exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Connections__container--2h4XE {\n    padding: 20px;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/Connection/Connections.css"],"names":[],"mappings":"AAAA;IACI,cAAc;CACjB","file":"Connections.css","sourcesContent":[".container {\n    padding: 20px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "Connections__container--2h4XE"
};