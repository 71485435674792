import React from 'react';
import { activate } from '../../actions';
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'
import PageContainer from '../common/container/PageContainer';
import Button from 'react-bootstrap/lib/Button';
import Image from 'react-bootstrap/lib/Image';
import { PulseLoader } from 'halogenium';
import styles from './ActivateAccount.css';

/**
 * activate a user's account
 * @TODO activate user's account
 */
class ActivateAccount extends React.Component {

  componentDidMount() {
    // lets activate the user's account!
    this.props.activate(this.props.match.params.code);
  }

  render() {
    const {
      activateUserSuccess,
      loadingActivateUser,
      loadingActivateUserError,
    } = this.props;
    console.log(loadingActivateUser, loadingActivateUserError, activateUserSuccess);
    return(
      <PageContainer header={false}>
        <div className={styles.pageContainer}>
          <div className={styles.innerLoginContainer}>
            <div>
              <div className={styles.topHeader}>
                <div>
                  <Image
                    src={require('../../assets/images/swingbot_logo.png')}
                    width={60}
                    height={60}
                    className="center-block"
                  />
                </div>
                { activateUserSuccess === null && loadingActivateUser === true &&
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2>Activating Account</h2>
                    <p className="subHeading">This will just take a moment</p>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                      <PulseLoader color="green" size="16px" margin="4px" />
                    </div>
                  </div>
                }
                { activateUserSuccess === true && loadingActivateUser === null &&
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2>Activation Complete</h2>
                    <p className="subHeading">You're all set, login to get started.</p>
                    <br />
                    <Button
                      block
                      size={'large'}
                      onClick={() => {
                        this.props.goToLogin();
                      }}
                    >
                        Click to Login
                    </Button>
                  </div>
                }
                { loadingActivateUserError !== null &&
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2>Activation Failed</h2>
                    <p className="subHeading">There was a problem with the activation.</p>
                    <br />
                    <Button
                      block
                      size={'large'}
                      onClick={() => {
                        this.props.goToLogin();
                      }}
                    >
                        Back to Login
                    </Button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
  );
  }
}

const mapStateToProps = ({ activate }) => {
  const {
    activateUserSuccess,
    loadingActivateUser,
    loadingActivateUserError
  } = activate;
  return {
    activateUserSuccess,
    loadingActivateUser,
    loadingActivateUserError
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  activate,
  goToLogin: () => push('/login'),
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount)
