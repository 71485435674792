exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".AnalysisResultHistory__historyTableRow--1AhJI {\n    padding: 10px;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/Progress/AnalysisResultHistory.css"],"names":[],"mappings":"AAAA;IACI,cAAc;CACjB","file":"AnalysisResultHistory.css","sourcesContent":[".historyTableRow {\n    padding: 10px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"historyTableRow": "AnalysisResultHistory__historyTableRow--1AhJI"
};