import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageContainer from '../common/container/PageContainer'
import { getAnalysesByUserId } from '../../actions'
import AnalysisListItem from './AnalysisListItem'
import Loading from '../common/Loading'
/**
 * class AnalysisList
 */
class AnalysisList extends React.Component {
  componentDidMount = () => {
    // lets fetch the analysis
    this.props.getAnalysisByUserId(this.props.authUser.user_id);
  }
  /**
   * render the video grid
   */
  renderAnalysisList = () => {
    const { analyses } = this.props;
    return analyses.length > 0 ? analyses.map(analysis => {
        return(
          <AnalysisListItem
            key={analysis.video_id}
            videoId={analysis.video_id}
            thumb={analysis.zen_thumb}
            video={analysis}
          />
        )
    }) : null;
  }
  /**
   * render
   */
  render() {
    return (
      <PageContainer title="Analysis">
        { !this.props.loadingAnalysisList &&
          <div>
            {this.renderAnalysisList()}
          </div>
        }
        { this.props.loadingAnalysisList && <Loading />}
      </PageContainer>
    )
  }
}

const mapStateToProps = ({ videoList, auth, analysisList }) => {
  const { analyses, loadingAnalysisList } = analysisList;
  const { authUser } = auth;
  console.log(analyses);
  return { analyses, loadingAnalysisList, authUser };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getAnalysesByUserId,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisList);
