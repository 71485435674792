/**
 * Services
 */
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');

/**
 * Get connections for user
 * @param {int} userId
 */
export const getConnectionsForUser = userId => {
    const endpoint = endpointForApi('user', `users/${userId}/connection`);
    return axios({
      url: endpoint,
      method: 'get',
      headers: headersForApi('user')
    });
}
/**
 * Get the invitation data
 * @param {string} nonce
 */
export const getInvitationByNonce = nonce => {
    const endpoint = endpointForApi('connection', `connections/nonce/${nonce}`);
    return axios({
      url: endpoint,
      method: 'get',
      headers: headersForApi('connection')
    });
}

export const acceptInvitationRequest = (userId, nonce) => {
    const endpoint = endpointForApi('connection', 'connections/request/accept');
    const body = { userId, nonce };
    return axios({
      url: endpoint,
      method: 'put',
      headers: headersForApi('connection'),
      data: body
    });
}

export const denyInvitationRequest = (userId, nonce) => {
    const endpoint = endpointForApi('connection', 'connections/request/deny');
    const body = { userId, nonce };
    return axios({
      url: endpoint,
      method: 'put',
      headers: headersForApi('connection'),
      data: body
    });
}
