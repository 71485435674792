/**
 * Google Tag Manager Middleware
 * 
 * This middleware will intercept the action
 * and generate the dataLayer object of GTM
 * with the data we wish to provide based on the 
 * payload of the action
 * 
 * We may also need to add to the dataLayer from each 
 * individual component but we can simply do this 
 * by tacking onto the window.dataLayer as we do below.
 */

export const analyticsGTM = ({ getState }) => next => action => {

    // set the dataLayer initially...
    window.dataLayer = window.dataLayer || [];
    
    // Listen for Route Changes ONLY
    if (typeof next === 'function' && action.type === '@@router/LOCATION_CHANGE') {
        // Tack on the user data if we have it...
        const state = getState()
        const userData = (((state.auth || {}).authUser || {}).data || {});
        const userDataLayer = { 
            userId: userData.user_id || null, 
            isSubscribed: userData.is_subscribed || null
        };
        // add the user data
        window.dataLayer.push(userDataLayer);
    }

    return next(action);
};