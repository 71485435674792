import braintree from 'braintree';

import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');

/**
 * Braintree Client
 */
/**
 * Get a client token to authorize braintree payments drop-in UI
 * 
 * @param {int} userId 
 * @param {bool} sandbox 
 */
export const getBraintreeClientToken = (userId, sandbox) => {
    const endpoint = endpointForApi('transaction', `transaction/braintree-client-token`);
    const body = { userId, sandbox };
    return axios({
        url: endpoint,
        method: 'post',
        headers: headersForApi('transaction'),
        data: body
    });
};
/**
 * createBraintreeCustomer
 * 
 * This will create the Braintree Customer in the vault
 * It will return a customer.id from the response from braintree
 * that we can then use to generate the paymentNonce
 */
export const createBraintreeCustomer = (userId, firstName, lastName, email, sandbox) => {
    const endpoint = endpointForApi('transaction', `transaction/braintree-customer`);
    const body = { userId, firstName, lastName, email, sandbox };
    return axios({
        url: endpoint,
        method: 'post',
        headers: headersForApi('transaction'),
        data: body
    });
};
/**
 * subscribeToPlan
 * @param {int} userId the user id
 * @param {string} paymentNonce the payment nonce we get from the DropIn UI
 * @param {string} planId the plan id (from braintree) that is selected
 * @param {bool} sandbox boolean for test|live account
 */
export const subscribeToPlan = (userId, paymentNonce, planId, sandbox) => {
    const endpoint = endpointForApi('transaction', `transaction/braintree-subscribe`);
    const body = { userId, paymentNonce, planId, sandbox };
    return axios({
        url: endpoint,
        method: 'post',
        headers: headersForApi('transaction'),
        data: body
    });
};

export const getSubscriptionsByUserId = (userId, sandbox) => {
    const debug = sandbox === true ? '1' : '0'
    const endpoint = endpointForApi('user', `users/${userId}/transaction?debug=${debug}`);
    return axios({
        url: endpoint,
        method: 'get',
        headers: headersForApi('user')
    });
};

export const cancelSubscription = (userId, subscriptionId, sandbox) => {
    const endpoint = endpointForApi('transaction', `transaction/braintree-cancel`);
    const body = { userId, subscriptionId, sandbox };
    return axios({
        url: endpoint,
        method: 'post',
        headers: headersForApi('transaction'),
        data: body
    });
};

