exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SectionSampleImages__sampleImageRow--Gmdt6 {\n    margin-bottom: 20px;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/About/sections/SectionSampleImages.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;CACvB","file":"SectionSampleImages.css","sourcesContent":[".sampleImageRow {\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"sampleImageRow": "SectionSampleImages__sampleImageRow--Gmdt6"
};