import * as actionCreators from './actions';
import * as services from './services';
/**
 * completeRegistration (if there is a Connection Request)
 * 
 * This will set the password for the new user.
 */
export const completeRegistration = (nonce, password) => dispatch => {
    dispatch(actionCreators.completeRegistration(nonce, password));
    return services.completeRegistration(nonce, password)
      .then(result => dispatch(actionCreators.completeRegistrationSuccess(result)))
      .catch(error => dispatch(actionCreators.completeRegistrationError(error)));
};
  
export const completeRegistrationReset = () => dispatch => {
    dispatch(actionCreators.completeRegistrationReset());
};

/**
 * Choose Password
 * @param {string} email the email of the user choosing the password
 * @param {string} password the password for the user
 * @param {string} tempPassword the temporary password in the database
 */
export const choosePassword = (email, password, tempPassword) => dispatch => {
    dispatch(actionCreators.choosePassword(email, password, tempPassword));
    return services.choosePassword(email, password, tempPassword)
        .then(result => dispatch(actionCreators.choosePasswordSuccess(result)))
        .catch(error => dispatch(actionCreators.choosePasswordError(error)));
};

export const choosePasswordReset = () => dispatch => {
    dispatch(actionCreators.choosePasswordReset());
};
