import React from 'react';
import PropTypes from 'prop-types';

class CountdownTimer extends React.Component {

    constructor(props) {
        super(props);
        this._second = 1000;
        this._minute = this._second * 60;
        this._hour = this._minute * 60;
        this._day = this._hour * 24;
        this.timer = null;
        this.state = {
            days: null,
            hours: null,
            minutes: null,
            seconds: null
        }
    }

    componentDidMount = () => {
        this.countdownToDate();
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.timer = null;
    }

    countdownToDate = () => {
        const { endDate } = this.props;
        var now = new Date();
        var distance = endDate - now;
        if (distance < 0) {

            clearInterval(this.timer);

            // nullify, this is expired...
            this.setState({
                days: null,
                hours: null,
                minutes: null,
                seconds: null,
            });

            return;
        }
        var days = Math.floor(distance / this._day);
        var hours = Math.floor((distance % this._day) / this._hour);
        var minutes = Math.floor((distance % this._hour) / this._minute);
        var seconds = Math.floor((distance % this._minute) / this._second);

        this.setState({
            days,
            hours,
            minutes,
            seconds
        });

        this.timer = setInterval(this.countdownToDate, 1000);
    }


    render() {
        return (
            <div>
                <p>{`Starts in ${this.state.days} days ${this.state.hours} ${this.state.minutes}:${this.state.seconds}`}</p>
            </div>
        );
    }
}

CountdownTimer.propTypes = {
    endDate: PropTypes.date,
}

CountdownTimer.defaultProps = {
    endDate: null,
};

export default CountdownTimer;