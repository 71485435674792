/**
 * GET /analyses/{analysisId}
 */
import {
    ANALYZE_VIDEO_DETAIL,
    ANALYZE_VIDEO_DETAIL_ERROR,
    ANALYZE_VIDEO_DETAIL_SUCCESS
  } from '../types';
  // load up the AWS API Client
  // endpoint helpers and axios
  import { endpointForApi, headersForApi } from '../../api/swingbotApi';
  const axios = require('axios');
  
  /**
   * GET /analyses/:id
   * get analyses details
   */
  export const getAnalysisResultsByVideoId = videoId => {
    return dispatch => {
      dispatch({
        type: ANALYZE_VIDEO_DETAIL,
        payload: null
      });
      return axios({
        method: 'get',
        url: endpointForApi('video', `videos/${videoId}/analyze`),
        headers: headersForApi('video')
      })
        .then(result => {
          dispatch({
            type: ANALYZE_VIDEO_DETAIL_SUCCESS,
            payload: result.data.data
          });
        })
        .catch(result => {
          dispatch({
            type: ANALYZE_VIDEO_DETAIL_ERROR,
            payload: result
          });
        });
    };
  };
  