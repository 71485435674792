/**
 * ConnectionRequestCheck
 * 
 * This intermediary file will be the first page the user sees
 * once they click the link in the email for a connection invitation
 * 
 * Based on the status of the invitee, we will either prompt the user
 * to login OR complete their registration
 */
import React from 'react';
import { push } from 'react-router-redux';
import Image from 'react-bootstrap/lib/Image';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import PageContainer from '../common/container/PageContainer';
import queryString from 'query-string';
import { 
  login, 
  loginTyping, 
  loginReset,  
} from '../../actions'
import { googleVerifyToken, googleVerifyTokenReset } from '../../actions/GoogleVerifyToken';
import {
  getInvitationByNonce
} from '../../actions/Connection';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PulseLoader } from 'halogenium';
import styles from './LoginAccount.css';

class ConnectionRequestCheck extends React.Component {
  /**
   * state to hold the form vars
   */
  state = {
    email: '',
    password: '',
  }

  componentDidMount = () => {
    // clear out the information 
    this.props.loginReset();
    this.props.googleVerifyTokenReset();
    const query = queryString.parse(this.props.location.search);
    // let's fetch the invitation request by nonce if there is a cr in the query string...
    if (query['cr']) {
      // fetching the invitation data
      this.props.getInvitationByNonce(query.cr);
    }
  }
  // send the user to the complete registration page
  // with the correct nonce in the query string
  handleClickCompleteRegistration = () => {

  }
  // send the user to the login page with the nonce in the query string
  handleClickLogin = () => {

  }
  /**
   * display the summary of the request to the user.
   */
  renderInvitationSummary = () => {
    const { invitationData } = this.props;
    const userStatus = invitationData.invitee_status;
    return (
      <div className={styles.description}>
        <p>
          <strong>{`${invitationData.inviter_f_name} ${invitationData.inviter_l_name}`}</strong> has sent you a new connection request.
        {userStatus === 3 && 
          '  To complete your account registration and view this request, please click the button below.'
        }
        {userStatus === 2 && 
          '  To view this request, please login to continue.'
        }
        </p>
      </div>
    )
  }

  renderInvitationButton = () => {
    const { invitationData } = this.props;
    const userStatus = invitationData.invitee_status;
    const registrationNonce = invitationData.invitee_nonce;
    const invitationNonce = invitationData.nonce;
    return userStatus === 3 ? (
      <Col sm={12}>
          <Button
            block
            onClick={() => this.props.goToCompleteRegistration(registrationNonce, invitationNonce)}
            size="large"
          >
              Click to Complete Registration
          </Button>
      </Col>
    ) : (
      <Col sm={12}>
        <Button
          block
          onClick={() => this.props.goToLogin(invitationNonce)}
          size="large"
        >
            Click to Login
        </Button>
      </Col>
    )
  }
  /**
   * render the options for the request.
   * Based on the status of the invitee, we will show a 
   * login or a choose password button...
   */
  render() {
    const {
      getInvitationDataLoading,
      invitationData
    } = this.props;
    return (
      <PageContainer header={false}>
      <div className={styles.pageContainer}>
        <div className={styles.innerLoginContainer}>
          <div>
            <div className={styles.topHeader}>
              <div>
                <Image
                  src={require('../../assets/images/swingbot_logo.png')}
                  width={60}
                  height={60}
                  className="center-block"
                />
              </div>
              <h2>Connection Request</h2>
              { getInvitationDataLoading === true &&
                <div style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  height: '100%',
                  width: '100%'
                }}>
                  <div style={{ textAlign: 'center' }}>
                    <PulseLoader color="green" size="16px" margin="4px" />
                  </div>
                </div>
              }
              {invitationData !== null && this.renderInvitationSummary()}
            </div>
              <div className={styles.bottomLoginContainer}>
                { invitationData !== null && this.renderInvitationButton()}
                  {/* <Col sm={12}>
                      <Button
                        block
                        onClick={this.submitForm}
                        size="large"
                      >
                          Click to Login
                      </Button>
                  </Col> */}
                {/* } */}
            </div>
          </div>
        </div>
      </div>
      </PageContainer>
    );
  }
}
const mapStateToProps = ({ auth, googleVerifyToken, connection }) => {
  const {
    token,
    authUser,
    loadingLoginUser,
    loadingLoginUserError
  } = auth;
  const {
    googleVerifyTokenData,
    loadingGoogleVerifyToken,
    loadingGoogleVerifyTokenError
  } = googleVerifyToken;
  const {
    getInvitationDataLoading,
    getInvitationDataError,
    invitationData,
  } = connection;
  console.log(invitationData);
  return {
    token,
    authUser,
    loadingLoginUser,
    loadingLoginUserError,
    googleVerifyTokenData,
    loadingGoogleVerifyToken,
    loadingGoogleVerifyTokenError,
    getInvitationDataLoading,
    getInvitationDataError,
    invitationData,
  };
}
const mapDispatchToProps = dispatch => bindActionCreators({
  login,
  googleVerifyToken,
  googleVerifyTokenReset,
  loginTyping,
  loginReset,
  getInvitationByNonce,
  goToLogin: (code) => push(`/login?cr=${code}`),
  goToCompleteRegistration: (code, invitationNonce) => push(`/choose-password?cp=${code}&cr=${invitationNonce}`),
}, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(ConnectionRequestCheck)
