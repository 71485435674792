import {
  ACTIVATE_USER,
  ACTIVATE_USER_ERROR,
  ACTIVATE_USER_SUCCESS,
} from '../../actions/types'
// set the initial state of the reducer
 const INITIAL_STATE = {
   activateUserSuccess: null,
   loadingActivateUser: false,
   loadingActivateUserError: null,
   token: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     /**
      * activate user
      */
     case ACTIVATE_USER:
       return {
         ...state,
         activateUserSuccess: null,
         loadingActivateUser: true,
         loadingActivateUserError: null
       };

     case ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activateUserSuccess: action.payload.data.success,
        loadingActivateUser: null,
        loadingActivateUserError: null,
      };

     case ACTIVATE_USER_ERROR:
      return {
        ...state,
        activateUserSuccess: null,
        loadingActivateUser: null,
        loadingActivateUserError: action.payload
      };

     default:
       return state;
   }
 };
