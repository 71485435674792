import {
    GET_LIVE_BROADCAST_LIST,
    GET_LIVE_BROADCAST_LIST_ERROR,
    GET_LIVE_BROADCAST_LIST_SUCCESS,
    GET_EXISTING_BROADCAST_LIST,
    GET_EXISTING_BROADCAST_LIST_ERROR,
    GET_EXISTING_BROADCAST_LIST_SUCCESS,
    GET_UPCOMING_BROADCAST_LIST,
    GET_UPCOMING_BROADCAST_LIST_ERROR,
    GET_UPCOMING_BROADCAST_LIST_SUCCESS
  } from '../types'
  // endpoint helpers and axios
  import { endpointForApi, headersForApi } from '../../api/swingbotApi';
  const axios = require('axios');
  /**
   * get live broadcast list
   */
  export const getLiveBroadcastList = () => {
    return (dispatch) => {
      dispatch({
        type: GET_LIVE_BROADCAST_LIST,
        payload: null
      });
      const endpoint = endpointForApi('webinar', 'live_broadcasts');
      return axios({
        url: endpoint,
        method: 'get',
        headers: headersForApi('webinar')
      })
        .then(result => {
          dispatch({
            type: GET_LIVE_BROADCAST_LIST_SUCCESS,
            payload: result.data.data
          });
        })
        .catch(result => {
          dispatch({
            type: GET_LIVE_BROADCAST_LIST_ERROR,
            payload: result
          });
        });
      };
    };

    export const getUpcomingBroadcastList = () => {
      return (dispatch) => {
        dispatch({
          type: GET_UPCOMING_BROADCAST_LIST,
          payload: null
        });
        const endpoint = endpointForApi('webinar', 'upcoming_broadcasts');
        return axios({
          url: endpoint,
          method: 'get',
          headers: headersForApi('webinar')
        })
          .then(result => {
            dispatch({
              type: GET_UPCOMING_BROADCAST_LIST_SUCCESS,
              payload: result.data.data
            });
          })
          .catch(result => {
            dispatch({
              type: GET_UPCOMING_BROADCAST_LIST_ERROR,
              payload: result
            });
          });
        };
      };

    export const getExistingBroadcastList = () => {
      return (dispatch) => {
        dispatch({
          type: GET_EXISTING_BROADCAST_LIST,
          payload: null
        });
        const endpoint = endpointForApi('webinar', 'past_broadcasts');
        return axios({
          url: endpoint,
          method: 'get',
          headers: headersForApi('webinar')
        })
          .then(result => {
            console.log('PAST: ', result.data.data);
            dispatch({
              type: GET_EXISTING_BROADCAST_LIST_SUCCESS,
              payload: result.data.data
            });
          })
          .catch(result => {
            console.log('error', result);
            dispatch({
              type: GET_EXISTING_BROADCAST_LIST_ERROR,
              payload: result
            });
          });
        };
      };
  