import queryString from 'query-string';
/**
 * Generate a redirect based on the current query string
 * This is used mainly for login, register, etc, so once the 
 * user does any of these successfully they can be redirected
 * to the correct page
 * @param {string} search the "this.props.location.search" query string
 */
export const redirectFromQueryString = (search) => {
    try {
        const query = queryString.parse(search);
        const queryKey = Object.keys(query)[0];
        // based on the key of the query string
        // we can redirect to particular pages
        switch(queryKey) {
            // connection request
            case 'cr':
                return {
                    pathname: '/connection/request',
                    search: "?" + new URLSearchParams(query).toString()
                };
            // default is false, no redirect
            default:
                return false;
        };
    } catch(e) {
        return false;
    }
};

export const hasQueryString = (string) => {
    try {
        const query = queryString.parse(string);
        return Object.keys(query).length > 0;
    } catch(e) {
        return false;
    }
}
