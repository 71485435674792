/**
 * ProgramDetailReducer
 *
 * Fetch the list of programs from the database
 */
import {
  GET_PROGRAM_DETAIL,
  GET_PROGRAM_DETAIL_ERROR,
  GET_PROGRAM_DETAIL_SUCCESS
} from '../../actions/types'

// set the initial state of the reducer
 const INITIAL_STATE = {
   programData: {},
   loadingProgramDetail: false,
   loadingProgramDetailError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     case GET_PROGRAM_DETAIL:
       return {
         ...state,
         programData: {},
         loadingProgramDetail: true,
         loadingProgramDetailError: null
       }
     break;

     case GET_PROGRAM_DETAIL_SUCCESS:
      return {
        ...state,
        programData: action.payload,
        loadingProgramDetail: false,
        loadingProgramDetailError: null
      }
     break;

     case GET_PROGRAM_DETAIL_ERROR:
      return {
        ...state,
        programData: {},
        loadingProgramDetail: false,
        loadingProgramDetailError: action.payload
      }
     break;

     default:
       return state;
   }
 };
