/**
 * Complete Registration (choose password)
 */
import * as actionTypes from '../../actions/User/types';

  // set the initial state of the reducer
   const INITIAL_STATE = {
     completeRegistrationSuccess: null,
     loadingCompleteRegistration: false,
     loadingCompleteRegistrationError: null,
   };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {

       case actionTypes.COMPLETE_REGISTRATION:
         return {
           ...state,
           completeRegistrationSuccess: null,
           loadingCompleteRegistration: true,
           loadingCompleteRegistrationError: null
         }
  
       case actionTypes.COMPLETE_REGISTRATION_SUCCESS:
        console.log(action.payload);
        return {
          ...state,
          completeRegistrationSuccess: true,
          loadingCompleteRegistration: false,
          loadingCompleteRegistrationError: null,
        }
  
       case actionTypes.COMPLETE_REGISTRATION_ERROR:
        return {
          ...state,
          completeRegistrationSuccess: false,
          loadingCompleteRegistration: false,
          loadingCompleteRegistrationError: action.payload
        }
  
      case actionTypes.COMPLETE_REGISTRATION_RESET:
         return {
           ...state,
           completeRegistrationSuccess: null,
           loadingCompleteRegistration: false,
           loadingCompleteRegistrationError: null
         }
  
       default:
         return state;
     }
   };
  