exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "html {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\nhtml, body, #index__root--4PyOE, #index__root--4PyOE>div {\n  height: 100%;\n  font-family: Roboto, sans-serif;\n}\n", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,aAAa;CACd;;AAED;EACE,UAAU;EACV,WAAW;EACX,aAAa;CACd;;AAED;EACE,aAAa;EACb,gCAAgC;CACjC","file":"index.css","sourcesContent":["html {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\nhtml, body, #root, #root>div {\n  height: 100%;\n  font-family: Roboto, sans-serif;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "index__root--4PyOE"
};