/**
 * GET /videos/{videoId}
 */
import {
  GET_VIDEO_DETAIL,
  GET_VIDEO_DETAIL_ERROR,
  GET_VIDEO_DETAIL_SUCCESS
} from '../types'
// load up the AWS API Client
// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');

/**
 * GET /videos/:id
 * get video details
 */
export const getVideoByVideoId = (videoId) => {
  return (dispatch) => {
    dispatch({
      type: GET_VIDEO_DETAIL,
      payload: null
    });
    return axios({
      method: 'get',
      url: endpointForApi('www', 'videos/' + videoId),
      headers: headersForApi('www')
    })
      .then(result => {
        dispatch({
          type: GET_VIDEO_DETAIL_SUCCESS,
          payload: result.data.data
        });
      })
      .catch(result => {
        dispatch({
          type: GET_VIDEO_DETAIL_ERROR,
          payload: result
        });
      });
    };
  };
