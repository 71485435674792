import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageContainer from '../common/container/PageContainer'
import { getAnalysisHistory } from '../../actions'
import Loading from '../common/Loading'
import SwingbotHeader from '../common/navbar/SwingbotHeader';

import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Table from 'react-bootstrap/lib/Table';
import './AnalysisResultHistory.css';
// import { isUndefined } from 'util';
/**
 * class VideoList
 */
class VideoList extends React.Component {
    state = {
        targetDisplayNameFilter: null,
    }

    
    componentDidMount = () => {
        // lets fetch the videos
        this.props.getAnalysisHistory(this.props.authUser.data.user_id);
    }
  
    handleSelectVideo = (videoId) => {
        this.props.history.push({
            pathname: '/analysis/' + videoId
        });
    }

    sortByTarget = (history) => {

    }

    filterHistory = () => {
        if(this.state.targetDisplayNameFilter !== null) {
            return this.filterByDisplayName(this.state.targetDisplayNameFilter);
        }
        return this.props.analysisHistoryList;
    }

    setHistoryFilter = (name) => {
        if (this.state.targetDisplayNameFilter !== null) {
            this.setState({
                targetDisplayNameFilter: null
            });
        } else {
            this.setState({
                targetDisplayNameFilter: name
            });
        }
    }

    filterByDisplayName = (name) => {
        return this.props.analysisHistoryList
            .filter((row) => {
                return row.display_name === name;
            });
    }

    renderHistoryRows = () => {
        const filtered = this.filterHistory();
        return filtered.map(row => (
            <tr className="historyTableRow">
                <td 
                    onClick={() => this.setHistoryFilter(row.display_name)}>
                    {row.display_name}
                </td>
                <td>{row.code.substr(0,1) === 'F' ? 'Fail' : 'Pass'}</td>
                <td>{row.video_id}</td>
                <td>{row.gm_stitch_id}</td>
            </tr>
        ));
    }
  /**
   * render
   */
  render() {
    return (
      <div className="mainVideoContainer">
        <PageContainer title="Videos">
          <div className="pageContainer">
            <SwingbotHeader />
            { !this.props.loadingAnalysisHistoryList &&
            <Container>
                <Row>
                    <Col lg={12}>
                        <h2>History</h2>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
              <Table striped hover>
              <thead>
                <tr>
                  <th>Issue</th>
                  <th>Pass?</th>
                  <th>Video</th>
                  <th>Instruction Video</th>
                </tr>
              </thead>
              <tbody>
                {this.renderHistoryRows()}
            </tbody>
              </Table>
              </Col>
              </Row>
              </Container>
            }
            { this.props.loadingAnalysisHistoryList && <Loading />}
          </div>
        </PageContainer>
      </div>
    )
  }
}

const mapStateToProps = ({ analysisHistory, auth }) => {
    const { 
        analysisHistoryList,
        loadingAnalysisHistoryList
    } = analysisHistory;
    console.log(analysisHistoryList);
    const { authUser } = auth;
    return { 
        analysisHistoryList, 
        authUser,
        loadingAnalysisHistoryList
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getAnalysisHistory,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(VideoList);
