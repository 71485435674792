import React from 'react';
import { Image, Col } from 'react-bootstrap/lib';
import styles from './StitchListItem.css'
import { CONSTANTS } from '../../config';
import { colorForScore } from '../../util/misc';
/**
 * StittchListItem
 *
 * Display the video item and some details for the video
 */
class StitchListItem extends React.Component {
  state = {
    imageUrl: this.props.thumbnail,
  }

  handleImageClick = () => {
    const { disabled } = this.props;
    if (disabled === false) {
      console.log(this.props.data);
      this.props.onClick(this.props.data);
    } else {
      console.log('cant doo it..');
      this.props.onDisabledClick(this.props.data);
    }
  }

  render() {
    const {
      thumbnail,
      display_name,
      summary,
      text_definition,
      percentScore,
      date_created,
      probability,
    } = this.props.data;
    const score = percentScore !== null ? `Score ${String(percentScore).toUpperCase()}%`.toUpperCase() : null;
    // color for score
    let color = 'gray';
    if (score !== null) {
      color = colorForScore(parseInt(percentScore));
    }
    // color for probability
    let probabilityColor = 'gray';
    if (probability !== null) {
      probabilityColor = colorForScore(parseInt(probability));
    }
    // grab the disabled prop, and lets set the opacity of the image...
    const { disabled } = this.props;
    const imageStyle = disabled
      ? { 
        opacity: 0.5,
        /* Add the blur effect */
        filter: `blur(8px)`,
        '-webkit-filter': `blur(8px)`,
      }
      : {};
    return (
      <Col sm={6} md={4} lg={3} key={this.props.thumb}>
        <div
          key={thumbnail}
          className={styles.containerVideo}
          style={this.props.style}
        >
            <Image
              src={this.state.imageUrl}
              rounded
              fluid
              onError={() => {
                this.setState({
                  imageUrl: CONSTANTS.IMG_PLACEHOLDER_VIDEO
                });
              }}
              onClick={() => {
                this.handleImageClick()
              }}
              style={imageStyle}
            />
          { this.props.details === true &&
            <div className={styles.containerVideoDetail}>
              { disabled === false && <h6 className={styles.title}>{display_name}</h6>}
              {/* {score &&
                <div className="labelScore">
                  <h5>
                    <Badge style={{ backgroundColor: color }}>{score}</Badge>
                    <Badge style={{ backgroundColor: probabilityColor }}>PROB {probability}%</Badge>
                  </h5>
                </div>
              } */}
              {/* <p className="textVideoDetail">{text_definition}</p> */}
            </div>
          }
        </div>
      </Col>
    )
  }
}

StitchListItem.defaultProps = {
  details: true,
  style: {},
  onClick() {},
  onDisabledClick() {},
  disabled: false
}

export default StitchListItem
