import React from 'react'

import Image from 'react-bootstrap/lib/Image';
import Col from 'react-bootstrap/lib/Col';

import { CONSTANTS } from '../../config'
class AnalysisListItem extends React.Component {
  /**
   * set our image url in state, and then if the image
   * fails to load, we can revert to the placeholder.
   */
  state = {
    imageUrl: this.props.thumb,
  }
  render() {
    return (
      <Col sm={6} md={4} lg={3} key={this.props.thumb}>
        <div
          style={{
            minHeight: '546px',
            overflow: 'hidden',
            marginBottom: '10px',
            borderRadius: '10px',
            backgroundColor: 'gray'
          }}
        >
          <Image
            rounded
            src={this.state.imageUrl}
            responsive
            resizeMode={'cover'}
            onError={() => {
              this.setState({
                imageUrl: CONSTANTS.IMG_PLACEHOLDER_VIDEO
              });
            }}
            style={{
              marginBottom: '10px'
            }}
          />
        </div>
      </Col>
    )
  }
}

export default AnalysisListItem
