import React from 'react';

import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import styles from './SectionHeader.css';

const SectionHeader = (props) => {
    return (
        <Container className={styles.container}>
            <Row className={styles.row}>
                <Col>
                    <p className={styles.title}>{props.title}</p>
                    <p className={styles.description}>{props.description}</p>
                </Col>
            </Row>
        </Container>
    );
}

export default SectionHeader;