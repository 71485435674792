import React from 'react'
import Col from 'react-bootstrap/lib/Col';
import Image from 'react-bootstrap/lib/Image';
import { CONSTANTS } from '../../config'
import styles from './VideoListItem.css';

class VideoListItem extends React.Component {
  /**
   * set our image url in state, and then if the image
   * fails to load, we can revert to the placeholder.
   */
  state = {
    imageUrl: this.props.thumb,
  }

  handleClick = () => {
    if ('onClick' in this.props) {
      this.props.onClick(this.props.videoId);
    }
  }

  render() {
    return (
      <Col sm={6} md={4} lg={3} key={this.props.thumb}>
        <div
          // className={styles.videoLink}
          className={styles.containerVideo}
          onClick={this.handleClick}
          style={this.props.style}
        >
          <Image
            rounded
            src={this.state.imageUrl}
            fluid
            onError={(e) => {
              e.target.src = CONSTANTS.IMG_PLACEHOLDER_VIDEO 
            }}
            className={styles.image}
          />
        </div>
      </Col>
    )
  }
}

export default VideoListItem
