import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Image from 'react-bootstrap/lib/Image';
// import Badge from 'react-bootstrap/lib/Badge';
// import Label from 'react-bootstrap/lib/Label';
import { CONSTANTS } from '../../config'
import styles from './ConnectionItem.css';

class ConnectionItem extends React.Component {
    handleItemClicked = () => {
        this.props.onPress(this.props.connection);
    }
    render() {
        console.log(this.props.connection);
        const { f_name, l_name, avatar, status, email } = this.props.connection;
        return(
            <Col sm={6} md={6} lg={4} key={this.props.thumb}>
                <div className={styles.container}>
                    <div className={styles.avatarContainer}>
                        <Image
                            src={avatar}
                            onError={(e) => {
                                e.target.src = CONSTANTS.IMG_PLACEHOLDER_VIDEO 
                            }}
                            style={{ padding: 10, width: 60, height: 60, borderRadius: 30 }}
                            // className={styles.avatar}
                        />
                    </div>
                    <div className={styles.descriptionContainer}>
                        <p className={styles.name}>{`${f_name} ${l_name}`}</p>
                        {email !== '' && email && <p className={styles.email}>{email}</p>}
                    </div>
                    <div className={styles.statusContainer}>
                        <p className={styles[status.toLowerCase()]}>{status.toLowerCase()}</p>
                    </div>
                </div>
            </Col>
        );
    }
}

ConnectionItem.defaultProps = {
    onPress() {},
};

export default ConnectionItem;
