import React from 'react';
import { activate } from '../../actions';
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'

import Button from 'react-bootstrap/lib/Button';
import Image from 'react-bootstrap/lib/Image';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormCheck from 'react-bootstrap/lib/FormCheck';

import { FieldGroupWithLabel } from '../../util/misc';
import { PulseLoader } from 'halogenium';
import './ActivateAccountWithForm.css'
/**
 * activate a user's account
 * @TODO activate user's account
 */
class ActivateAccountWithForm extends React.Component {

  componentDidMount() {
    // lets activate the user's account!
    this.props.activate(this.props.match.params.code);
  }

  renderActivationForm = () => (
        <div>
            <Form>
                <Col sm={12}>
                  <FieldGroupWithLabel
                    id="formControlsEmail"
                    type="text"
                    label="Where do you typically hit the ball?"
                    placeholder=""
                    // onChange={null}
                    // value={this.state.email}
                    autoFocus
                    // validationstate={validationState}
                    autoComplete="new-username"
                  />
                    <FormCheck checked readOnly>
                        Checkbox
                    </FormCheck>
                  <FieldGroupWithLabel
                    id="formControlsPassword"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    onChange={this.changePassword}
                    // value={this.state.password}
                    // validationstate={validationState}
                    autoComplete="new-password"
                  />
                </Col>
            </Form>
        </div>
  )
    
  render() {
    const {
      activateUserSuccess,
      loadingActivateUser,
      loadingActivateUserError,
    } = this.props;
    return(
        <div className="mainLoginContainer">
          <div className="innerActivationContainer">
            <div>
              <div className="topHeader">
                <div>
                  <Image
                    src={require('../../assets/images/swingbot_logo.png')}
                    width={60}
                    height={60}
                    className="center-block"
                  />
                </div>
                { activateUserSuccess === null && loadingActivateUser === true &&
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2>Activating Account</h2>
                    <p className="subHeading">This will just take a moment</p>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                      <PulseLoader color="green" size="16px" margin="4px" />
                    </div>
                  </div>
                }
                { activateUserSuccess === true && loadingActivateUser === null && this.renderActivationForm()}
                { loadingActivateUserError !== null &&
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2>Activation Failed</h2>
                    <p className="subHeading">There was a problem with the activation.</p>
                    <br />
                    <Button
                      block
                      bsSize={'large'}
                      bsStyle={'success'}
                      onClick={() => {
                        this.props.goToLogin();
                      }}
                    >
                        Back to Login
                    </Button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
  );
  }
}

const mapStateToProps = ({ activate }) => {
  const {
    activateUserSuccess,
    loadingActivateUser,
    loadingActivateUserError
  } = activate;
  return {
    activateUserSuccess,
    loadingActivateUser,
    loadingActivateUserError
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  activate,
  goToLogin: () => push('/login'),
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccountWithForm)
