exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "p.AnalysisResultPanel__analysisSummary--139C1 {\n    font-size: 18px;\n    line-height: 30px;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/Analysis/AnalysisResultPanel.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;CACrB","file":"AnalysisResultPanel.css","sourcesContent":["p.analysisSummary {\n    font-size: 18px;\n    line-height: 30px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"analysisSummary": "AnalysisResultPanel__analysisSummary--139C1"
};