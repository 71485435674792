const colors = {
    black: '#000000',
    blue: '#2f72bf',
    darkBackground: '#222222',
    darkBlue: '#0b3e7a',
    darkGray: '#222222',
    darkGreen: '#2b641a',
    gray: '#666666',
    green: '#3e831a',
    lightBackground: '#eeeeee',
    lightBlue: '#85b3e8',
    lightGray: '#EAEAEA',
    lightGreen: '#7dbd46',
    mediumGray: '#CCCCCC',
    red: '#FF0000',
    veryLightGreen: '#D7FFCC',
    veryLightBlue: '#edf8fc',
    white: '#FFFFFF',
};

export {
    colors
};