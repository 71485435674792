/**
 * Connection Types
 */
export const GET_CONNECTIONS_FOR_USER = 'connection/get-connections-for-user';
export const GET_CONNECTIONS_FOR_USER_SUCCESS = 'connection/get-connections-for-user-success';
export const GET_CONNECTIONS_FOR_USER_ERROR = 'connection/get-connections-for-user-error';
export const GET_CONNECTIONS_FOR_USER_RESET = 'connection/get-connections-for-user-reset';

export const GET_INVITATION_DATA = 'connection/get-invitation-data';
export const GET_INVITATION_DATA_SUCCESS = 'connection/get-invitation-data-success';
export const GET_INVITATION_DATA_ERROR = 'connection/get-invitation-data-error';
export const GET_INVITATION_DATA_RESET = 'connection/get-invitation-data-reset';

export const ACCEPT_INVITATION = 'connection/accept-invitation';
export const ACCEPT_INVITATION_SUCCESS = 'connection/accept-invitation-success';
export const ACCEPT_INVITATION_ERROR = 'connection/accept-invitation-error';
export const ACCEPT_INVITATION_RESET = 'connection/accept-invitation-reset';

export const DENY_INVITATION = 'connection/deny-invitation';
export const DENY_INVITATION_SUCCESS = 'connection/deny-invitation-success';
export const DENY_INVITATION_ERROR = 'connection/deny-invitation-error';
export const DENY_INVITATION_RESET = 'connection/deny-invitation-reset';