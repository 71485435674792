/**
 * Choose password
 */
import * as actionTypes from '../../actions/User/types';

  // set the initial state of the reducer
   const INITIAL_STATE = {
     choosePasswordSuccess: null,
     loadingChoosePassword: false,
     loadingChoosePasswordError: null,
   };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
       /**
        * activate user
        */
       case actionTypes.CHOOSE_PASSWORD:
         return {
           ...state,
           choosePasswordSuccess: null,
           loadingChoosePassword: true,
           loadingChoosePasswordError: null
         }
  
       case actionTypes.CHOOSE_PASSWORD_SUCCESS:
        return {
          ...state,
          choosePasswordSuccess: true,
          loadingChoosePassword: false,
          loadingChoosePasswordError: null,
        }
  
       case actionTypes.CHOOSE_PASSWORD_ERROR:
        return {
          ...state,
          choosePasswordSuccess: false,
          loadingChoosePassword: false,
          loadingChoosePasswordError: action.payload
        }
  
      case actionTypes.CHOOSE_PASSWORD_RESET:
         return {
           ...state,
           choosePasswordSuccess: null,
           loadingChoosePassword: false,
           loadingChoosePasswordError: null
         }
  
       default:
         return state;
     }
   };
  