/**
 * AnalysisHistoryListReducer
 *
 * Fetch the list of analysisHistoryList from the database
 */
import {
    GET_ANALYSIS_HISTORY_LIST,
    GET_ANALYSIS_HISTORY_LIST_ERROR,
    GET_ANALYSIS_HISTORY_LIST_SUCCESS
  } from '../../actions/types'
  
  // set the initial state of the reducer
   const INITIAL_STATE = {
     analysisHistoryList: [],
     loadingAnalysisHistoryList: false,
     loadingAnalysisHistoryListError: null,
   };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
       case GET_ANALYSIS_HISTORY_LIST:
         return {
           ...state,
           analysisHistoryList: [],
           loadingAnalysisHistoryList: true,
           loadingAnalysisHistoryListError: null
         }
  
       case GET_ANALYSIS_HISTORY_LIST_SUCCESS:
        return {
          ...state,
          analysisHistoryList: action.payload,
          loadingAnalysisHistoryList: false,
          loadingAnalysisHistoryListError: null
        }
  
       case GET_ANALYSIS_HISTORY_LIST_ERROR:
        return {
          ...state,
          analysisHistoryList: [],
          loadingAnalysisHistoryList: false,
          loadingAnalysisHistoryListError: action.payload
        }
  
       default:
         return state;
     }
   };
  