import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from 'react-bootstrap/lib/Breadcrumb';
import styles from './BreadcrumbTrail';

class BreadcrumbTrail extends React.Component {
    renderCrumbs = () => {
        return this.props.crumbs.map(crumb => {
            return (
                <Breadcrumb.Item 
                    href={crumb.href}
                    active={crumb.active}
                >
                    {crumb.title}
                </Breadcrumb.Item>
            );
        });
    }
    render() {
        return (
            <Breadcrumb className={styles.trail}>
                {this.renderCrumbs()}
            </Breadcrumb>
        );
    }
}
BreadcrumbTrail.propTypes = {
    crumbs: PropTypes.array,
};

BreadcrumbTrail.defaultProps = {
    crumbs: []
};

export default BreadcrumbTrail;