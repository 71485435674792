import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { 
    getSubscriptionsByUserId, 
    cancelSubscription, 
    cancelSubscriptionReset
} from '../../actions/Subscribe';
import { getUserByUserId } from '../../actions/User/UserDetailActions';
import PageContainer from '../common/container/PageContainer';
import SectionHeader from '../common/header/SectionHeader';
import SwingbotHeaderClear from '../common/navbar/SwingbotHeaderClear';
import Loading from '../common/Loading';


import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';
import Card from 'react-bootstrap/lib/Card';
import ListGroup from 'react-bootstrap/lib/ListGroup';
import Badge from 'react-bootstrap/lib/Badge';
import Table from 'react-bootstrap/lib/Table';
import Modal from 'react-bootstrap/lib/Modal';

import { SBAlert } from '../common/bootstrap';

import { userSelectors } from '../../selectors';

import styles from './MyAccount.css';

class MyAccount extends React.Component {

    instance; 
    state = {
        sandbox: true,
        paymentNonce: null,
        planId: null,
        planSelected: 'monthly',
        showIosModal: false,
        showAndroidModal: false,
        showBraintreeModal: false,
    }

    componentDidMount = () => {
        const { user_id, is_subscribed } = this.props.authUser.data;
        // we have to fetch the user transaction information
        // from the database and reset the cancellation redux
        this.props.cancelSubscriptionReset();
        this.props.getSubscriptionsByUserId(user_id);
        this.props.getUserByUserId(user_id);
    }

    cancel = (userId, subscriptionId, source, environment) => {
        this.setState({
            showBraintreeModal: true,
            recordSelected: {userId, subscriptionId, source, environment}
        });
    }

    cancelConfirmed = () => {
        const {userId, subscriptionId, environment} = this.state.recordSelected;
        const sandbox = environment === 'LIVE' ? false : true;
        this.props.cancelSubscription(userId, subscriptionId, sandbox);
    }

    cancelIos = (userId, subscriptionId, source, environment) => {
        this.setState({
            showIosModal: true,
            recordSelected: {userId, subscriptionId, source, environment}
        });
    }

    cancelAndroid = (userId, subscriptionId, source, environment) => {
        this.setState({
            showAndroidModal: true,
            recordSelected: {userId, subscriptionId, source, environment}
        });
    }

    closeIosModal = () => {
        this.setState({ showIosModal: false });
    }

    closeAndroidModal = () => {
        this.setState({ showAndroidModal: false });
    }
    
    closeBraintreeModal = () => {
        this.props.cancelSubscriptionReset();
        this.setState({ showBraintreeModal: false });
    }

    flattenSubscriptions = (subscriptions) => {
        const orderIds = []
        const flat = subscriptions.map((sub) => {
            if (orderIds.indexOf(sub.order_id) < 0) {
                orderIds.push(sub.order_id);
                return sub;
            }
            return null;
        }).filter(record => record !== null);
        return flat;
    }

    renderSubscriptions = () => {
        return this.props.getSubscriptionsSuccess !== null
            ? this.flattenSubscriptions(this.props.getSubscriptionsSuccess)
                .map((record, index) => {
                return record.order_id !== '' ? (
                    <tr key={`${record.order_id}-${index}`}>
                        <td>{record.order_id}</td>
                        <td>
                            {this.renderCancelButton(
                                record.user_id, 
                                record.order_id, 
                                record.source,
                                record.environment
                            )}
                        </td>
                    </tr>
                ) : null;
            }) : null;
    }

    renderSubscriptionDetails = () => {
        return this.props.getSubscriptionsSuccess !== null
            ? this.flattenSubscriptions(this.props.getSubscriptionsSuccess)
                .map((record, index) => {
                    // source
                    const source = record.source;
                    const subscriptionData = record.subscriptionData || null;
                    return record.order_id !== '' ? (
                        <Row className={styles.subscriptionRow} key={`${record.order_id}-${index}`}>
                            <Col>
                            {
                                subscriptionData !== null && 
                                source === 'www' && 
                                this.renderWebSubscriptionDataCard(record)
                            }
                            {
                                subscriptionData === null && 
                                source === 'ios' && 
                                this.renderIosSubscriptionDataCard(record)
                            }
                            {
                                subscriptionData === null && 
                                source === 'android' && 
                                this.renderAndroidSubscriptionDataCard(record)
                            }
                            </Col>
                        </Row>
                    ) : null;
            }) : null;
    }

    renderWebSubscriptionDataCard = (record) => {
        const sub = record.subscriptionData;
        const colorVariant = sub.status === 'Active' ? 'success' : 'danger';
        const planTitle = sub.planId === 'PRO-MONTHLY'
            ? 'Monthly Subscription'
            : 'Annual Subscription';
        return (
            <Card>
                <Card.Header className={styles.subscriptionHeader}>{planTitle}</Card.Header>
                <Card.Body>
                    <Card.Title>Next Billing Date {sub.nextBillingDate} </Card.Title>
                    <ListGroup variant="flush" className={styles.subscriptionList}>
                        <ListGroup.Item>Order #: {record.order_id}</ListGroup.Item>
                        <ListGroup.Item><Badge variant={colorVariant}>{sub.status}</Badge></ListGroup.Item>
                        <ListGroup.Item>Price {sub.price}</ListGroup.Item>
                        <ListGroup.Item>Balance {sub.balance}</ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                {sub.status !== 'Canceled' &&
                    <Card.Footer className={styles.subscriptionFooter}>
                        {this.renderCancelButton(
                            record.user_id, 
                            record.order_id, 
                            record.source,
                            record.environment
                        )}
                    </Card.Footer>
                }
            </Card>
        );
    };

    renderIosSubscriptionDataCard = (record) => {
        return (
            <Card>
                <Card.Header className={styles.subscriptionHeader}>Apple Store Subscription</Card.Header>
                <Card.Body>
                <ListGroup variant="flush" className={styles.subscriptionList}>
                    <ListGroup.Item>Order #: {record.order_id}</ListGroup.Item>
                    <ListGroup.Item>Date Created: {record.date_created}</ListGroup.Item>
                </ListGroup>
                </Card.Body>
                <Card.Footer className={styles.subscriptionFooter}>
                        {this.renderCancelButton(
                            record.user_id, 
                            record.order_id, 
                            record.source,
                            record.environment
                        )}
                    </Card.Footer>
            </Card>
        );
    };

    renderAndroidSubscriptionDataCard = (record) => {
        return (
            <Card>
                <Card.Header className={styles.subscriptionHeader}>Android Purchase</Card.Header>
                <Card.Body>
                    <ListGroup variant="flush" className={styles.subscriptionList}>
                        <ListGroup.Item>Order #: {record.order_id}</ListGroup.Item>
                        <ListGroup.Item>Date Created: {record.date_created}</ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Footer className={styles.subscriptionFooter}>
                        {this.renderCancelButton(
                            record.user_id, 
                            record.order_id, 
                            record.source,
                            record.environment
                        )}
                    </Card.Footer>
            </Card>
        );
    };

    renderCancelButton = (userId, orderId, source, environment) => {
        let button = '';
        switch(source) {
            case 'www':
                button = (
                    <Button
                        disabled={source !== 'www'}
                        variant={'primary'}
                        onClick={() => this.cancel(
                            userId, 
                            orderId, 
                            source,
                            environment
                        )}
                    >
                        Cancel Subscription
                    </Button>
                );
            break;

            case 'ios':
                button = (
                    <Button
                        variant={'primary'}
                        onClick={() => this.cancelIos(
                            userId, 
                            orderId, 
                            source,
                            environment
                        )}
                    >
                        Cancel Subscription
                    </Button>
                );
            break;

            case 'android':
                button = (
                    <Button
                        variant={'primary'}
                        onClick={() => this.cancelAndroid(
                            userId, 
                            orderId, 
                            source,
                            environment
                        )}
                    >
                        Cancel Subscription
                    </Button>
                );
            break;

            default:
                button = null;
            break;
        }

        return button;
    }

    renderBraintreeModal = () => {
        return (
            <Modal show={this.state.showBraintreeModal} onHide={this.closeBraintreeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Cancellation</Modal.Title>
                </Modal.Header>
                {this.props.cancelSubscriptionSuccess === null &&
                    <Modal.Body>
                        <p>Are you sure you wish to cancel?</p>
                        <p>You will no longer have access to any of your videos or lessons.</p>
                        {this.props.cancelSubscriptionLoading === true && (
                            <p>Cancelling...</p>
                        )}
                        {this.props.cancelSubscriptionSuccess !== null && (
                            <p>Subscription Cancelled</p>
                        )}
                    </Modal.Body>
                }
                {this.props.cancelSubscriptionSuccess !== null &&
                    <Modal.Body>
                        <p>Subscription Cancelled</p>
                        <p>Your subscription will not be renewed.</p>
                    </Modal.Body>
                }
                {this.props.cancelSubscriptionSuccess === null &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeBraintreeModal}>No, don't cancel</Button>
                        <Button variant="danger" onClick={this.cancelConfirmed}>Yes, Cancel my subscription</Button>
                    </Modal.Footer>
                }
                {this.props.cancelSubscriptionSuccess !== null &&
                   <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeBraintreeModal}>Close</Button>
                    </Modal.Footer>
                }
            </Modal>
        );
    }

    renderIosModal = () => {
        return (
            <Modal show={this.state.showIosModal} onHide={this.closeIosModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Apple Support</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>To cancel a subscription purchased on an iOS device, you can follow the instructions in the article below.</p>
                    <a href="https://support.apple.com/en-us/HT202039" target="blank">Apple Support Article</a>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeIosModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderAndroidModal = () => {
        return (
            <Modal show={this.state.showAndroidModal} onHide={this.closeAndroidModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Google Play Support</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>To cancel a subscription purchased on an Android device, you can follow the instructions in the article below.</p>
                    <a href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en" target="blank">Google Play Support</a>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeAndroidModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderIsSubscribed = () => {
        return (
            <SBAlert variant={'success'}>You are a member</SBAlert>
        );
    }

    renderIsNotSubscribed = () => {
        return (
            <SBAlert>
                You are not a member yet! Click <a href="/cart">Click here to learn more</a>
            </SBAlert>
        );
    }

    renderAccountSubscriptions = () => (
        <Container>
            <Row>
                <Col>
                    <p>{
                        this.props.isSubscribed === true 
                            ? this.renderIsSubscribed()
                            : this.renderIsNotSubscribed()
                        }</p>
                </Col>
            </Row>
        </Container>
    );

    render() {
        const { getSubscriptionsLoading, getSubscriptionsSuccess } = this.props;
        return (
            <PageContainer>
                <SwingbotHeaderClear variant="dark" />
                <SectionHeader title={'My Account'} />
                {getSubscriptionsLoading === true && <Loading title={'Account Details'} />}
                {getSubscriptionsLoading === false && getSubscriptionsSuccess && this.renderAccountSubscriptions()}
                {getSubscriptionsLoading === false && getSubscriptionsSuccess && (
                    <Container className={styles.container}>
                        {this.renderSubscriptionDetails()}
                    </Container>
                )}
                {this.renderIosModal()}
                {this.renderAndroidModal()}
                {this.renderBraintreeModal()}
            </PageContainer>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth, subscribe } = state;
    const { authUser } = auth;
    const {
        getSubscriptionsLoading,
        getSubscriptionsSuccess,
        getSubscriptionsError,
        cancelSubscriptionLoading,
        cancelSubscriptionSuccess,
        cancelSubscriptionError,
    } = subscribe;
    return {
        isSubscribed: userSelectors.isSubscribed(state),
        authUser,
        subscribe,
        getSubscriptionsLoading,
        getSubscriptionsSuccess,
        getSubscriptionsError,
        cancelSubscriptionLoading,
        cancelSubscriptionSuccess,
        cancelSubscriptionError,
    };
  };

const mapDispatchToProps = dispatch => bindActionCreators({
    getSubscriptionsByUserId,
    cancelSubscription,
    cancelSubscriptionReset,
    getUserByUserId
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
