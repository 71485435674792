import { createSelector } from 'reselect';

/**
 * get the stitch video selected
 */
export const getSelectedStitchVideo = (state) => (
  state.stitchVideosAvailable.selectedStitchVideo
);
/**
 * get all of the stitch videos available
 */
export const getStitchVideosAvailable = (state) => (
  state.stitchVideosAvailable.stitchAvailable
);
/**
 * get filtered stitch videos
 */

/**
 * getStitchVideosByTargetSummaryId
 *
 * Filter the stitch videos by target summary id
 * composed selector which can be called simply by passing
 * in the state
 *
 * @param {object} state the entire state
 */
export const getStitchVideosByTargetSummaryId = createSelector(
  [ getSelectedStitchVideo, getStitchVideosAvailable ],
  (stitchVideo, stitchVideosAvailable) => {
    return stitchVideosAvailable.filter(video => {
      return video.target_summary_id === stitchVideo.target_summary_id
    })
  }
);
