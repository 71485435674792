import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/lib/Badge';

const ScoreLabel = (props) => {
  return (
    <Badge
      style={{
        backgroundColor: props.backgroundColor,
        marginRight: '2px',
        color: props.color,
        fontSize: '14px'
      }}
      onMouseOut={props.onMouseOut}
      onMouseOver={props.onMouseOver}
    >
      {props.scoreText}
    </Badge>
  );
}

ScoreLabel.propTypes = {
  scoreText: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string
};

ScoreLabel.defaultProps = {
  scoreText: 0,
  backgroundColor: '#EEEEEE',
  color: '#000000'
};

export default ScoreLabel;
