exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SectionHowItWorks__gridContainer--3fd8B {\n    padding: 0,\n};\n\n.SectionHowItWorks__sectionSubtitle--nYohz {\n    color: #edf8fc;\n    text-transform: uppercase;\n}\n\n.SectionHowItWorks__leftSide--XJISX {}\n\n.SectionHowItWorks__center--2FPGT {\n    justify-content: center;\n}\n", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/About/sections/SectionHowItWorks.css"],"names":[],"mappings":"AAAA;IACI,WAAW;CACd,CAAA;;AAED;IACI,eAAe;IACf,0BAA0B;CAC7B;;AAED,sCAAY;;AAEZ;IACI,wBAAwB;CAC3B","file":"SectionHowItWorks.css","sourcesContent":[".gridContainer {\n    padding: 0,\n};\n\n.sectionSubtitle {\n    color: #edf8fc;\n    text-transform: uppercase;\n}\n\n.leftSide {}\n\n.center {\n    justify-content: center;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"gridContainer": "SectionHowItWorks__gridContainer--3fd8B",
	"sectionSubtitle": "SectionHowItWorks__sectionSubtitle--nYohz",
	"leftSide": "SectionHowItWorks__leftSide--XJISX",
	"center": "SectionHowItWorks__center--2FPGT"
};