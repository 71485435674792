exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SectionHeader__container--1xDtu {\n    border-bottom-color: green;\n    border-bottom-width: 3px;\n}\n\n.SectionHeader__row--3DHKd {\n    padding-top: 40px;\n    padding-bottom: 20px;\n    border-bottom: 2px solid #EEEEEE;\n    margin-bottom: 10px;\n}\n\n.SectionHeader__title--1wvvK {\n    line-height: 38px;\n    font-size: 36px;\n    font-weight: bold;\n    color: #333333;\n}\n\n.SectionHeader__description--1MPiX {\n    line-height: 26px;\n    font-size: 24px;\n    color: #666666;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/common/header/SectionHeader.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,yBAAyB;CAC5B;;AAED;IACI,kBAAkB;IAClB,qBAAqB;IACrB,iCAAiC;IACjC,oBAAoB;CACvB;;AAED;IACI,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;CAClB;;AAED;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;CAClB","file":"SectionHeader.css","sourcesContent":[".container {\n    border-bottom-color: green;\n    border-bottom-width: 3px;\n}\n\n.row {\n    padding-top: 40px;\n    padding-bottom: 20px;\n    border-bottom: 2px solid #EEEEEE;\n    margin-bottom: 10px;\n}\n\n.title {\n    line-height: 38px;\n    font-size: 36px;\n    font-weight: bold;\n    color: #333333;\n}\n\n.description {\n    line-height: 26px;\n    font-size: 24px;\n    color: #666666;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "SectionHeader__container--1xDtu",
	"row": "SectionHeader__row--3DHKd",
	"title": "SectionHeader__title--1wvvK",
	"description": "SectionHeader__description--1MPiX"
};