import React from 'react';
import Image from 'react-bootstrap/lib/Image';
import styles from './AppBadges.css';

const AppBadges = () => (
    <div className={styles.badgeContainer}>
        <a href="https://itunes.apple.com/us/app/swingbot-golf-swing-video/id516200328?mt=8">
            <Image
                className={styles.appStoreBadge}
                src={require('../../assets/images/app-store-logo.png')}
            />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.databake.swingbot">
            <Image
                className={styles.appStoreBadge}
                src={require('../../assets/images/google_web.png')}
            />
        </a>
    </div>
);

export default AppBadges;
