import {
  GET_VIDEO_LIST,
  GET_VIDEO_LIST_ERROR,
  GET_VIDEO_LIST_SUCCESS
} from '../types'
// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');
/**
 * get video list
 */
export const getVideosByUserId = (userId, limit=500, offset=0) => {
  return (dispatch) => {
    dispatch({
      type: GET_VIDEO_LIST,
      payload: null
    });
    const endpoint = endpointForApi('video', 'users/'+ userId +'/videos?limit='+ limit +'&offset=' + offset);
    return axios({
      url: endpoint,
      method: 'get',
      headers: headersForApi('video')
    })
      .then(result => {
        dispatch({
          type: GET_VIDEO_LIST_SUCCESS,
          payload: result.data.data
        });
      })
      .catch(result => {
        dispatch({
          type: GET_VIDEO_LIST_ERROR,
          payload: result
        });
      });
    };
  };
