import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux'
import { verifyToken, isTokenExpired } from '../util/jwt';

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  // TODO: figure out the expired stuff..
  // const {
  //   token,
  //   data,
  //   success
  // } = rest.authUser;
  // check to see if the token is expired...
  //const isExpired = isTokenExpired(rest.authUser.token);
  const isExpired = false;
  return (
    <Route
      {...rest}
      render={ (props) => {
      // for now lets just check the user, but in the future
      // maybe we should check the token?
      // should we check to see if the user is subscribed?
      return (
        isExpired !== true &&
        rest.authUser !== null &&
        rest.authUser.token !== null &&
        rest.authUser.success === true
      ) ? (
        <Redirect
          to={{
            pathname: '/video',
            state: { from: props.location }
          }}
        />
      ) : (
        <Component { ...props } />
      )}}
    />
  )
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
}

export default connect(
  mapStateToProps, null, null, { pure: false }
)(AuthorizedRoute)
