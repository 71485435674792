import React from 'react';
import styles from './AnalysisError.css'
import { 
    Col,
    Row,
    Container 
} from 'react-bootstrap/lib';

class AnalysisError extends React.Component {
    render() {
        return (
            <Container className={styles.noVideosContainer}>
                <Row>
                    <Col md={6}>
                        <h2>Something went wrong.</h2>
                        <p className={styles.subheading}>The video results could not be analyzed.</p>
                    </Col>
                    <Col md={6}>
                        <p className={styles.tips}>Here are some tips for recording your swing:</p>
                        <ul>
                            <li>Make sure your ENTIRE body is visible in the recording</li>
                            <li>Make sure you record your ENTIRE swing</li>
                            <li>Make sure you record in ample light (no crazy shadows!)</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default AnalysisError;
