export const LOGIN_USER_SUCCESS = 'account/login-user-success';

// Complete Registration
export const COMPLETE_REGISTRATION = 'user/complete-registration';
export const COMPLETE_REGISTRATION_SUCCESS = 'user/complete-registration-success';
export const COMPLETE_REGISTRATION_ERROR = 'user/complete-registration-error';
export const COMPLETE_REGISTRATION_RESET = 'user/complete-registration-reset';

// Register
export const REGISTER_USER = 'register-user';
export const REGISTER_USER_SUCCESS = 'register-user-success';
export const REGISTER_USER_ERROR = 'register-user-error';
export const REGISTER_USER_RESET = 'register-user-reset';

// Choose Password
export const CHOOSE_PASSWORD = 'choose-password';
export const CHOOSE_PASSWORD_SUCCESS = 'choose-password-success';
export const CHOOSE_PASSWORD_ERROR = 'choose-password-error';
export const CHOOSE_PASSWORD_RESET = 'choose-password-reset';