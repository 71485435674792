import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import PageContainer from '../../common/container/PageContainer';
import SwingbotHeaderClear from '../../common/navbar/SwingbotHeaderClear';
import StitchListItem from '../StitchListItem';
import { getStitchDetailByStitchId } from '../../../actions/Stitch/StitchDetailActions';
import { stitchSelectors } from '../../../selectors';
import { colorForScore } from '../../../util/misc';
import ScoreLabel from '../../common/ScoreLabel/ScoreLabel';
import ReactPlayer from 'react-player';

// import Breadcrumb from 'react-bootstrap/lib/Breadcrumb';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Container from 'react-bootstrap/lib/Container';
import Breadcrumb from 'react-bootstrap/lib/Breadcrumb';
// import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';



import {
  deactivateStitchVideo,
  deactivateStitchVideoReset,
} from '../../../actions';
import "../../../../node_modules/video-react/dist/video-react.css";
import styles from './StitchDetail.css';
import './Breadcrumb.css';

class StitchDetail extends React.Component {
  state = {
    height: 0,
    width: 0,
  }

  componentDidMount() {
    this.props.deactivateStitchVideoReset();
    this.scrollPageToTop();
    this.updateDimensions();
    const { stitchId } = this.props.location.state;
    console.log('stitch id: ', stitchId);
    this.props.getStitchDetailByStitchId(stitchId);
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillReceiveProps = (nextProps) => {
    this.scrollPageToTop();
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  }

  deactivateVideo = () => {
    const { authUser } = this.props;
    const { gm_stitch_id } = this.props.location.state.stitchData;
    if (gm_stitch_id && authUser.data.user_id) {
      this.props.deactivateStitchVideo(authUser.data.user_id, gm_stitch_id);
    }
  }

  updateDimensions = () => {
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ height, width });
  }

  scrollPageToTop = () => {
    // on click of a related video it will reload the SAME
    // page and maintain scroll position, so we have to
    // scroll the page programatically
    window.scrollTo(0,0);
  }
  /**
   * renderRelatedVideos
   */
  renderRelatedVideos = () => {
    const relatedItems = this.props.relatedVideos.map(video => (
      <Col xs={12} sm={6} md={4} lg={3} key={video.gm_stitch_id}>
        <StitchListItem
          key={video.thumbnail}
          thumbnail={video.thumbnail}
          data={video}
          details={false}
          onClick={() => {
            this.props.deactivateStitchVideoReset();
            this.props.history.push({
              pathname: '/instruction/' + video.gm_stitch_id,
              state: { stitchData: video }
            });
          }}
          style={{
            border: 'none',
            backgroundColor: 'transparent'
          }}
        />
      </Col>
    ));
    return (
      <Row
        style={{
          padding: '10px',
        }}>
        {relatedItems}
      </Row>
    );
  }
  /**
   * render
   */
  render() {
    const {
      video_url,
      display_name,
      text_definition,
      summary,
      percentScore,
      probability,
    } = this.props.location.state.stitchData;

    const { stitchDetails, stitchDetailError } = this.props;
    console.log('stitch detail ', stitchDetails, stitchDetailError);
    const tooltipScore = (
    	// <Tooltip id="tooltipScore" className="tooltip">
    		<p>Based on our analysis your swing score for {display_name} is {percentScore}%</p>
    	// </Tooltip>
    );
    const tooltipProbability = (
      // <Tooltip id="tooltipProbability" className="tooltip">
        <p>We are {probability}% confident in the results based on our detection</p>
      // </Tooltip>
    );

    const score = percentScore !== null ? ` ${String(percentScore).toUpperCase()}`.toUpperCase() : null;
    // color for score
    let color = 'gray';
    if (score !== null) {
      color = colorForScore(parseInt(percentScore, 10));
    }
    // color for probability
    let probabilityColor = 'gray';
    if (probability !== null) {
      probabilityColor = colorForScore(parseInt(probability, 10));
    }

    return (
      <div className={styles.stitchDetailContainer}>
        <PageContainer>
          <div className={styles.pageContainer}>
            <SwingbotHeaderClear variant="dark" />
            {/* this is the VIDEO container */}
            {/* this is the main container for the content */}
            <div className={styles.stitchDetailBodyContainer}>
            <Container>
              <Row>
                <Col md={4}>
                  <div
                    className={styles.stitchPlayerDetailContainer}
                    ref={(divElement) => this.divElement = divElement }
                  >
                    { video_url !== null &&
                      <ReactPlayer 
                        url={video_url} 
                        width={360}
                        height={540}
                        controls
                      />
                    }
                  </div>
                </Col>
                <Col md={8} lg={8}>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/instruction">
                      Instruction
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{display_name}</Breadcrumb.Item>
                  </Breadcrumb>
                  <h2 className={styles.detailHeader}>{display_name}</h2>
                  {score &&
                    <div className={styles.labelScore}>
                      <h4>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={tooltipScore}
                        >
                          <ScoreLabel
                            backgroundColor={color}
                            scoreText={`SCORE ${score}%`}
                            color={'#FFFFFF'}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={tooltipProbability}
                        >
                          <ScoreLabel
                            color={'#FFFFFF'}
                            backgroundColor={probabilityColor}
                            scoreText={`ACCURACY ${probability}%`}
                          />
                        </OverlayTrigger>
                      </h4>
                    </div>
                  }
                  <p className={styles.content}>{text_definition}</p>
                  <p className={styles.contentSummary}>{summary}</p>
                  {/* <hr />
                  { this.props.stitchDeactivateVideoSuccess === null &&
                    <div style={{ justifyContent: 'flex-end' }}>
                      <p><em>You can remove this video from your library at any time, be careful, this cannot be undone.</em></p>
                      <Button
                        disabled={this.props.loadingDeactivateStitchVideo}
                        onClick={this.deactivateVideo}>
                        Remove From Library
                      </Button>
                    </div>
                  }
                  { this.props.stitchDeactivateVideoSuccess !== null &&
                    <div style={{ justifyContent: 'flex-end' }}>
                      <p><em>Your video has been removed, and will no longer show up in your library.</em></p>
                    </div>
                  } */}
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <div className={styles.relatedVideoContainer}>
                    <h4>More {display_name} Videos</h4>
                    {this.renderRelatedVideos()}
                  </div>
                </Col>
              </Row> */}
            </Container>
            </div>
          </div>
        </PageContainer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    loadingDeactivateStitchVideo,
    stitchDeactivateVideoSuccess,
    stitchDetailError,
    stitchDetails
  } = state.stitchDeactivateVideo;
  const {
    authUser
  } = state.auth;
  return {
    relatedVideos: stitchSelectors.getStitchVideosByTargetSummaryId(state),
    loadingDeactivateStitchVideo,
    stitchDeactivateVideoSuccess,
    authUser,
    stitchDetails,
    stitchDetailError
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getStitchDetailByStitchId,
  deactivateStitchVideo,
  deactivateStitchVideoReset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StitchDetail);
