import React from 'react';
import PropTypes from 'prop-types';
import styles from './FeatureContainer.css';
import classNames from 'classnames';
import { colors } from '../../../styles';
class FeatureContainer extends React.Component {

    state = {
        isOver: false
    }

    handleMouseOver = () => {
        this.setState({
            isOver: true
        });
        this.props.handleMouseOver(this.props.type);
    };

    handleMouseOut = () => {
        this.setState({
            isOver: false
        });
    }

    render() {
        // render the mouseover style
        const overStyle = this.state.isOver === true ? 'featureOver' : 'featureOut';
        const overStyleColor = this.state.isOver === true
            ? {
                backgroundColor: colors.veryLightBlue,
                border: `1px solid ${colors.white}`,
                // color: colors.white,
            }
            : {
                backgroundColor: colors.white,
                border: `1px solid ${colors.white}`,
            };
        const containerStyles = classNames(overStyle, styles.featureContainer);
        return (
            <div 
                style={overStyleColor}
                className={containerStyles}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
            >
                <p className={styles.featureTitle}>{this.props.title}</p>
                <p className={styles.featureDescription}>{this.props.description}</p>
            </div>
        );
    }
};

FeatureContainer.defaultProps = {
    type: 'video-library',
    title: 'Feature 1',
    description: 'video-library'
};

FeatureContainer.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default FeatureContainer;
