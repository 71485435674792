/**
 * auth
 *
 * Authentication into the system
 * Get token for subsequent requests.
 */
import {
  LOGIN_TYPING,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_RESET,
  LOGOUT_USER,
  INVALID_TOKEN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from './types'
import { push } from 'react-router-redux'
// Google analytics
import ReactGA from 'react-ga';
// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../api/swingbotApi';
const axios = require('axios');
/**
 * login typing beginning
 * the point is to remove the "errors" if there were any
 */
export const loginTyping = () => {
  return(dispatch) => {
    dispatch({
      type: LOGIN_TYPING,
    });
  }
}
/**
 * login the user
 */
export const login = (email, password, redirect = false) => {
  return(dispatch) => {
    dispatch({
      type: LOGIN_USER,
      payload: null
    });
    var body = {
      email: email,
      password: password
    };
    return axios({
      method: 'post',
      data: body,
      url: endpointForApi('www', 'account/login'),
      headers: headersForApi('www')
    })
      .then(result => dispatch(loginSuccess(result, redirect)))
      .catch(result => dispatch(loginFailure(result)));
    };
  }; // end login

export const loginSuccess = (result, redirect = false) => {
  return (dispatch) => {
    // set the global token.
    ReactGA.set({ userId: result.data.user_id });
    window.sessionStorage.setItem("user", JSON.stringify(result));
    window.sessionStorage.setItem("token", result.data.token);
    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: result
    });
    // determine where to send the user...
    redirect === false
      ? dispatch(push('/video'))
      : dispatch(push(redirect));
  }
}

export const loginFailure = (result) => {
  return (dispatch) => {
    return dispatch({
      type: LOGIN_USER_ERROR,
      payload: result
    });
  }
}

export const loginReset = () => {
  return (dispatch) => {
    return dispatch({
      type: LOGIN_USER_RESET,
      payload: null
    });
  }
}

export const loginRequired = (dispatch) => {
  return (dispatch) => {
    dispatch({ type: INVALID_TOKEN });
    return dispatch(push('/login'));
  }
}
/**
 * logout user
 */
export const logout = () => {
  return (dispatch) => {
    window.sessionStorage.removeItem('user')
    window.sessionStorage.removeItem('token');
    // we also want to set the state to its initial
    // form...or remove the state from localStorage!
    localStorage.removeItem('state');
    dispatch({ type: LOGOUT_USER });
    // now send the user back to login...
    dispatch(push('/login'));
  }
}
/**
 * forgotPassword
 * @TODO fill in this function
 */
export const forgot = (email) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD,
      payload: email
    });
  }
}
/**
 * reset password
 * @TODO fill in this function
 */
export const reset = (password, code) => {
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD,
    });
  }
}
