import {
  GET_USER_DETAIL,
  GET_USER_DETAIL_ERROR,
  GET_USER_DETAIL_SUCCESS
} from '../types'
// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');
/**
 * get user detail
 */
export const getUserByUserId = (userId) => {
  return (dispatch) => {
    dispatch({
      type: GET_USER_DETAIL,
      payload: null
    });
    // lets make the request to the API
    return axios({
      method: 'get',
      url: endpointForApi('www', 'users/' + userId),
      headers: headersForApi('www')
    })
      .then(result => {
        dispatch({
          type: GET_USER_DETAIL_SUCCESS,
          payload: result.data.data
        });
      })
      .catch(result => {
        dispatch({
          type: GET_USER_DETAIL_ERROR,
          payload: result
        });
      });
    };
  };
