import React from 'react';
import Section from '../../common/container/Section';
import { colors } from '../../../styles';

import Image from 'react-bootstrap/lib/Image';
import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import FeatureContainer from './FeatureContainer';
import styles from './SectionHowItWorks.css';

const videoLibraryImage = require('../../../assets/images/feature-video-library.png');
const cameraAngleImage = require('../../../assets/images/feature-camera-angle.png');
const analysisDetailImage = require('../../../assets/images/feature-analysis-detail.png');

class SectionHowItWorks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imageSrc: videoLibraryImage,
        };
        this.imageSrc = videoLibraryImage;
    }

    changeImage = (imageType) => {
        switch (imageType) {
            case 'video-library':
                this.setState({
                    imageSrc: videoLibraryImage,
                });
            break;
            case 'camera-angle':
                this.setState({
                    imageSrc: cameraAngleImage
                });
            break;
            case 'analysis-detail':
                this.setState({
                    imageSrc: analysisDetailImage
                });
            break;
            default:
                this.setState({
                    imageSrc: videoLibraryImage
                });
            break;
        }
    };

    render() {
        const subTitleColor = { color: colors.lightGreen };
        return (
            <Section
                name='howitworks'
                containerStyle={{
                    backgroundColor: colors.white
                }}
                style={{
                    backgroundColor: colors.white,
                    height: undefined,
                    width: '85%'
                }}

            >
                <Container className={styles.gridContainer}>
                    {/* <Row style={{ textAlign: 'center', paddingTop: '40px', paddingBottom: '40px' }}>
                        <Col lg={12}>
                            <p className="huge">Features</p>
                            <p>If you are looking to hit more fairways, and get real swing analysis anytime, anywhere, you have come to the right place.</p>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col lg={6} xs={12} className={styles.leftSide}>
                            <FeatureContainer
                                title={'Video Library'}
                                type={'video-library'}
                                description={'Access your video library in the app. Play videos and view your analysis quickly and easily.'}
                                handleMouseOver={this.changeImage}
                            />
                            <FeatureContainer
                                title={'Analysis Results'}
                                type={'analysis-detail'}
                                description={'View your analysis in the app or on the website. We provide text results, custom video with annotations, and an analysis accuracy percentage.'}
                                handleMouseOver={this.changeImage}
                            />
                            <FeatureContainer
                                title={'Multiple Camera Angles'}
                                type={'camera-angle'}
                                description={'Choose either Face on or Down the line camera angles for your analysis.'}
                                handleMouseOver={this.changeImage}
                            />
                        </Col>
                        <Col lg={6} className={styles.center}>
                            <Image
                                height={700}
                                src={this.state.imageSrc}
                                fluid
                            />
                        </Col>
                    </Row>
                </Container>
            </Section>
        );
    }
}

export default SectionHowItWorks;

