/**
 * UserDetailReducer
 *
 * Fetch the userData from the database for a user
 */
import {
  GET_USER_DETAIL,
  GET_USER_DETAIL_ERROR,
  GET_USER_DETAIL_SUCCESS
} from '../../actions/types'

// set the initial state of the reducer
 const INITIAL_STATE = {
   userData: {},
   loadingUserDetail: null,
   loadingUserDetailError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     case GET_USER_DETAIL:
       return {
         ...state,
         userData: [],
         loadingUserDetail: true,
         loadingUserDetailError: null
       }
     break;

     case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loadingUserDetail: false,
        loadingUserDetailError: null
      }
     break;

     case GET_USER_DETAIL_ERROR:
      return {
        ...state,
        userData: [],
        loadingUserDetail: false,
        loadingUserDetailError: action.payload
      }
     break;

     default:
       return state;
   }
 };
