import { routerReducer } from 'react-router-redux';
// Auth
import AuthReducer from './AuthReducer'
// Google
import GoogleVerifyTokenReducer from './GoogleVerifyTokenReducer'
// User
import UserDetailReducer from './User/UserDetailReducer'
import UserRegistrationReducer from './User/UserRegistrationReducer'
import UserActivationReducer from './User/UserActivationReducer'
import UserForgotPasswordReducer from './User/UserForgotPasswordReducer'
import UserResetPasswordReducer from './User/UserResetPasswordReducer'
import UserChoosePasswordReducer from './User/UserChoosePasswordReducer'
import UserCompleteRegistrationReducer from './User/UserCompleteRegistrationReducer'
import UserUnsubscribeReducer from './User/UserUnsubscribeReducer'
// Program
import ProgramListReducer from './Program/ProgramListReducer'
import ProgramDetailReducer from './Program/ProgramDetailReducer'
// Video
import VideoListReducer from './Video/VideoListReducer'
import VideoDetailReducer from './Video/VideoDetailReducer'
// Analysis
import AnalysisListReducer from './Analysis/AnalysisListReducer'
import AnalysisDetailReducer from './Analysis/AnalysisDetailReducer'
import AnalysisResultHistoryReducer from './Progress/AnalysisResultHistoryReducer'
// Stitch
import StitchAvailableListReducer from './Stitch/StitchAvailableListReducer'
import StitchActivateVideoReducer from './Stitch/StitchActivateVideoReducer'
import StitchDeactivateVideoReducer from './Stitch/StitchDeactivateVideoReducer'
import StitchDetailReducer from './Stitch/StitchDetailReducer'
// Upload
import UploadUrlReducer from './Upload/UploadUrlReducer'
import LiveBroadcastListReducer from './Webinar/LiveBroadcastListReducer';
import AnalyzeVideoDetailReducer from './Analysis/AnalyzeVideoDetailReducer';
// Braintree
import SubscribeReducer from './Subscribe/SubscribeReducer';
// Connection
import ConnectionReducer from './Connection/ConnectionReducer';

export const reducers = {
  router: routerReducer,
  auth: AuthReducer,
  registerAccount: UserRegistrationReducer,
  activateAccount: UserActivationReducer,
  forgotPassword: UserForgotPasswordReducer,
  resetPassword: UserResetPasswordReducer,
  choosePassword: UserChoosePasswordReducer,
  completeRegistration: UserCompleteRegistrationReducer,
  unsubscribe: UserUnsubscribeReducer,
  googleVerifyToken: GoogleVerifyTokenReducer,
  // user
  userDetail: UserDetailReducer,
  // video
  videoList: VideoListReducer,
  videoDetail: VideoDetailReducer,
  // analysis
  analysisList: AnalysisListReducer,
  analysisDetail: AnalysisDetailReducer,
  analyzeVideoDetail: AnalyzeVideoDetailReducer,
  analysisHistory: AnalysisResultHistoryReducer,
  // program
  programList: ProgramListReducer,
  programDetail: ProgramDetailReducer,
  // Stitch
  stitchVideosAvailable: StitchAvailableListReducer,
  stitchActivateVideo: StitchActivateVideoReducer,
  stitchDeactivateVideo: StitchDeactivateVideoReducer,
  stitchDetail: StitchDetailReducer,
  // Upload
  uploadVideoUrl: UploadUrlReducer,
  // activate
  activate: UserActivationReducer,
  liveBroadcastList: LiveBroadcastListReducer,
  subscribe: SubscribeReducer,
  connection: ConnectionReducer,
};
