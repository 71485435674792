/**
 * Submit a forgot password request
 */
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
} from '../types'
// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');
/**
 * forgot password request
 */
export const forgotPassword = (email) => {
  return(dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD,
      payload: null
    });
    var body = { email: email };
    return axios({
      method: 'post',
      data: body,
      url: endpointForApi('www', 'account/forgot'),
      headers: headersForApi('www')
    })
      .then(result => dispatch(forgotPasswordSuccess(result)))
      .catch(result => dispatch(forgotPasswordFailure(result)));
    };
  }; // end activate

export const forgotPasswordSuccess = (result) => {
  return (dispatch) => {
    return dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: result
    });
  }
}

export const forgotPasswordFailure = (result) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_ERROR,
      payload: result
    });
  }
}
