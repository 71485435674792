import React from 'react';
import Image from 'react-bootstrap/lib/Image';
import Col from 'react-bootstrap/lib/Col';
import './Account/LoginAccount.css'

class NotFound extends React.Component {
  /**
   * state to hold the form vars
   */
  state = {
    email: '',
  }

  changeEmail = (event) => {
    // reset errors...
    this.props.loginTyping();
    this.setState({
      email: event.target.value.trim(),
    })
  }
  /**
   * validate the fields
   */
  validateFields = () => {
    return (this.state.email !== '');
  }
  /**
   * submit the registration
   */
  submitForm = () => {
    const {
      email,
    } = this.state;
    this.props.forgotPassword(email);
  }
  /**
   * render the form
   */
  render() {
    return(
      <div className="mainLoginContainer">
        <div className="innerLoginContainer">
          <div>
            <div className="topHeader">
              <div>
                <Image
                  src={require('../assets/images/swingbot_logo.png')}
                  width={60}
                  height={60}
                  className="center-block"
                />
              </div>
              <h2>Mulligan</h2>
              <p className="subHeading">Let's try that again.</p>
            </div>
            <Col sm={12}>
              <hr style={{ borderColor: '#CCCCCC' }}/>
              <div className="bottomContainer">
                <a href="/login">Go to Login</a>
              </div>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}
export default NotFound
