import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import PageContainer from '../common/container/PageContainer';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import Button from 'react-bootstrap/lib/Button';

import { submitFeedbackGeneral, submitFeedbackGeneralReset } from '../../actions';
import SwingbotHomeHeader from '../common/navbar/SwingbotHomeHeader';

class FeedbackGeneral extends React.Component {
    state = {
        userId: this.props.match.params.userId,
    }

    componentDidMount() {
        // we want to fetch the stitch details
        this.props.submitFeedbackGeneralReset();
    }

    scrollPageToTop = () => {
        // on click of a related video it will reload the SAME
        // page and maintain scroll position, so we have to
        // scroll the page programatically
        window.scrollTo(0,0);
    }

    validateForm = () => {
        const { text } = this.state;
        const formData = {};
        for (const field in this.refs) {
        formData[field] = this.refs[field].value;
        }
    }

    submitForm = () => {
        console.log(this.state);
        this.props.submitFeedbackGeneral(1, this.state);
    }

    handleRadioChange = (e) => {
        this.setState({[e.target.name]: e.target.id});
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }
    /**
     * Radio Group
     * 
     * @param {array} options the values of the radio group
     * @param {string} name the name of the radio group (identifier)
     */
    renderRadioGroup = (name, options) => {
        return options.map(option => {
            const optionValue = option.replace(/ /g , '_').toLowerCase();
            return (
                <InputGroup.Radio
                    key={`${name}_${optionValue}`}
                    id={optionValue}
                    onChange={this.handleRadioChange}
                    name={name}
                >
                    {option}
                </InputGroup.Radio>
            );
        });
    }
  /**
   * render
   * 
   */
  render() {
    return (
        <div className="stitchDetailContainer">
        <PageContainer>
          <div className="pageContainer">
            <div className="stitchDetailBodyContainer">
            <SwingbotHomeHeader />
            <Container>
              <Row>
                <Col md={12}>

            <form>
                <FormGroup>
                    <p>{'How many times do you play golf a year?'}</p><br />
                        {this.renderRadioGroup(
                            'howManyTimes', [
                                '1 to 10',
                                '10 to 20',
                                '20 to 30',
                                'over 30'
                            ])
                        }
                </FormGroup>
                <FormGroup>
                    <p>{'Have you ever had a golf lesson?'}</p><br />
                        {this.renderRadioGroup(
                            'hadAGolfLesson', [
                                'Yes',
                                'No',
                                'I don\'t need one'
                            ])
                        }
                </FormGroup>
                <FormGroup>
                    <p>{'What is your typical golf score?'}</p><br />
                        {this.renderRadioGroup(
                            'typicalGolfScore', [
                                'Over 100',
                                '90 to 100',
                                '80 to 90',
                                '70 to 80',
                                'Below 70'
                            ])
                        }
                </FormGroup>
                <FormGroup>
                    <p>{'What is your preferred way to pay for lessons?'}</p><br />
                        {this.renderRadioGroup(
                            'transactionType', [
                                'I would subscribe monthly for unlimited lessons',
                                'I would subscribe monthly for a few lessons',
                                'I would pay per lesson'
                            ])
                        }
                </FormGroup>
                <FormGroup>
                    <p>{'What is a fair price PER lesson?'}</p><br />
                        {this.renderRadioGroup(
                            'fairPricePerLesson', [
                                '$29.99 per lesson',
                                '$19.99 per lesson',
                                '$9.99 per lesson',
                                '$4.99 per lesson',
                                'Free'
                            ])
                        }
                </FormGroup>
                <FormGroup>
                    <p>{'What is the biggest reason for trying Swingbot?'}</p><br />
                        {this.renderRadioGroup(
                            'reasonForTrying', [
                                'To improve my golf swing',
                                'To save money on golf lessons',
                                'To test out new technology'
                            ])
                        }
                </FormGroup>

                <Button onClick={this.submitForm}>Submit</Button>
            </form>
        </Col>
        </Row>
        </Container>
        </div>
        </div>
        </PageContainer>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
  const { authUser } = state.auth;
  return {
    authUser
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    submitFeedbackGeneral,
    submitFeedbackGeneralReset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackGeneral);
