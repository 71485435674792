exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SBBootstrap__alert--3AX8- {\n    font-size: 16px;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/common/bootstrap/SBBootstrap.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;CACnB","file":"SBBootstrap.css","sourcesContent":[".alert {\n    font-size: 16px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"alert": "SBBootstrap__alert--3AX8-"
};