/**
 * Activate a user account with a code
 */
import {
  ACTIVATE_USER,
  ACTIVATE_USER_ERROR,
  ACTIVATE_USER_SUCCESS,
} from '../types'

// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');
/**
 * activate a new user
 */
export const activate = (code) => {
  return(dispatch) => {
    dispatch({
      type: ACTIVATE_USER,
      payload: code
    });
    return axios({
      method: 'get',
      url: endpointForApi('www', 'account/activate/' + code),
      headers: headersForApi('www')
    })
      .then(result => dispatch(activateSuccess(result)))
      .catch(result => dispatch(activateFailure(result)));
    };
  }; // end activate

export const activateSuccess = (result) => {
  return (dispatch) => {
    dispatch({
      type: ACTIVATE_USER_SUCCESS,
      payload: result
    });
  }
}

export const activateFailure = (result) => {
  return (dispatch) => {
    dispatch({
      type: ACTIVATE_USER_ERROR,
      payload: result
    });
  }
}
