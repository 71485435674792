import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'

import Image from 'react-bootstrap/lib/Image';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import DropdownItem from 'react-bootstrap/lib/DropdownItem';

import './SwingbotHeader.css'
import {
  logout
} from '../../../actions'

class SwingbotHomeHeader extends React.Component {
  render() {
    return(
      <Navbar fixedTop fluid>
        <Navbar.Header>
          <div className="mainContainer">
            <div className="logo">
              <Image
                src={require('../../../assets/images/swingbot_logo.png')}
                height={32}
                width={32}
              />
              <h4 style={{ marginLeft: '5px', fontSize: '20px' }}>Swingbot</h4>
            </div>
          </div>
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav className="navigationItems" pullRight>
            <DropdownItem href="https://swingbot.uservoice.com">Support</DropdownItem>
            <Nav.Item eventKey={2} onClick={() => {
                this.props.navLogin();
              }}>
      				Member Login
      			</Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
}
const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  navLogin: () => push('/login'),
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SwingbotHomeHeader)
