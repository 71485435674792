import {
    STITCH_ACTIVATE_VIDEO,
    STITCH_ACTIVATE_VIDEO_ERROR,
    STITCH_ACTIVATE_VIDEO_SUCCESS,
    STITCH_ACTIVATE_VIDEO_RESET
  } from '../types'
  // endpoint helpers and axios
  import { endpointForApi, headersForApi } from '../../api/swingbotApi';
  const axios = require('axios');
  /**
   * Activate a stitch video
   */
  export const activateStitchVideo = (userId, stitchVideoId) => {
    return (dispatch) => {
      dispatch({
        type: STITCH_ACTIVATE_VIDEO,
        payload: null
      });
      const endpoint = endpointForApi('stitch', `users/${userId}/stitch/${stitchVideoId}/activate`);
      return axios({
        url: endpoint,
        method: 'put',
        headers: headersForApi('stitch')
      })
        .then(result => {
          dispatch({
            type: STITCH_ACTIVATE_VIDEO_SUCCESS,
            payload: result.data.data
          });
        })
        .catch(result => {
          dispatch({
            type: STITCH_ACTIVATE_VIDEO_ERROR,
            payload: result
          });
        });
      };
    };
  
    export const activateStitchVideoReset = () => {
        return (dispatch) => {
            dispatch({
              type: STITCH_ACTIVATE_VIDEO_RESET,
              payload: null
            });
        }
    };