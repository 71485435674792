exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".AnalyzeVideoDetail__pageContainer--1i2Bf {\n    margin-top: 90px;\n    margin-bottom: 90px;\n}\n\n.AnalyzeVideoDetail__videoContainer--27YN1 {\n    padding: 20px;\n    justify-content: center;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/Analysis/AnalyzeVideoDetail.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;CACvB;;AAED;IACI,cAAc;IACd,wBAAwB;CAC3B","file":"AnalyzeVideoDetail.css","sourcesContent":[".pageContainer {\n    margin-top: 90px;\n    margin-bottom: 90px;\n}\n\n.videoContainer {\n    padding: 20px;\n    justify-content: center;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"pageContainer": "AnalyzeVideoDetail__pageContainer--1i2Bf",
	"videoContainer": "AnalyzeVideoDetail__videoContainer--27YN1"
};