/**
 * Register a new user
 */
import * as actionCreators from './actions';
import * as services from './services';
import { push } from 'react-router-redux'
/**
 * register a new user
 */
export const register = (email, emailConfirm, password, firstName, lastName, redirect = false) => dispatch => { 
  dispatch(actionCreators.registerUser(email, emailConfirm, password, firstName, lastName, redirect));
  return services.registerUser(email, emailConfirm, password, firstName, lastName, redirect)
    .then(result => dispatch(registerSuccess(result, redirect)))
    .catch(result => dispatch(registerFailure(result)));
};
/**
 * registerSuccess helper function
 * @param {object} result 
 * @param {mixed} redirect 
 */
export const registerSuccess = (result, redirect) => {
  return (dispatch) => {
    // set the global token.
    window.sessionStorage.setItem("user", result.data);
    window.sessionStorage.setItem("token", result.data.token);
    dispatch(actionCreators.registerUserSuccess(result));
    dispatch(actionCreators.loginUserSuccess(result));
    
    // push to the home page or redirect to the page we need.
    redirect === false
    ? dispatch(push('/video'))
    : dispatch(push(redirect))
  }
}

export const registerFailure = (result) => dispatch => {
  dispatch(actionCreators.registerUserError(result));
}

export const registerReset = () => dispatch => {
  dispatch(actionCreators.registerUserReset());
}
