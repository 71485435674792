/**
 * UserListReducer
 *
 * Fetch the list of users from the database
 */
import {
  AUTH_CHECK_TOKEN,
  AUTH_CHECK_TOKEN_ERROR,
  AUTH_CHECK_TOKEN_SUCCESS,
  LOGIN_TYPING,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_RESET,
  LOGOUT_USER,
  INVALID_TOKEN,
  EXPIRED_TOKEN,
} from '../actions/types'
// set the initial state of the reducer
 const INITIAL_STATE = {
   authUser: null,
   loadingLoginUser: false,
   loadingLoginUserError: null,
   token: null,
   checkingToken: false,
   checkingTokenError: null,
   isInvalid: null,
   isExpired: null,
 };

 export default (state = INITIAL_STATE, action) => {
   if (action !== undefined) {
     if ('type' in action) {
       switch (action.type) {

         case INVALID_TOKEN:
          return {
            ...state,
            isInvalid: true
          }

         case EXPIRED_TOKEN:
          return {
            ...state,
            isInvalid: true,
            isExpired: true,
          }

         /**
          * checking token...
          */
        case AUTH_CHECK_TOKEN:
           return {
             ...state,
             authUser: null,
             checkingToken: true,
             checkingTokenError: null
           }

         /**
          * check token success!
          */
        case AUTH_CHECK_TOKEN_SUCCESS:
           return {
             ...state,
             authUser: action.payload.data,
             checkingToken: false,
             checkingTokenError: null,
             isInvalid: null,
             isExpired: null,
           }

         /**
          * check token fail
          */
        case AUTH_CHECK_TOKEN_ERROR:
           return {
             ...state,
             authUser: null,
             checkingToken: false,
             checkingTokenError: action.payload
           }

         /**
          * user is typing so remove the errors...
          */
        case LOGIN_TYPING:
          return {
            ...state,
            loadingLoginUserError: null,
          }
        /**
         * login user
         */
        case LOGIN_USER:
           return {
             ...state,
             authUser: null,
             loadingLoginUser: true,
             loadingLoginUserError: null
           }

        case LOGIN_USER_SUCCESS:
          return {
            ...state,
            authUser: action.payload.data,
            loadingLoginUser: false,
            loadingLoginUserError: null,
            token: action.payload.token,
            isInvalid: null,
            isExpired: null
          }

        case LOGIN_USER_ERROR:
        console.log(action.payload.response);
          return {
            ...state,
            authUser: null,
            loadingLoginUser: false,
            loadingLoginUserError: ((action.payload.response || {}).data || {}).error || null
          }

        case LOGIN_USER_RESET:
          return {
            authUser: null,
            loadingLoginUser: false,
            loadingLoginUserError: null
          }

        case LOGOUT_USER:
          return {
            ...INITIAL_STATE,
          }

        default:
          return state;
       }
     }
   }
  return state;
 };
