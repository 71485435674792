/**
 * VideoListReducer
 *
 * Fetch the list of videos from the database
 */
import {
  GET_VIDEO_LIST,
  GET_VIDEO_LIST_ERROR,
  GET_VIDEO_LIST_SUCCESS
} from '../../actions/types'

// set the initial state of the reducer
 const INITIAL_STATE = {
   videos: [],
   totalVideos: null,
   loadingVideoList: null,
   loadingVideoListError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     case GET_VIDEO_LIST:
       return {
         ...state,
         videos: [],
         loadingVideoList: true,
         loadingVideoListError: null
       }

     case GET_VIDEO_LIST_SUCCESS:
      return {
        ...state,
        totalVideos: action.payload.count,
        videos: action.payload.records,
        loadingVideoList: null,
        loadingVideoListError: null
      }

     case GET_VIDEO_LIST_ERROR:
      return {
        ...state,
        videos: [],
        loadingVideoList: null,
        loadingVideoListError: action.payload
      }

     default:
       return state;
   }
 };
