import * as actionCreators from './actions';
import * as services from './services';

/**
 * Get connections for user 
 * @param {int} userId 
 */
export const getConnectionsForUser = userId => dispatch => {
    dispatch(actionCreators.getConnectionsForUser(userId));
    return services.getConnectionsForUser(userId)
        .then(response => dispatch(actionCreators.getConnectionsForUserSuccess(response)))
        .catch(error => dispatch(actionCreators.getConnectionsForUserError(error)));
};
/**
 * Get invitation details using the nonce
 * @param {string} nonce 
 */
export const getInvitationByNonce = nonce => dispatch => {
    dispatch(actionCreators.getInvitationByNonce(nonce));
    return services.getInvitationByNonce(nonce)
        .then(response => dispatch(actionCreators.getInvitationByNonceSuccess(response)))
        .catch(error => dispatch(actionCreators.getInvitationByNonceError(error)));
};

/**
 * Accept the invitation request
 * @param {int} userId the invitee id
 * @param {string} nonce 
 */
export const acceptInvitationRequest = (userId, nonce) => dispatch => {
    dispatch(actionCreators.acceptInvitationRequest(userId, nonce));
    return services.acceptInvitationRequest(userId, nonce)
        .then(response => dispatch(actionCreators.acceptInvitationRequestSuccess(response)))
        .catch(error => dispatch(actionCreators.acceptInvitationRequestError(error)));
}
/**
 * Deny the invitation request
 * @param {int} userId the invitee id
 * @param {string} nonce the invitation request code
 */
export const denyInvitationRequest = (userId, nonce) => dispatch => {
    dispatch(actionCreators.denyInvitationRequest(userId, nonce));
    return services.denyInvitationRequest(userId, nonce)
        .then(response => dispatch(actionCreators.denyInvitationRequestSuccess(response)))
        .catch(error => dispatch(actionCreators.denyInvitationRequestError(error)));
}
/**
 * Reset all of the invitation requests
 */
export const resetInvitationRequests = () => dispatch => {
    dispatch(actionCreators.acceptInvitationRequestReset());
    dispatch(actionCreators.denyInvitationRequestReset());
}