import React from 'react'
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'
import PageContainer from '../common/container/PageContainer'
import SwingbotHeaderClear from '../common/navbar/SwingbotHeaderClear';
import Image from 'react-bootstrap/lib/Image';
import Button from 'react-bootstrap/lib/Button';
import SectionWithImage from '../common/container/SectionWithImage';
// About Sections
import SectionHowItWorks from '../About/sections/SectionHowItWorks';
// import SectionTestimonials from './sections/SectionTestimonials';
// import SectionCostCalculator from './sections/SectionCostCalculator';
// import SectionMembership from './sections/SectionMembership';
import SectionSampleImages from '../About/sections/SectionSampleImages';
import SectionFreeTrial from '../About/sections/SectionFreeTrial';
// import Footer from '../common/Footer';
// import { colors, misc } from '../../styles';
import classNames from 'classnames';
import styles from './HomeBasic.css';

/**
 * Page to tell the user more about the free trial and 
 * convince them that this is a good idea
 */
class HomeBasic extends React.Component {
  /**
   * render
   */
  render() {
    return (
        <PageContainer title="Videos">
          <div className={styles.pageContainer}>
            <SwingbotHeaderClear absolute />
            <SectionWithImage
                className={styles.containerFreeTrialHome}
                style={{
                    height: '600px',
                    marginBottom: 0, 
                    paddingBottom: 0,
                    backgroundColor: 'transparent'
                }}
            >
                {/* <SwingbotHeaderClear /> */}
                <p className={classNames(styles.huge, styles.white, styles.center )}>Meet Swingbot, your new golf instructor</p>
                <p className={classNames(styles.white)}>Receive unlimited automated swing analyses anytime, anywhere, in minutes.</p>
                <br />
                <Button 
                    id="get-started-register"
                    className={styles.freeTrialButton} 
                    variant="success" 
                    size="large"
                    onClick={() => this.props.navRegister()}
                >
                    Click to get Started
                </Button>
                <div className={styles.badgeContainer}>
                    <a 
                        href="https://itunes.apple.com/us/app/swingbot-golf-swing-video/id516200328?mt=8"
                        class="ios-download-button"
                    >
                        <Image
                            id="ios-download-btn"
                            className={styles.appStoreBadge}
                            src={require('../../assets/images/app-store-logo.png')}
                        />
                    </a>
                    <a 
                        href="https://play.google.com/store/apps/details?id=com.databake.swingbot"
                        class="android-download-button"
                    >
                        <Image
                            id="android-download-btn"
                            className={styles.appStoreBadge}
                            src={require('../../assets/images/google_web.png')}
                        />
                    </a>
                </div>
            </SectionWithImage>
            <SectionHowItWorks />
            <SectionSampleImages />
            <SectionFreeTrial 
                containerStyle={{ height: '700px' }}
                props={this.props}
            />
        </div>
        </PageContainer>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    navRegister: () => push('/register'),
}, dispatch)

export default connect(null, mapDispatchToProps)(HomeBasic);
