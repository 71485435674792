/**
 * Middleware
 * check to see if the token is up to date!
 */
import { endpointForApi, headersForApi } from '../api/swingbotApi';
import { push } from 'react-router-redux';
import {
  REFRESH_TOKEN,
  AUTH_CHECK_TOKEN_SUCCESS,
} from '../actions/types'
//import { refreshToken, loginRequired } from '../actions';
const jwt = require('jsonwebtoken');
const axios = require('axios');
/**
 * token middleware to refresh...
 * The action is a typical dispatched action,
 * so we can use the action to intercept or pass along
 * the chain.
 *
 * In the case of checking the token, we can verify the token
 * for EACH action that gets dispatched, and if there is an expired
 * token, refresh the token and then set the new token values
 * to the sessionStorage, and then go to the next action...
 */
/**
 * 1. Check the token for expired.
 * 2. Refresh the token, wait for a promise
 */
export const tokenMiddlewareCheck = ({ dispatch, getState }) => next => action => {

  const token = window.sessionStorage.getItem('token');
  let state = getState();
  // if this is a "thunk' like a fetch of videos etc,
  // we can intercept this and refresh before anything "odd"
  // happens...
  // if (typeof action === 'function') {
  //   // get the token status
  //   const tokenStatus = getTokenStaus(token);
  //   // if the token is expired, we can refresh it here...
  //   // prior to another action...
  //   if (state) {
  //     if (tokenStatus === 'EXPIRED') {
  //       // if(!state.refreshTokenPromise) {
  //       //   console.log('refreshing?');
  //       return refreshToken(dispatch, state, token).then(() => next(action));
  //       // }
  //       // tokenIsExpired(dispatch);
  //     }
  //   }
  // }
  // return as usual
  return next(action);
};
/**
 * determine the status of the token
 */
function getTokenStaus(token) {
  try {
    const decoded = jwt.verify(token, process.env.REACT_APP_WWW_JWT_SECRET);
    if (decoded !== null) {
      return 'VALID';
    } else {
      return 'INVALID';
    }
  } catch (e) {
    // if it is expired, its still a "valid" token...
    if (e.name === 'TokenExpiredError') {
      return 'EXPIRED';
    } else {
      return 'INVALID';
    }
  }
}
/**
 * refresh the token from the server
 */
function refreshToken(dispatch, state, token) {
  let refreshTokenPromise = new Promise(function(resolve, reject) {
    return axios({
      method: 'post',
      data: { token },
      url: endpointForApi('www', 'account/refresh_token'),
      headers: headersForApi('www')
    })
    .then(freshToken => {
      // get the refresh token from the result
      const refreshToken = freshToken.data.data.token;
      // we have to decode the fresh token...
      const decoded = jwt.decode(refreshToken, process.env.REACT_APP_WWW_JWT_SECRET);
      // set the global token, but we MIGHT set this
      window.sessionStorage.setItem("user", decoded);
      window.sessionStorage.setItem("token", refreshToken);
      dispatch({
        type: AUTH_CHECK_TOKEN_SUCCESS,
        payload: decoded
      });
      resolve();
    })
    .catch(err => {
      window.sessionStorage.removeItem("user");
      window.sessionStorage.removeItem("token");
      return reject(dispatch(push('/login')))
    });
  });
  // dispatch the PROMISE to the state, until it resolves...then becomes null...
  dispatch({
    type: REFRESH_TOKEN,
    payload: refreshTokenPromise
  });
  return refreshTokenPromise;
}

function tokenIsExpired(dispatch) {
  window.sessionStorage.removeItem("user");
  window.sessionStorage.removeItem("token");
  return dispatch(push('/login'));
}
