/**
 * helpers for the api
 */
/**
 * get the headers for the api specified
 */
export const headersForApi = (api) => {
  let headers = {};
  switch(api.toLowerCase()) {
    /**
     * swingbot website api
     */
    case 'www':
      headers = {
        'X-Api-Key': process.env.REACT_APP_WWW_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    case 'account':
      headers = {
        'X-Api-Key': process.env.REACT_APP_WWW_ACCOUNT_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    case 'user':
      headers = {
        'X-Api-Key': process.env.REACT_APP_USER_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    // stitch videos
    case 'stitch':
      headers = {
        'X-Api-Key': process.env.REACT_APP_WWW_STITCH_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    // webinar
    case 'webinar':
      headers = {
        'X-Api-Key': process.env.REACT_APP_WEBINAR_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    case 'feedback':
      headers = {
        'X-Api-Key': process.env.REACT_APP_WWW_FEEDBACK_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    case 'video':
      headers = {
        'X-Api-Key': process.env.REACT_APP_WWW_VIDEO_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    case 'analysis':
      headers = {
        'X-Api-Key': process.env.REACT_APP_WWW_ANALYSIS_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    case 'transaction':
      headers = {
        'X-Api-Key': process.env.REACT_APP_WWW_TRANSACTION_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    case 'connection':
      headers = {
        'X-Api-Key': process.env.REACT_APP_WWW_CONNECTION_API_KEY,
        'Authorization': window.sessionStorage.getItem("token")
      }
    break;
    // universal api (swingbot-admin)
    default:
      // null
      headers = {}
    break;
  }
  return headers;
};
/**
 * tack on the endpoint to the base url
 */
export const endpointForApi = (api, tail) => {
  let endpoint = null;
  switch(api.toLowerCase()) {
    case 'www':
      endpoint = process.env.REACT_APP_WWW_API_URL + tail;
    break;
    case 'account':
      endpoint = process.env.REACT_APP_WWW_ACCOUNT_API_URL + tail;
    break;
    case 'user':
      endpoint = process.env.REACT_APP_USER_API_URL + tail;
    break;
    case 'stitch':
      endpoint = process.env.REACT_APP_WWW_STITCH_API_URL + tail;
    break;
    case 'webinar':
      endpoint = process.env.REACT_APP_WEBINAR_API_URL + tail;
    break;
    case 'feedback':
      endpoint = process.env.REACT_APP_WWW_FEEDBACK_API_URL + tail;
    break;
    case 'video':
      endpoint = process.env.REACT_APP_WWW_VIDEO_API_URL + tail;
    break;
    case 'analysis':
      endpoint = process.env.REACT_APP_WWW_ANALYSIS_API_URL + tail;
    break;
    case 'transaction':
      endpoint = process.env.REACT_APP_WWW_TRANSACTION_API_URL + tail;
    break;
    case 'connection':
      endpoint = process.env.REACT_APP_WWW_CONNECTION_API_URL + tail;
    break;
    default:
      endpoint = null;
    break;
  }
  return endpoint;
};
