// import {
//   GOOGLE_VERIFY_TOKEN,
//   GOOGLE_VERIFY_TOKEN_ERROR,
//   GOOGLE_VERIFY_TOKEN_SUCCESS,
//   LOGIN_USER_SUCCESS,
//   GOOGLE_VERIFY_TOKEN_RESET
// } from './types'
import * as actions from './actions';
import { push } from 'react-router-redux'
// endpoint helpers and axios
import { verifyToken } from '../../util/jwt'
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');

/**
 * login the user
 */
export const googleVerifyToken = (token) => {
  return(dispatch) => {
    dispatch(actions.googleVerifyToken(token));
    var body = { token };
    return axios({
      method: 'post',
      data: body,
      url: endpointForApi('account', 'account/google-verify-token'),
      headers: headersForApi('account')
    })
      .then(result => {
        // set the global token.
        window.sessionStorage.setItem("user", JSON.stringify(result));
        window.sessionStorage.setItem("token", result.data.token);
        dispatch(actions.googleVerifyTokenSuccess(result.data));
        // also dispatch the payload to the auth reducer...
        return verifyToken(result.data.token)
          .then(decodedToken => {
            dispatch(actions.loginUserSuccess(result));
            dispatch(push('/video'));
          })
          .catch(err => err);
      })
      .catch(result => dispatch(actions.googleVerifyTokenFailure(result)));
    };
  }; // end google verify

  export const googleVerifyTokenReset = () => {
    return (dispatch) => dispatch(actions.googleVerifyTokenReset());
  }