/**
 * UploadUrlReducer
 */
import {
  GET_UPLOAD_URL,
  GET_UPLOAD_URL_ERROR,
  GET_UPLOAD_URL_SUCCESS
} from '../../actions/types'

// set the initial state of the reducer
 const INITIAL_STATE = {
   uploadUrl: null,
   totalStitchAvailable: null,
   loadingUploadUrl: null,
   loadingUploadUrlError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     case GET_UPLOAD_URL:
       return {
         ...state,
         uploadUrl: null,
         loadingUploadUrl: true,
         loadingUploadUrlError: null
       }
     break;

     case GET_UPLOAD_URL_SUCCESS:
     console.log(action.payload);
      return {
        ...state,
        uploadUrl: action.payload,
        loadingUploadUrl: null,
        loadingUploadUrlError: null
      }
     break;

     case GET_UPLOAD_URL_ERROR:
      return {
        ...state,
        uploadUrl: null,
        loadingUploadUrl: null,
        loadingUploadUrlError: action.payload
      }
     break;

     default:
       return state;
   }
 };
