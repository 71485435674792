/**
 * GET /analysis/{analysisId}
 */
import {
  GET_ANALYSIS_DETAIL,
  GET_ANALYSIS_DETAIL_ERROR,
  GET_ANALYSIS_DETAIL_SUCCESS
} from '../types'
// load up the AWS API Client
// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');

/**
 * GET /analysis/:analysisId
 * get analysis details
 */
export const getVideoByVideoId = (analysisId) => {
  return (dispatch) => {
    dispatch({
      type: GET_ANALYSIS_DETAIL,
      payload: null
    });
    return axios({
      method: 'get',
      url: endpointForApi('www', 'analysis/' + analysisId),
      headers: headersForApi('www')
    })
      .then(result => {
        dispatch({
          type: GET_ANALYSIS_DETAIL_SUCCESS,
          payload: result.data.data
        });
      })
      .catch(result => {
        dispatch({
          type: GET_ANALYSIS_DETAIL_ERROR,
          payload: result
        });
      });
    };
  };
