import React from 'react';
import PropTypes from 'prop-types';
import sectionStyles from './Section.css';

const SectionWithImage = (props) => {
  return (
  <div
    id={props.name}
    className={props.className}
  >
    <div
      className={sectionStyles.innerContainerSection}
      style={props.style}
    >
      {props.children}
    </div>
  </div>
);
  }

SectionWithImage.propTypes = {
  backgroundColor: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

SectionWithImage.defaultProps = {
  backgroundColor: '#FFFFFF',
  containerStyle: {
    width: '100%',
    height: undefined,
    backgroundColor: '#FFFFFF',
  },
  width: '100%',
  height: undefined,
  style: {
    height: undefined,
    width: '100%',
    backgroundColor: '#FFFFFF'
  }
}

export default SectionWithImage;
