import React from 'react'
import PropTypes from 'prop-types';
import styles from './PageContainer.css'
import Footer from '../Footer';

/**
 * PageContainer
 */
class PageContainer extends React.Component {
  render() {
    // lets check the props to see if we need to account 
    // for the header
    const mainStyle = this.props.header === true 
      ? styles.mainContainerPage
      : styles.mainContainerPageNoHeader;

    return (
      <div className={styles.outerContainer}>
        <div className={mainStyle}>
          <div className={styles.innerContainerPage}>
            {this.props.children}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

PageContainer.defaultProps = {
  header: true,
}

PageContainer.propTypes = {
  header: PropTypes.bool,
}

export default PageContainer;

