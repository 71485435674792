import React from 'react';
import Image from 'react-bootstrap/lib/Image';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import PageContainer from '../common/container/PageContainer';
import { GoogleLogin } from 'react-google-login'
import queryString from 'query-string';
import { 
  login, 
  loginTyping, 
  loginReset, 
} from '../../actions'
import { googleVerifyToken, googleVerifyTokenReset } from '../../actions/GoogleVerifyToken';
import { getInvitationByNonce } from '../../actions/Connection';
import { FieldGroup } from '../../util/misc';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PulseLoader } from 'halogenium';
import { redirectFromQueryString } from '../../util/redirect';
import styles from './LoginAccount.css';

class LoginAccount extends React.Component {
  /**
   * state to hold the form vars
   */
  state = {
    email: '',
    password: '',
  }

  componentDidMount = () => {
    // clear out the information 
    this.props.loginReset();
    this.props.googleVerifyTokenReset();
    const query = queryString.parse(this.props.location.search);
    // let's fetch the invitation request by nonce if there is a cr in the query string...
    if (query['cr']) {
      // fetching the invitation data
      this.props.getInvitationByNonce(query.cr);
    }
  }

  changeEmail = (event) => {
    // reset errors...
    this.props.loginTyping();
    this.setState({
      email: event.target.value.trim(),
    })
  }

  changePassword = (event) => {
    // reset errors...
    this.props.loginTyping();
    this.setState({
      password: event.target.value.trim(),
    })
  }
  /**
   * validate the fields
   */
  validateFields = () => {
    return (
      this.state.email !== '' &&
      this.state.password !== '' &&
      this.state.password.length > 4
    );
  }
  /**
   * submit the registration
   */
  submitForm = () => {
    const redirect = redirectFromQueryString(this.props.location.search);
    const {
      email,
      password
    } = this.state;
    this.props.login(email, password, redirect);
  }
  /**
   * get the response from the google login
   */
  handleGoogleLogin = (response) => {
    if ('tokenId' in response) {
      console.log(response);
      // lets get the token from the google login, send it to our server and decode it
      // we have to send the token to our server and verify the token
      // as well as handle any register/login for the user...
      // the end result in both cases should be a userData object
      this.props.googleVerifyToken(response.tokenId);
    }
  }
  renderLoginError = () => {
    const { loadingLoginUserError } = this.props;
    return loadingLoginUserError !== null ? (
      <div>
        <p className={styles.loginError}>{loadingLoginUserError}</p>
      </div>
    ) : null;
  }
  /**
   * render the form
   */
  render() {
    const canSubmit = this.validateFields();
    const {
      loadingLoginUser,
      loadingLoginUserError,
      loadingGoogleVerifyToken,
    } = this.props;
    const validationState = loadingLoginUserError !== null ? 'error' : null;
    return (
      <PageContainer header={false}>
      <div className={styles.pageContainer}>
        <div className={styles.innerLoginContainer}>
          <div>
            <div className={styles.topHeader}>
              <div>
                <Image
                  src={require('../../assets/images/swingbot_logo.png')}
                  width={60}
                  height={60}
                  className="center-block"
                />
              </div>
              <h2>Welcome to Swingbot</h2>
              <p className={styles.subHeading}>Automated Swing Analysis</p>
              {this.renderLoginError()}
            </div>
            { (loadingLoginUser === false || loadingLoginUser === null) && loadingGoogleVerifyToken === false &&
              <div className={styles.bottomLoginContainer}>
              <Form>
                <Col sm={12}>
                  <FieldGroup
                    id="formControlsEmail"
                    type="email"
                    label="Email"
                    placeholder="Email address"
                    onChange={this.changeEmail}
                    value={this.state.email}
                    autoFocus
                    validationstate={validationState}
                    autoComplete="new-username"
                  />
                  <FieldGroup
                    id="formControlsPassword"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    onChange={this.changePassword}
                    value={this.state.password}
                    validationstate={validationState}
                    autoComplete="new-password"
                  />
                </Col>
                <Col sm={12}>
                  <FormGroup>
                    <Button
                      disabled={!canSubmit}
                      block
                      onClick={this.submitForm}
                      size="large"
                    >
                        Click to Login
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
              <hr />
              <div className={styles.socialDivider}>
                <p className={styles.socialDividerText}>OR</p>
              </div>
              <Col sm={12}>
                <div className={styles.googleContainer}>
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Continue with Google"
                    onSuccess={this.handleGoogleLogin}
                    onFailure={this.handleGoogleLogin}
                    className={styles.googleLogin}
                  />
                </div>
              </Col>
              <Col sm={12}>
                <div className={styles.bottomContainer}>
                  <a href="/forgot">Forgot your password?</a>
                </div>
                <hr style={{ borderColor: '#CCCCCC' }}/>
                <div className={styles.bottomContainer}>
                  Not a member? <a href="/register">Sign up</a>
                </div>
              </Col>
            </div>
            }
            { loadingLoginUser === true &&
              <div style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}>
                <div style={{ textAlign: 'center' }}>
                  <PulseLoader color="green" size="16px" margin="4px" />
                </div>
              </div>
            }
            { loadingGoogleVerifyToken === true &&
              <div style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}>
                <div style={{ textAlign: 'center' }}>
                  <PulseLoader color="red" size="16px" margin="4px" />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      </PageContainer>
    );
  }
}
const mapStateToProps = ({ auth, googleVerifyToken, connection }) => {
  const {
    token,
    authUser,
    loadingLoginUser,
    loadingLoginUserError
  } = auth;
  const {
    googleVerifyTokenData,
    loadingGoogleVerifyToken,
    loadingGoogleVerifyTokenError
  } = googleVerifyToken;
  const {
    getInvitationDataLoading,
    getInvitationDataError,
    invitationData,
  } = connection;
  console.log(invitationData);
  return {
    token,
    authUser,
    loadingLoginUser,
    loadingLoginUserError,
    googleVerifyTokenData,
    loadingGoogleVerifyToken,
    loadingGoogleVerifyTokenError,
    getInvitationDataLoading,
    getInvitationDataError,
    invitationData,
  };
}
const mapDispatchToProps = dispatch => bindActionCreators({
  login,
  googleVerifyToken,
  googleVerifyTokenReset,
  loginTyping,
  loginReset,
  getInvitationByNonce
}, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(LoginAccount)
