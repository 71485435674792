import React from 'react';

import Image from 'react-bootstrap/lib/Image';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';

import { unsubscribe, unsubscribeClear} from '../../actions'
import { FieldGroup } from '../../util/misc';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PulseLoader } from 'halogenium';
import './LoginAccount.css'

class Unsubscribe extends React.Component {
    componentDidMount = () => {
        this.props.unsubscribeClear();
    }
  /**
   * submit the registration
   */
  submitForm = () => {
    const { email } = this.props.match.params;
    this.props.unsubscribe(email);
  }
  /**
   * render the form
   */
  render() {
    const {
      unsubscribeSuccess,
      loadingUnsubscribe,
      loadingUnsubscribeError
    } = this.props;
    const validationState = loadingUnsubscribeError !== null ? 'error' : null;
    return(
      <div className="mainLoginContainer">
        <div className="innerLoginContainer">
          <div>
            <div className="topHeader">
              <div>
                <Image
                  src={require('../../assets/images/swingbot_logo.png')}
                  width={60}
                  height={60}
                  className="center-block"
                />
              </div>
              <h2>We hate to see you go</h2>
              <p className="subHeading">Click unsubscribe to stop receiving emails.</p>
            </div>
            { loadingUnsubscribe === false && unsubscribeSuccess === null &&
              <div className="bottomLoginContainer">
              <Form vertical>
                <Col sm={12}>
                  <FieldGroup
                    id="formControlsEmail"
                    type="email"
                    label="Email"
                    placeholder="Email address"
                    value={this.props.match.params.email}
                    autoFocus
                    disabled
                  />
                </Col>
                <Col sm={12}>
                  <FormGroup>
                    <Button
                      disabled={false}
                      block
                      bsSize={'large'}
                      bsStyle={'login'}
                      onClick={this.submitForm}
                    >
                        Click to Unsubscribe
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
              <Col sm={12}>
                <hr style={{ borderColor: '#CCCCCC' }}/>
                <div className="bottomContainer">
                  Already a member? <a href="/login">Sign in</a>
                </div>
              </Col>
            </div>
            }
            { loadingUnsubscribe === true &&
              <div style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}>
                <div style={{ textAlign: 'center' }}>
                  <PulseLoader color="green" size="16px" margin="4px" />
                </div>
              </div>
            }
            { loadingUnsubscribe === false && unsubscribeSuccess === true &&
              <div style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}>
                <div style={{ textAlign: 'center' }}>
                  <h4>You are now unsubscribed.</h4>
                </div>
                <Col sm={12}>
                  <hr style={{ borderColor: '#CCCCCC' }}/>
                  <div className="bottomContainer">
                    <a href="/login">Back to Login</a>
                  </div>
                </Col>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ unsubscribe }) => {
  const {
    unsubscribeSuccess,
    loadingUnsubscribe,
    loadingUnsubscribeError,
  } = unsubscribe;
  return {
    unsubscribeSuccess,
    loadingUnsubscribe,
    loadingUnsubscribeError,
  };
}
const mapDispatchToProps = dispatch => bindActionCreators({
  unsubscribe,
  unsubscribeClear
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe)
