import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageContainer from '../common/container/PageContainer'
import SwingbotHeaderClear from '../common/navbar/SwingbotHeaderClear';
import SectionHeader from '../common/header/SectionHeader';
import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import { getConnectionsForUser } from '../../actions/Connection';
import Loading from '../common/Loading';
import styles from './Connections.css';
import ConnectionItem from './ConnectionItem';

class Connections extends React.Component {
    componentDidMount = () => {
        // fetch the connections for the user
        this.props.getConnectionsForUser(this.props.user.user_id);
    }
    handleOnPress = (connection) => {
        console.log(connection);
    }
    renderConnectionList = () => {
        const { connectionsForUser } = this.props;
        return connectionsForUser ? connectionsForUser.data.data.map(connection => {
            return (
                <ConnectionItem 
                    connection={connection} 
                    onPress={this.handleOnPress}
                    key={connection.invitation_id}
                />
            );
        }) : (null);
    }
    render() {
        return (
            <PageContainer title="Connections">
                <SwingbotHeaderClear variant="dark" />
                 <SectionHeader title={'Connections'} />
                 { !this.props.getConnectionsForUserLoading &&
                    <Container className={styles.container}>
                        <Row>
                            {this.renderConnectionList()}
                        </Row>
                    </Container>
                 }
                 { this.props.getConnectionsForUserLoading && <Loading title={'Connections'} />}
            </PageContainer>
        );
    }
}

const mapStateToProps = ({ auth, connection }) => {
    const { authUser } = auth;
    const { 
        getConnectionsForUserLoading,
        getConnectionsForUserError,
        connectionsForUser,
    } = connection;
    return {
        user: authUser.data,
        getConnectionsForUserLoading,
        getConnectionsForUserError,
        connectionsForUser
    };
};

const mapDispatchToProps= dispatch => bindActionCreators({
    getConnectionsForUser
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Connections);
