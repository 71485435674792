import * as actionTypes from './types';

export const googleVerifyToken = (payload) => ({
    type: actionTypes.GOOGLE_VERIFY_TOKEN,
    payload
});

export const googleVerifyTokenSuccess = (result) => ({
    type: actionTypes.GOOGLE_VERIFY_TOKEN_SUCCESS,
    payload: result
});

export const googleVerifyTokenFailure = (error) => ({
    type: actionTypes.GOOGLE_VERIFY_TOKEN_ERROR,
    payload: error
});

export const googleVerifyTokenReset = () => ({
    type: actionTypes.GOOGLE_VERIFY_TOKEN_RESET,
});

export const loginUserSuccess = (result) => ({
    type: actionTypes.LOGIN_USER_SUCCESS,
    payload: result
});

