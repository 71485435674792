/**
 * ProgramListReducer
 *
 * Fetch the list of programs from the database
 */
import {
  GET_PROGRAM_LIST,
  GET_PROGRAM_LIST_ERROR,
  GET_PROGRAM_LIST_SUCCESS
} from '../../actions/types'

// set the initial state of the reducer
 const INITIAL_STATE = {
   programs: [],
   loadingProgramList: false,
   loadingProgramListError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     case GET_PROGRAM_LIST:
       return {
         ...state,
         programs: [],
         loadingProgramList: true,
         loadingProgramListError: null
       }
     break;

     case GET_PROGRAM_LIST_SUCCESS:
      return {
        ...state,
        programs: action.payload,
        loadingProgramList: false,
        loadingProgramListError: null
      }
     break;

     case GET_PROGRAM_LIST_ERROR:
      return {
        ...state,
        programs: [],
        loadingProgramList: false,
        loadingProgramListError: action.payload
      }
     break;

     default:
       return state;
   }
 };
