import 'bootstrap'
import './scss/app.scss';
import React from 'react'
import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/es/integration/react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'
import './index.css';

// components
// import Home from './components/Home/Home';
import About from './components/About/About';
import StitchList from './components/Stitch/StitchList';
import StitchDetail from './components/Stitch/Detail/StitchDetail';
import LoginAccount from './components/Account/LoginAccount';
import ForgotPassword from './components/Account/ForgotPassword';
import ResetPassword from './components/Account/ResetPassword';
import ChoosePassword from './components/Account/ChoosePassword';
import Unsubscribe from './components/Account/Unsubscribe';
import ActivateAccount from './components/Account/ActivateAccount';
import MyAccount from './components/Account/MyAccount';
import ActivateAccountWithForm from './components/Account/ActivateAccountWithForm';
import NotFound from './components/NotFound';
import PleaseUseApp from './components/PleaseUseApp';
import Terms from './components/Terms';
import PrivacyPolicy from './components/PrivacyPolicy';
import Loading from './components/common/Loading';
import AuthorizedRoute from './components/AuthorizedRoute';
import AuthorizedRedirectRoute from './components/AuthorizedRedirectRoute';
import AuthorizedHomeRoute from './components/AuthorizedHomeRoute';
import AuthorizedSubscriberRoute from './components/AuthorizedSubscriberRoute';
import AuthorizedNonSubscriberRoute from './components/AuthorizedNonSubscriberRoute';
import RegisterAccount from './components/Account/Register';
import WebinarList from './components/Webinar/WebinarList';
import Webinar from './components/Webinar/Webinar';
import AboutSubscription from './components/About/Subscription';
import ConnectionRequest from './components/Connection/ConnectionRequest';
import ConnectionRequestCheck from './components/Account/ConnectionRequestCheck';
import Connections from './components/Connection/Connections';
// Feedback
import FeedbackGeneral from './components/Feedback/General';

// Subscribe
import Cart from './components/Subscribe/Cart';
import CartThankyou from './components/Subscribe/CartThankyou';

import { configureStore } from './store';
// import SampleStitch from './components/Stitch/Detail/SampleStitch';
import AnalysisList from './components/Analysis/AnalysisList';
import AnalyzeVideoDetail from './components/Analysis/AnalyzeVideoDetail';
import Lab from './components/Lab/Lab';
import VideoList from './components/Videos/VideoList';
import AnalysisResultHistory from './components/Progress/AnalysisResultHistory';

// Google Analytics will work with router using this helper.
import withTracker from './components/withTracker';
import AboutFreeTrial from './components/About/FreeTrial';
import HomeBasic from './components/Home/HomeBasic';

// Favicon
require('./assets/images/favicon.ico');

// have to create history here and in the store...or it will break.
const { store, persistor, history } = configureStore()
const reload = () => window.location.reload();

const App = () => (
  <Provider store={store}>
    <PersistGate
      loading={<Loading />}
      persistor={persistor}>
    <ConnectedRouter
      history={history}
      onUpdate={() => window.scrollTo(0, 0)}
    >
      <div>
        <Switch>
          <AuthorizedHomeRoute
            exact
            path="/"
            component={withTracker(HomeBasic, {} )}
          />
          <Route
            exact
            path="/free-trial"
            component={withTracker(AboutFreeTrial, {} )}
          />
          <Route
            exact
            path="/about"
            component={withTracker(About, {} )}
          />
          {/* <Route
            exact
            path="/free-trial"
            component={withTracker(AboutFreeTrial, {} )}
          /> */}
          {/* <AuthorizedSubscriberRoute
            exact
            path="/instruction"
            component={withTracker(PleaseUseApp, {} )}
          /> */}
          <AuthorizedRoute
            exact
            path="/instruction"
            component={withTracker(StitchList, {} )}
          />
          <AuthorizedRoute
            exact
            path="/instruction/:stitchId"
            component={StitchDetail}
          />
          {/* <Route
            exact
            path="/instruction/sample/:stitchId"
            component={withTracker(SampleStitch, {} )}
          /> */}
          <AuthorizedRoute
            exact
            path="/account"
            component={withTracker(MyAccount, {} )}
          />
          <Route
            exact
            path="/account/activate/:code"
            component={withTracker(ActivateAccount, {} )}
          />
          <AuthorizedRedirectRoute
            exact
            path="/connection/request"
            component={withTracker(ConnectionRequest, {} )}
          />
          {/* <Route
            exact
            path="/analysis"
            component={AnalysisList}
          /> */}
          <AuthorizedRoute
            exact
            path="/analysis/:videoId"
            component={withTracker(AnalyzeVideoDetail, {} )}
          />
          {/* <AuthorizedSubscriberRoute
            exact
            path="/lab/:videoId"
            component={Lab}
          /> */}
          {/* <AuthorizedSubscriberRoute
            exact
            path="/history"
            component={AnalysisResultHistory}
          /> */}
          <AuthorizedRoute
            exact
            path="/video"
            component={withTracker(VideoList, {} )}
          />
          <AuthorizedRoute
            exact
            path="/connection"
            component={withTracker(Connections, {} )}
          />
          <Route
            exact
            path="/connection-request"
            component={withTracker(ConnectionRequestCheck, {} )}
          />
          <Route
            exact
            path="/register"
            component={withTracker(RegisterAccount, {} )}
          />
          <AuthorizedSubscriberRoute
            exact
            path="/webinar"
            component={withTracker(WebinarList, {} )}
          />
          <AuthorizedSubscriberRoute
            exact
            path="/webinar/:id"
            component={withTracker(Webinar, {} )}
          />
          <Route 
            exact 
            path="/feedback/general/:userId" 
            component={withTracker(FeedbackGeneral, {} )}
          />
          <Route
            exact
            path="/reset/:code"
            component={ResetPassword}
          />
          <Route
            exact
            path="/choose-password"
            component={ChoosePassword}
          />
          <Route 
            exact 
            path="/forgot" 
            component={withTracker(ForgotPassword, {} )} 
          />
          <Route 
            exact 
            path="/login" 
            component={withTracker(LoginAccount, {} )} 
          />
          <Route 
            exact 
            path="/terms" 
            component={withTracker(Terms, {} )} 
          />
          <Route 
            exact 
            path="/privacy" 
            component={withTracker(PrivacyPolicy, {} )} 
          />
          <Route 
            exact 
            path="/account/unsubscribe/:email" 
            component={withTracker(Unsubscribe, {} )} 
          />
          <Route 
            exact 
            path="/about/subscription" 
            component={withTracker(AboutSubscription, {} )} 
          />
          <AuthorizedNonSubscriberRoute
            exact
            path="/cart"
            component={withTracker(Cart, {} )}
          />
          <AuthorizedRoute
            exact
            path="/cart-thankyou"
            component={withTracker(CartThankyou, {} )}
          />
          {/* <AuthorizedRoute exact path="/videos" component={VideoList} /> */}
          <Route 
            component={withTracker(NotFound, {} )} 
          />
        </Switch>
      </div>
    </ConnectedRouter>
  </PersistGate>
</Provider>
);

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
