/**
 * AnalysisListReducer
 *
 * Fetch the list of analysis from the database
 */
import {
  GET_ANALYSIS_LIST,
  GET_ANALYSIS_LIST_ERROR,
  GET_ANALYSIS_LIST_SUCCESS
} from '../../actions/types'

// set the initial state of the reducer
 const INITIAL_STATE = {
   analysis: [],
   totalAnalysis: null,
   loadingAnalysisList: null,
   loadingAnalysisListError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     case GET_ANALYSIS_LIST:
       return {
         ...state,
         analysis: [],
         loadingAnalysisList: true,
         loadingAnalysisListError: null
       }

     case GET_ANALYSIS_LIST_SUCCESS:
     console.log(action.payload);
      return {
        ...state,
        totalAnalysis: action.payload.count,
        analysis: action.payload.records,
        loadingAnalysisList: false,
        loadingAnalysisListError: null
      }

     case GET_ANALYSIS_LIST_ERROR:
      return {
        ...state,
        analysis: [],
        loadingAnalysisList: false,
        loadingAnalysisListError: action.payload
      }

     default:
       return state;
   }
 };
