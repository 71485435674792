/**
 * Unsubscribe email
 */
import {
    UNSUBSCRIBE,
    UNSUBSCRIBE_ERROR,
    UNSUBSCRIBE_SUCCESS,
    UNSUBSCRIBE_CLEAR,
  } from '../types'
  // endpoint helpers and axios
  import { endpointForApi, headersForApi } from '../../api/swingbotApi';
  const axios = require('axios');
  /**
   * unsubscribe request
   */
  export const unsubscribe = (email) => {
    return(dispatch) => {
      dispatch({
        type: UNSUBSCRIBE,
        payload: null
      });
      var body = { email };
      return axios({
        method: 'put',
        data: body,
        url: endpointForApi('user', 'users/unsubscribe'),
        headers: headersForApi('user')
      })
        .then(result => dispatch(unsubscribeSuccess(result)))
        .catch(result => dispatch(unsubscribeFailure(result)));
      };
    }; // end activate
  
  const unsubscribeSuccess = (result) => {
    return (dispatch) => {
      return dispatch({
        type: UNSUBSCRIBE_SUCCESS,
        payload: result
      });
    }
  }
  
  const unsubscribeFailure = (result) => {
    return (dispatch) => {
      dispatch({
        type: UNSUBSCRIBE_ERROR,
        payload: result
      });
    }
  }
  
  export const unsubscribeClear = () => dispatch =>
    dispatch({ type: UNSUBSCRIBE_CLEAR });
  