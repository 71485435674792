import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { 
    getLiveBroadcastList, 
    getExistingBroadcastList,
    getUpcomingBroadcastList 
} from '../../actions';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
// import Breadcrumb from 'react-bootstrap/lib/Breadcrumb';
import Container from 'react-bootstrap/lib/Container';

import SwingbotHeader from '../common/navbar/SwingbotHeader';
import PageContainer from '../common/container/PageContainer';
import WebinarListItem from './WebinarListItem';

import './Webinar.css';

class WebinarList extends React.Component {
    componentDidMount = () => {
        this.props.getLiveBroadcastList();
        this.props.getUpcomingBroadcastList();
        this.props.getExistingBroadcastList();
    }

    renderWebinarItems = () => {
        return this.props.liveBroadcasts.items ?
            this.props.liveBroadcasts.items.map(item => {
                return (
                    <Col md={4} lg={3} sm={4} xs={6} className="webinarListItemCol">
                        <WebinarListItem
                            id={item.id}
                            title={item.snippet.title}
                            description={item.snippet.description}
                            thumbnail={item.snippet.thumbnails.medium.url}
                            startTime={item.snippet.scheduledStartTime}
                            endTime={item.snippet.scheduledEndTime}
                        />
                    </Col>
                )
            }) : (
                <div style={{ border: '1px solid #EEEEEE', marginTop: '20px', marginBottom: '20px', padding: '20px', borderRadius: '5px', textAlign: 'center' }}>
                    <h4>Currently no live sessions</h4>
                </div>
            );
    }

    renderUpcomingWebinarItems = () => {
        return this.props.upcomingBroadcasts.items ?
            this.props.upcomingBroadcasts.items.map(item => {
                return (
                    <Col md={4} lg={3} sm={4} xs={6} className="webinarListItemCol">
                        <WebinarListItem
                            id={item.id}
                            title={item.snippet.title}
                            description={item.snippet.description}
                            thumbnail={item.snippet.thumbnails.medium.url}
                            startTime={item.snippet.scheduledStartTime}
                            endTime={item.snippet.scheduledEndTime}
                        />
                    </Col>
                )
            }) : null;
    }

    renderWebinarPastItems = () => {
        return this.props.pastBroadcasts.items ?
            this.props.pastBroadcasts.items.map(item => {
                console.log('past ittem: ', item);
                return (
                    <Col md={4} lg={3} sm={4} xs={6} className="webinarListItemCol">
                        <WebinarListItem
                            id={item.id.videoId}
                            title={item.snippet.title}
                            description={item.snippet.description}
                            thumbnail={item.snippet.thumbnails.medium.url}
                            startTime={item.snippet.scheduledStartTime}
                            endTime={item.snippet.scheduledEndTime}
                        />
                    </Col>
                )
            }) : null;
    }

    render() {
        return (
            <div className="stitchDetailContainer">
                <PageContainer title="Live Analysis Webinars">
                <div className="pageContainer">
                    <SwingbotHeader />
                    <div className="stitchDetailBodyContainer">
                    <Container>
                        {/* <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/webinar" active>
                                Analysis Webinars
                            </Breadcrumb.Item>
                        </Breadcrumb> */}
                        <h3>Live Session</h3>
                        <Row>
                            {this.props.liveBroadcasts && this.renderWebinarItems()}
                        </Row>
                        <h3>Upcoming Sessions</h3>
                        <Row>
                            {this.props.upcomingBroadcasts && this.renderUpcomingWebinarItems()}
                        </Row>
                        <h3>Past Sessions</h3>
                        <Row>
                            {this.props.pastBroadcasts && this.renderWebinarPastItems()}
                        </Row>
                    </Container>
                    </div>
                </div>
                </PageContainer>
            </div>
        )
    }
}

const mapStateToProps = ({ liveBroadcastList }) => {
    const { 
        liveBroadcasts,
        loadingLiveBroadcastList,
        loadingLiveBroadcastListError,
        pastBroadcasts,
        loadingExistingBroadcastList,
        loadingExistingBroadcastListError,
        upcomingBroadcasts,
        loadingUpcomingBroadcastList,
        loadingUpcomingBroadcastListError
    } = liveBroadcastList;
    
    return {
        liveBroadcasts,
        loadingLiveBroadcastList,
        loadingLiveBroadcastListError,
        pastBroadcasts,
        loadingExistingBroadcastList,
        loadingExistingBroadcastListError,
        upcomingBroadcasts,
        loadingUpcomingBroadcastList,
        loadingUpcomingBroadcastListError
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getLiveBroadcastList,
    getExistingBroadcastList,
    getUpcomingBroadcastList
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WebinarList);