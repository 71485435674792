import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import PageContainer from '../common/container/PageContainer';
import SectionHeader from '../common/header/SectionHeader';
import SwingbotHeaderClear from '../common/navbar/SwingbotHeaderClear';

import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import AppBadges from '../common/AppBadges';

import { 
    subscribeToPlan, 
    getBraintreeClientToken, 
    createBraintreeCustomer,
    createBraintreeCustomerGetToken
} from '../../actions/Subscribe';
import { getUserByUserId } from '../../actions/User/UserDetailActions';

import styles from './Cart.css';

class CartThankyou extends React.Component {

    instance; 
    state = {
        sandbox: true,
        paymentNonce: null,
        planId: null,
        planSelected: 'monthly'
    }

    componentDidMount = () => {
        const {user_id} = this.props.authUser.data;
        // refresh the user data..
        this.props.getUserByUserId(user_id);
    }

    handleSelectPlan = (planType) => {
        this.setState({
            planSelected: planType
        });
    }

    render() {
        return (
            <PageContainer>
                <SwingbotHeaderClear variant="dark" />
                <SectionHeader title={'Thank you!'} />
                <Container className={styles.container}>
                    <Row>
                        <Col md={6}>
                            <h3>Let the journey begin.</h3>
                            <p>Congratulations, you have taken the first step to improving your golf swing using the future of swing analysis.</p>
                            <p>If you haven't already, please download either the iOS or Android App from the store.</p>
                            <p>As always, if you have any questions, comments, or suggestions, please contact me directly at support@swingbot.com.</p>
                        </Col>
                        <Col>
                            <AppBadges />              
                        </Col>
                    </Row>
                </Container>
            </PageContainer>
        );
    }
}

const mapStateToProps = ({ auth, subscribe }) => {
    const { authUser } = auth;
    const { 
        clientToken,
        createBraintreeCustomerSuccess 
    } = subscribe;
    return {
      authUser,
      subscribe,
      clientToken,
      createBraintreeCustomerSuccess
    };
  };

const mapDispatchToProps = dispatch => bindActionCreators({
    createBraintreeCustomer,
    createBraintreeCustomerGetToken,
    getBraintreeClientToken,
    subscribeToPlan,
    getUserByUserId
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CartThankyou);
