/**
 * Action Types
 *
 * The action types that are maintained as constants
 * so we dont mistype them throughout the application.
 */
// Auth Check Token
export const AUTH_CHECK_TOKEN = 'auth-check-token';
export const AUTH_CHECK_TOKEN_SUCCESS = 'auth-check-token-success';
export const AUTH_CHECK_TOKEN_ERROR = 'auth-check-token-error';
export const INVALID_TOKEN = 'invalid-token';
export const EXPIRED_TOKEN = 'expired-token';
// Refresh token
export const REFRESH_TOKEN = 'refresh-token';
export const REFRESH_TOKEN_SUCCESS = 'refresh-token-success';
export const REFRESH_TOKEN_ERROR = 'refresh-token-error';
// Google token
export const GOOGLE_VERIFY_TOKEN = 'google-verify-token';
export const GOOGLE_VERIFY_TOKEN_ERROR = 'google-verify-token-error';
export const GOOGLE_VERIFY_TOKEN_SUCCESS = 'google-verify-token-success';
export const GOOGLE_VERIFY_TOKEN_RESET = 'google-verify-token-reset';
// Login (get token)
export const LOGIN_TYPING = 'account/login-typing';
export const LOGIN_USER = 'account/login-user';
export const LOGIN_USER_SUCCESS = 'account/login-user-success';
export const LOGIN_USER_RESET = 'account/login-user-reset';
export const LOGIN_USER_ERROR = 'account/login-user-error';
export const LOGOUT_USER = 'account/logout-user';
// Register
export const REGISTER_USER = 'register-user';
export const REGISTER_USER_SUCCESS = 'register-user-success';
export const REGISTER_USER_ERROR = 'register-user-error';
export const REGISTER_USER_RESET = 'register-user-reset';
// Forgot Password
export const FORGOT_PASSWORD = 'forgot-password';
export const FORGOT_PASSWORD_SUCCESS = 'forgot-password-success';
export const FORGOT_PASSWORD_ERROR = 'forgot-password-error';
// Reset Password
export const RESET_PASSWORD = 'reset-password';
export const RESET_PASSWORD_SUCCESS = 'reset-password-success';
export const RESET_PASSWORD_ERROR = 'reset-password-error';
export const RESET_PASSWORD_CLEAR = 'reset-password-clear';
// unsubscribe
export const UNSUBSCRIBE = 'unsubscribe';
export const UNSUBSCRIBE_SUCCESS = 'unsubscribe-success';
export const UNSUBSCRIBE_ERROR = 'unsubscribe-error';
export const UNSUBSCRIBE_CLEAR = 'unsubscribe-clear';
// Activate
export const ACTIVATE_USER = 'activate-user';
export const ACTIVATE_USER_SUCCESS = 'activate-user-success';
export const ACTIVATE_USER_ERROR = 'activate-user-error';
// User Detail
export const GET_USER_DETAIL = 'get-user-detail';
export const GET_USER_DETAIL_SUCCESS = 'get-user-detail-success';
export const GET_USER_DETAIL_ERROR = 'get-user-detail-error';
// Programs
export const GET_PROGRAM_LIST = 'get-program-list';
export const GET_PROGRAM_LIST_SUCCESS = 'get-program-list-success';
export const GET_PROGRAM_LIST_ERROR = 'get-program-list-error';
// Program Detail
export const GET_PROGRAM_DETAIL = 'get-program-detail';
export const GET_PROGRAM_DETAIL_SUCCESS = 'get-program-detail-success';
export const GET_PROGRAM_DETAIL_ERROR = 'get-program-detail-error';
// Videos
export const GET_VIDEO_LIST = 'get-video-list';
export const GET_VIDEO_LIST_SUCCESS = 'get-video-list-success';
export const GET_VIDEO_LIST_ERROR = 'get-video-list-error';
// Video Detail
export const GET_VIDEO_DETAIL = 'get-video-detail';
export const GET_VIDEO_DETAIL_SUCCESS = 'get-video-detail-success';
export const GET_VIDEO_DETAIL_ERROR = 'get-video-detail-error';
// Analysis
export const GET_ANALYSIS_LIST = 'get-analysis-list';
export const GET_ANALYSIS_LIST_SUCCESS = 'get-analysis-list-success';
export const GET_ANALYSIS_LIST_ERROR = 'get-analysis-list-error';
// Analysis Detail
export const GET_ANALYSIS_DETAIL = 'get-analysis-detail';
export const GET_ANALYSIS_DETAIL_SUCCESS = 'get-analysis-detail-success';
export const GET_ANALYSIS_DETAIL_ERROR = 'get-analysis-detail-error';
// Analyze a Video
export const ANALYZE_VIDEO_DETAIL = 'analysis/analyze-video-detail'
export const ANALYZE_VIDEO_DETAIL_ERROR = 'analysis/analyze-video-detail-error'
export const ANALYZE_VIDEO_DETAIL_SUCCESS = 'analysis/analyze-video-detail-success'
// Analysis Progress
export const GET_ANALYSIS_HISTORY_LIST = 'progress/analysis-result-history';
export const GET_ANALYSIS_HISTORY_LIST_ERROR = 'progress/analysis-result-history-error';
export const GET_ANALYSIS_HISTORY_LIST_SUCCESS = 'progress/analysis-result-history-success';
// get the available stitch videos
export const GET_STITCH_AVAILABLE_LIST = 'stitch/get-stitch-available-list';
export const GET_STITCH_AVAILABLE_LIST_SUCCESS = 'stitch/get-stitch-available-list-success';
export const GET_STITCH_AVAILABLE_LIST_ERROR = 'stitch/get-stitch-available-list-error';
// Stitch Activate Video
export const STITCH_ACTIVATE_VIDEO = 'stitch/stitch-activate-video';
export const STITCH_ACTIVATE_VIDEO_SUCCESS = 'stitch/stitch-activate-video-success';
export const STITCH_ACTIVATE_VIDEO_ERROR = 'stitch/stitch-activate-video-error';
export const STITCH_ACTIVATE_VIDEO_RESET = 'stitch/stitch-activate-video-reset';
// Stitch Deactivate Video
export const STITCH_DEACTIVATE_VIDEO = 'stitch/stitch-deactivate-video';
export const STITCH_DEACTIVATE_VIDEO_SUCCESS = 'stitch/stitch-deactivate-video-success';
export const STITCH_DEACTIVATE_VIDEO_ERROR = 'stitch/stitch-deactivate-video-error';
export const STITCH_DEACTIVATE_VIDEO_RESET = 'stitch/stitch-deactivate-video-reset';
// Stitch Details
export const GET_STITCH_DETAIL = 'stitch/stitch-detail';
export const GET_STITCH_DETAIL_SUCCESS = 'stitch/stitch-detail-success';
export const GET_STITCH_DETAIL_ERROR = 'stitch/stitch-detail-error';
// Upload video get signed url
export const GET_UPLOAD_URL = 'get-upload-url';
export const GET_UPLOAD_URL_SUCCESS = 'get-upload-url-success';
export const GET_UPLOAD_URL_ERROR = 'get-upload-url-error';
// selected stitch video
export const SELECTED_STITCH_VIDEO = 'selected-stitch-video';
// Webinar list (Live Broadcasts)
export const GET_LIVE_BROADCAST_LIST = 'get-live-broadcast-list';
export const GET_LIVE_BROADCAST_LIST_SUCCESS = 'get-live-broadcast-list-success';
export const GET_LIVE_BROADCAST_LIST_ERROR = 'get-live-broadcast-list-error';
// upcoming
export const GET_UPCOMING_BROADCAST_LIST = 'get-upcoming-broadcast-list';
export const GET_UPCOMING_BROADCAST_LIST_ERROR = 'get-upcoming-broadcast-list-error';
export const GET_UPCOMING_BROADCAST_LIST_SUCCESS = 'get-upcoming-broadcast-list-success';
// existing videos
export const GET_EXISTING_BROADCAST_LIST = 'get-existing-broadcast-list';
export const GET_EXISTING_BROADCAST_LIST_ERROR = 'get-existing-broadcast-list-error';
export const GET_EXISTING_BROADCAST_LIST_SUCCESS = 'get-existing-broadcast-list-success';
// Feedback
export const FEEDBACK_GENERAL = 'feedback-general';
export const FEEDBACK_GENERAL_ERROR = 'feedback-general-error';
export const FEEDBACK_GENERAL_SUCCESS = 'feedback-general-success';
export const FEEDBACK_GENERAL_RESET = 'feedback-general-success-reset';
export const FEEDBACK_ANALYSIS = 'feedback-analysis';
export const FEEDBACK_ANALYSIS_ERROR = 'feedback-analysis-error';
export const FEEDBACK_ANALYSIS_SUCCESS = 'feedback-analysis-success';
export const FEEDBACK_ANALYSIS_RESET = 'feedback-analysis-success-reset';