import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { stitchSelectors, userSelectors } from '../../selectors';
import PageContainer from '../common/container/PageContainer'
import Loading from '../common/Loading';
import SectionHeader from '../common/header/SectionHeader';
import './StitchList.css';
import {
  getStitchVideosAvailableByUserId,
  selectStitchVideo,
} from '../../actions'
import UploadSwing from '../common/UploadSwing';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Image from 'react-bootstrap/lib/Image';
import Container from 'react-bootstrap/lib/Container';

import StackGrid from 'react-stack-grid';
import styles from './StitchList.css';
import StitchListItem from './StitchListItem';
import SwingbotHeaderClear from '../common/navbar/SwingbotHeaderClear';

/**
 * class StitchList
 */
class StitchList extends Component {

  state = {
    showPlayModal: false,
    videoData: null,
    filterLexiconName: null,
    filterProbability: null,
    filterScore: null,
    totalCount: 0,
    filteredResults: null
  }

  updateDimensions = () => {
    var w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
      height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
      this.setState({ width: width, height: height});
  }
  componentDidMount = () => {
    this.props.getStitchVideosAvailableByUserId(this.props.authUser.user_id);
  }
  componentWillMount = () => {
    this.updateDimensions();
  }
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  }
  componentWillReceiveProps = (nextProps) => {
    // console.log(nextProps);
    // if (nextProps.authUser !== this.props.authUser) {
      // if ('user_id' in nextProps.authUser) {
        // this.props.getStitchVideosAvailableByUserId(this.props.authUser.user_id);
      // }
    // }
  }
  componentDidMount = () => {
    // event listener for resizing the window...
    window.addEventListener("resize", this.updateDimensions);
    const { authUser } = this.props;
    if (authUser) {
      if ('user_id' in authUser.data) {
        const userId = authUser.data.user_id;
        this.props.getStitchVideosAvailableByUserId(userId);
      }
    }
  }

  filterResultSet = (results) => {
    let filtered = Object.assign(results,{});
    if (this.state.filterProbability !== null) {
      filtered = this.filterResultsWithProbability(filtered);
    }
    if (this.state.filterScore !== null) {
      filtered = this.filterResultsWithScore(filtered);
    }
    return filtered;
  }

  filterResultsWithProbability = (results) => {
    return results.filter(record => {
      return (
        record.probability !== null &&
        (parseInt(record.probability, 10) > parseInt(this.state.filterProbability, 10))
      );
    });
  }

  filterResultsWithScore = (results) => {
    return results.filter(record => {
      return (
        record.percentScore !== null &&
        (parseInt(record.percentScore, 10) >
        parseInt(this.state.filterScore, 10))
      );
    });
  }

  renderAvailableStitchVideos = () => {
    const { isSubscribed } = this.props;
    const filtered = this.state.filteredResults !== null 
      ? this.state.filteredResults 
      : this.props.stitchAvailable;
    return filtered.map((rec, index) => {
      // determine if we should disable the instructional video
      const disabled = isSubscribed === true ? false : index > 0;
      return (
        <StitchListItem
          key={rec.thumbnail}
          thumbnail={rec.thumbnail}
          data={rec}
          onClick={this.handleVideoClick}
          onDisabledClick={this.handleDisabledVideoClick}
          disabled={disabled}
        />
      );
    });
  }

  handleProbabilityChange = (value) => {
    if (this.props.stitchAvailable !== null) {
      const filtered = this.filterResultSet(this.props.stitchAvailable);
      this.setState({
        filterProbability: value,
        filteredResults: filtered,
        totalCount: filtered.length
      });
    }
  }

  handleScoreChange = (value) => {
    if (this.props.stitchAvailable !== null) {
      const filtered = this.filterResultSet(this.props.stitchAvailable);
      this.setState({
        filterScore: value,
        filteredResults: filtered,
        totalCount: filtered.length
      });
    }
  }
  /**
   * navigate to the stitch detail screen
   */
  handleVideoClick = (data) => {
    this.props.selectStitchVideo(data);
    this.props.history.push({
      pathname: '/instruction/' + data.gm_stitch_id,
      state: { stitchData: data, stitchId: data.gm_stitch_id }
    });
  }

  handleDisabledVideoClick = (data) => {
    this.props.history.push({
      pathname: '/cart',
    });
  }

  handleCloseModal = () => {
    this.setState({
      showPlayModal: false,
      videoData: null
    })
  }

  onStackLayout = (event) => {}

  renderSubscribedLayout = () => {
    let columns = 4;
    if (this.state.width > 0 &  this.state.width < 768) {
      columns = 2;
    } else if (this.state.width >= 768 & this.state.width < 1024) {
      columns = 4;
    } else {
      columns = 5;
    }
    const stackItemWidth = this.state.width / columns;
    return(
      <Container>
        <Row>
          <Col md={4} lg={3} onLayout={this.onStackLayout}>
            {/* If we have fetched the videos and we have some! */}
            {this.props.stitchAvailable !== null && this.props.stitchAvailable.length > 0 &&
              <StackGrid
                columnWidth={stackItemWidth}
                monitorImagesLoaded={true}
              >
                {this.renderAvailableStitchVideos()}
              </StackGrid>
            }
            {/* loading... */}
            {this.props.stitchAvailable === null && <Loading title={'Instructional Videos'} />}
            {/* if no stitch videos, lets show the user how to upload */}
            {this.props.stitchAvailable !== null && this.props.stitchAvailable.length < 1 &&
              <UploadSwing />
            }
          </Col>
        </Row>
      </Container>
    )
  }

  renderUnsubscribedLayout = () => {
    return (
      <div>
        <Container>
          <Row>
            <Col md={12}>
            <div>
              {/* <h1 className="jumbo">Intelligent Golf Instruction</h1> */}
              <h2>Get a golf lesson anytime, anywhere, in minutes.</h2>
              <p>Become a Swingbot member using the app.</p>
              <div className={styles.badgeContainer}>
                <a href="https://itunes.apple.com/us/app/swingbot-golf-swing-video/id516200328?mt=8" target="_blank">
                  <Image
                    className={styles.appStoreBadge}
                    src={require('../../assets/images/app-store-logo.png')}
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.databake.swingbot" target="_blank">
                  <Image
                    className={styles.appStoreBadge}
                    src={require('../../assets/images/google_web.png')}
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <p></p>
          </Col>
        </Row>
        </Container>
      </div>
    );
  }
  /**
   * render
   */
  render() {
    const { stitchAvailable, isSubscribed } = this.props;
    return (
      <div className="mainStitchContainer">
        <PageContainer>
          <div className="pageContainer">
            <SwingbotHeaderClear variant="dark" />
            <SectionHeader 
              title={'Instructional Videos'} 
              description={'All of the instructional videos Swingbot has created just for you.'}
            />
            { stitchAvailable && (stitchAvailable.length > 0) &&
              <Container className={styles.container}>
                <Row>
                  {this.renderAvailableStitchVideos()}
                </Row>
              </Container>
            }
            { stitchAvailable && (stitchAvailable.length === 0) &&
              <Container className={styles.container}>
                <Row>
                  <UploadSwing />
                </Row>
              </Container>
            }
            {stitchAvailable === null && <Loading title={'Instructional Videos'} />}
            {/* {subscribed && this.renderSubscribedLayout()} */}
            {/* {subscribed === true && this.renderUnsubscribedLayout()} */}
          </div>
        </PageContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { token, authUser } = state.auth;
  return {
    token,
    authUser,
    stitchAvailable: stitchSelectors.getStitchVideosAvailable(state),
    isSubscribed: userSelectors.isSubscribed(state)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getStitchVideosAvailableByUserId,
  selectStitchVideo
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StitchList);
