/**
 * Connection Reducer
 */
import * as actionTypes from '../../actions/Connection/types'
  
  // set the initial state of the reducer
   const INITIAL_STATE = {
        getInvitationDataLoading: false,
        getInvitationDataError: null,
        invitationData: null,
        getConnectionsForUserLoading: false,
        getConnectionsForUserError: null,
        connectionsForUser: null,
        denyInvitationLoading: false,
        denyInvitationError: null,
        denyInvitationSuccess: null,
        acceptInvitationLoading: false,
        acceptInvitationError: null,
        acceptInvitationSuccess: null,
   };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {

        case actionTypes.GET_INVITATION_DATA:
            return {
                ...state,
                getInvitationDataLoading: true,
                getInvitationDataError: null,
                invitationData: null,
            }
  
        case actionTypes.GET_INVITATION_DATA_SUCCESS:
            return {
                ...state,
                getInvitationDataLoading: false,
                getInvitationDataError: null,
                invitationData: action.payload.data.data,
            }
  
        case actionTypes.GET_INVITATION_DATA_ERROR:
            return {
                ...state,
                getInvitationDataLoading: false,
                getInvitationDataError: action.payload,
                invitationData: null,
            }

        case actionTypes.GET_INVITATION_DATA_RESET:
            return {
                ...state,
                getInvitationDataLoading: false,
                getInvitationDataError: null,
                invitationData: null,
            }
        
        case actionTypes.GET_CONNECTIONS_FOR_USER:
            return {
                ...state,
                getConnectionsForUserLoading: true,
                getConnectionsForUserError: null,
                connectionsForUser: null,
            }
  
        case actionTypes.GET_CONNECTIONS_FOR_USER_SUCCESS:
            return {
                ...state,
                getConnectionsForUserLoading: false,
                getConnectionsForUserError: null,
                connectionsForUser: action.payload,
            }
  
        case actionTypes.GET_CONNECTIONS_FOR_USER_ERROR:
            return {
                ...state,
                getConnectionsForUserLoading: false,
                getConnectionsForUserError: action.payload,
                connectionsForUser: null,
            }

        case actionTypes.GET_CONNECTIONS_FOR_USER_RESET:
            return {
                ...state,
                getConnectionsForUserLoading: false,
                getConnectionsForUserError: null,
                connectionsForUser: null,
            }

        case actionTypes.ACCEPT_INVITATION:
            return {
                ...state,
                acceptInvitationLoading: true,
                acceptInvitationError: null,
                acceptInvitationSuccess: null,
            }
  
        case actionTypes.ACCEPT_INVITATION_SUCCESS:
            return {
                ...state,
                acceptInvitationLoading: false,
                acceptInvitationError: null,
                acceptInvitationSuccess: action.payload.data.data,
            }
  
        case actionTypes.ACCEPT_INVITATION_ERROR:
            return {
                ...state,
                acceptInvitationLoading: false,
                acceptInvitationError: action.payload,
                acceptInvitationSuccess: null,
            }

        case actionTypes.ACCEPT_INVITATION_RESET:
            return {
                ...state,
                acceptInvitationLoading: false,
                acceptInvitationError: null,
                acceptInvitationSuccess: null,
            }

        case actionTypes.DENY_INVITATION:
            return {
                ...state,
                denyInvitationLoading: true,
                denyInvitationError: null,
                denyInvitationSuccess: null,
            }
  
        case actionTypes.DENY_INVITATION_SUCCESS:
            return {
                ...state,
                denyInvitationLoading: false,
                denyInvitationError: null,
                denyInvitationSuccess: action.payload.data.data,
            }
  
        case actionTypes.DENY_INVITATION_ERROR:
            return {
                ...state,
                denyInvitationLoading: false,
                denyInvitationError: action.payload,
                denyInvitationSuccess: null,
            }

        case actionTypes.DENY_INVITATION_RESET:
            return {
                ...state,
                denyInvitationLoading: false,
                denyInvitationError: null,
                denyInvitationSuccess: null,
            }
       default:
         return state;
     }
   };
  