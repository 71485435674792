/**
 * StitchAvailableListReducer
 *
 * Fetch the list of stitchAvailableVideos from the database
 */
import {
  GET_STITCH_AVAILABLE_LIST,
  GET_STITCH_AVAILABLE_LIST_ERROR,
  GET_STITCH_AVAILABLE_LIST_SUCCESS,
  SELECTED_STITCH_VIDEO
} from '../../actions/types'

// set the initial state of the reducer
 const INITIAL_STATE = {
   stitchAvailable: null,
   totalStitchAvailable: null,
   loadingStitchAvailableList: null,
   loadingStitchAvailableListError: null,
   selectedStitchVideo: null,
 };

 export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**
     * when the user selects a stitch video
     */
    case SELECTED_STITCH_VIDEO:
      return {
        ...state,
        selectedStitchVideo: action.payload
      }
    break;

     case GET_STITCH_AVAILABLE_LIST:
       return {
         ...state,
         stitchAvailable: null,
         loadingStitchAvailableList: true,
         loadingStitchAvailableListError: null
       }
     break;

     case GET_STITCH_AVAILABLE_LIST_SUCCESS:
      return {
        ...state,
        totalStitchAvailable: action.payload.count,
        stitchAvailable: action.payload.records,
        loadingStitchAvailableList: null,
        loadingStitchAvailableListError: null
      }
     break;

     case GET_STITCH_AVAILABLE_LIST_ERROR:
      return {
        ...state,
        stitchAvailable: null,
        loadingStitchAvailableList: null,
        loadingStitchAvailableListError: action.payload
      }
     break;

     default:
       return state;
   }
 };
