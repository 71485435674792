import * as actionTypes from './types';

/**
 * login user success
 * @param {object} result 
 */
export const loginUserSuccess = (result) => ({
    type: actionTypes.LOGIN_USER_SUCCESS,
    payload: result
});

/**
 * Register a new user in the system
 * 
 * @param {string} email 
 * @param {string} emailConfirm 
 * @param {string} password 
 * @param {string} firstName 
 * @param {string} lastName 
 * @param {mixed} redirect boolean false or the redirect object
 */
export const registerUser = (email, emailConfirm, password, firstName, lastName, redirect) => ({
    type: actionTypes.REGISTER_USER,
    payload: { email, emailConfirm, password, firstName, lastName, redirect }
});

export const registerUserError = (error) => ({
    type: actionTypes.REGISTER_USER_ERROR,
    payload: error
});

export const registerUserSuccess = (result) => ({
    type: actionTypes.REGISTER_USER_SUCCESS,
    payload: result
});

export const registerUserReset = () => ({
    type: actionTypes.REGISTER_USER_RESET,
    payload: null
});

/**
 * completeRegistration
 * 
 * When a new connection is created the user 
 * can "complete" their registration by choosing a password.
 * 
 * @param {string} firstName 
 * @param {string} lastName 
 * @param {string} password 
 * @param {string} email 
 */
export const completeRegistration = (firstName, lastName, password, email) => ({
    type: actionTypes.COMPLETE_REGISTRATION,
    payload: {firstName, lastName, password, email}
});

export const completeRegistrationError = (error) => ({
    type: actionTypes.COMPLETE_REGISTRATION_ERROR,
    payload: error
});

export const completeRegistrationSuccess = (result) => ({
    type: actionTypes.COMPLETE_REGISTRATION_SUCCESS,
    payload: result
});

export const completeRegistrationReset = () => ({
    type: actionTypes.COMPLETE_REGISTRATION_RESET,
    payload: null
});
/**
 * Choose Password
 * 
 * If a user was created by email there is no "password"
 * set for the user. Also, the status of the user is "pending" (3)
 * 
 * This choose password service will set the status to "active" (2)
 * and set the password that the user has chosen.
 */
export const choosePassword = (email, password, code) => ({
    type: actionTypes.CHOOSE_PASSWORD,
    payload: {email, password, code}
});

export const choosePasswordError = (error) => ({
    type: actionTypes.CHOOSE_PASSWORD_ERROR,
    payload: error
});

export const choosePasswordSuccess = (result) => ({
    type: actionTypes.CHOOSE_PASSWORD_SUCCESS,
    payload: result
});

export const choosePasswordReset = () => ({
    type: actionTypes.CHOOSE_PASSWORD_RESET,
    payload: null
});