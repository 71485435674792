/**
 * Google Verify Token
 *
 * Verify the login token from google WITH google
 */
import {
  GOOGLE_VERIFY_TOKEN,
  GOOGLE_VERIFY_TOKEN_ERROR,
  GOOGLE_VERIFY_TOKEN_SUCCESS,
  GOOGLE_VERIFY_TOKEN_RESET
} from '../actions/types'
// set the initial state of the reducer
 const INITIAL_STATE = {
   googleVerifyTokenData: null,
   loadingGoogleVerifyToken: false,
   loadingGoogleVerifyTokenError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     /**
      * verify google token user
      */
     case GOOGLE_VERIFY_TOKEN:
       return {
         ...state,
         googleVerifyTokenData: null,
         loadingGoogleVerifyToken: true,
         loadingGoogleVerifyTokenError: null
       }

     case GOOGLE_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        googleVerifyTokenData: action.payload.data,
        loadingGoogleVerifyToken: false,
        loadingGoogleVerifyTokenError: false,
        token: action.payload.token
      }

     case GOOGLE_VERIFY_TOKEN_ERROR:
      return {
        ...state,
        googleVerifyTokenData: null,
        loadingGoogleVerifyToken: false,
        loadingGoogleVerifyTokenError: action.payload
      }

    case GOOGLE_VERIFY_TOKEN_RESET:
      return {
        googleVerifyTokenData: null,
        loadingGoogleVerifyToken: false,
        loadingGoogleVerifyTokenError: null
      }

     default:
       return state;
   }
 };
