import * as actionTypes from './types';
/**
 * Get the connections
 * @param {object} data 
 */
export const getConnectionsForUser = (userId) => ({
    type: actionTypes.GET_CONNECTIONS_FOR_USER,
    payload: userId
});
export const getConnectionsForUserSuccess = (data) => ({
    type: actionTypes.GET_CONNECTIONS_FOR_USER_SUCCESS,
    payload: data
});
export const getConnectionsForUserError = (error) => ({
    type: actionTypes.GET_CONNECTIONS_FOR_USER_ERROR,
    payload: error
});
/**
 * Get the invitation
 * @param {object} data 
 */
export const getInvitationByNonce = (data) => ({
    type: actionTypes.GET_INVITATION_DATA,
    payload: data
});
export const getInvitationByNonceSuccess = (data) => ({
    type: actionTypes.GET_INVITATION_DATA_SUCCESS,
    payload: data
});
export const getInvitationByNonceError = (error) => ({
    type: actionTypes.GET_INVITATION_DATA_ERROR,
    payload: error
});
export const getInvitationByNonceReset = (error) => ({
    type: actionTypes.GET_INVITATION_DATA_RESET,
    payload: error
});
/**
 * Accept Invitation
 * @param {object} data 
 */
export const acceptInvitationRequest = (userId, data) => ({
    type: actionTypes.ACCEPT_INVITATION,
    payload: data
});
export const acceptInvitationRequestSuccess = (data) => ({
    type: actionTypes.ACCEPT_INVITATION_SUCCESS,
    payload: data
});
export const acceptInvitationRequestError = (error) => ({
    type: actionTypes.ACCEPT_INVITATION_ERROR,
    payload: error
});
export const acceptInvitationRequestReset = (error) => ({
    type: actionTypes.ACCEPT_INVITATION_RESET,
    payload: error
});
/**
 * Deny Invitation
 * @param {object} data 
 */
export const denyInvitationRequest = (userId, data) => ({
    type: actionTypes.DENY_INVITATION,
    payload: data
});
export const denyInvitationRequestSuccess = (data) => ({
    type: actionTypes.DENY_INVITATION_SUCCESS,
    payload: data
});
export const denyInvitationRequestError = (error) => ({
    type: actionTypes.DENY_INVITATION_ERROR,
    payload: error
});
export const denyInvitationRequestReset = (error) => ({
    type: actionTypes.DENY_INVITATION_RESET,
    payload: error
});