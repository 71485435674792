/**
 * Services
 */
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');

/**
 * Register a new user
 * 
 * @param {string} email 
 * @param {string} emailConfirm 
 * @param {string} password 
 * @param {string} firstName 
 * @param {string} lastName 
 * @param {mixed} redirect 
 */
export function registerUser(email, emailConfirm, password, firstName, lastName, redirect) {
    var body = {
        email: email,
        emailConfirm: emailConfirm,
        password: password,
        firstName: firstName,
        lastName: lastName
      };
    return axios({
        method: 'post',
        data: body,
        url: endpointForApi('www', 'account/register'),
        headers: headersForApi('www')
    });
}
/**
 * Complete a User Registration based on a Connection Request
 * 
 * @param {string} email 
 * @param {string} password 
 */
export function completeRegistration(nonce, password) {
    return axios({
        method: 'post',
        data: { nonce, password },
        url: endpointForApi('user', 'account/register/complete'),
        headers: headersForApi('user')
    });
}
/**
 * Choose Password (initial)
 * 
 * @param {string} email the email of the user
 * @param {string} password the new password for the user
 * @param {string} tempPassword the temporary password code
 * 
 * We are sending the new password for the user and the tempPassword
 * that was generated upon the creation of the user as a "nonce"
 * to identify
 */
export function choosePassword(email, password, tempPassword) {
    return axios({
        method: 'post',
        data: { email, password, tempPassword },
        url: endpointForApi('user', 'account/choose-password'),
        headers: headersForApi('user')
    });
}