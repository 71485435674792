import React from 'react';
import Image from 'react-bootstrap/lib/Image';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Badge from 'react-bootstrap/lib/Badge';
import Container from 'react-bootstrap/lib/Container';
import {
  convertToDisplayName,
  randomString,
  isLink,
  convertToLink,
  isPassing
} from '../../util/strings';
import styles from './AnalysisResultPanel.css';
/**
 * class AnalysisResultsPanel
 */
class AnalysisResultPanel extends React.Component {
  handleEdit = () => {
    this.props.onEdit(this.props.data);
  };

  handleDelete = () => {
    this.props.onDelete(this.props.data);
  };
  /**
   * request the stitch video
   */
  handleRequestStitch = () => {
    console.log('requesting stitch', this.props.data);
  };
  /**
   * handle adding this video and code to the qa table
   */

  /**
   * renderTable by looping through the keys
   */
  renderTable() {
    const { data, panelStyle, panelHeader } = this.props;
    const random = randomString(30);
    const passedAnalysis = isPassing(this.props.data.code);
    const probPass = this.props.data.probability_pass === true ? 'PASS' : 'FAIL';
    const percentProbability = this.props.data.probability_low;
    return (
        <Container>
            <Row style={{ marginBottom: 30 }}>
                <Col
                    md={6}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        backgroundColor: 'white'
                    }}
                >
                <Image 
                  onError={() => console.log('error image')}
                  src={this.props.data.image} 
                  fluid
                  rounded
                />
                </Col>
                <Col 
                    md={6}
                    style={{ padding: 20 }}
                >
                <h2>{panelHeader}</h2>
                {/* <h3><Badge>{passedAnalysis === true ? 'Passed' : 'Needs Improvement'}</Badge></h3> */}
                <p className={styles.analysisSummary}>{data.def}</p>
                <p className={styles.analysisSummary}>{data.msg}</p>
                </Col>
            </Row>
        </Container>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default AnalysisResultPanel;
