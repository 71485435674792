import React from 'react'
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageContainer from '../common/container/PageContainer'
import { getVideosByUserId } from '../../actions'
import Loading from '../common/Loading'
import AddToCalendar from '../common/AddToCalendar';
import CountdownTimer from '../common/CountdownTimer';

import Row from 'react-bootstrap/lib/Row';
import Container from 'react-bootstrap/lib/Container';
// import Breadcrumb from 'react-bootstrap/lib/Breadcrumb';
import Col from 'react-bootstrap/lib/Col';

import SwingbotHeader from '../common/navbar/SwingbotHeader';
import './Webinar.css';
/**
 * class Webinar
 */
class Webinar extends React.Component {
    state = {
        width: 0,
        height: 0
    }
    componentDidMount = () => {
        // lets fetch the videos
        // this.props.getVideosByUserId(1);
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        let width, height;
        //if (this.props.isLive === true) {
            height = this.divElement.clientHeight;
            width = this.divElement.clientWidth;
        // } else {
        //     height = this.divContainerElement.clientHeight;
        //     width = this.divContainerElement.clientWidth;
        // }
        console.log(width, height);
        this.setState({ height, width });
    }
    /**
     * render the calendar buttons
     */
    renderCalendarButtons = () => {

    }
    /**
     * render
     */
    render() {
        const ratio = 315 / 560;
        const pageWidth = this.props.isLive === true 
            ? (this.state.width) 
            : this.state.width * .75;
        const videoHeight = pageWidth * ratio;
        const width = pageWidth;

        const videoId = this.props.match.params.id;
        const videoUrl = `https://www.youtube.com/embed/${videoId}`;

        return (
            <div 
                className="stitchDetailContainer"
            >
                <PageContainer>
                <div className="pageContainer">
                    <SwingbotHeader />
                    <div className="stitchDetailBodyContainer">
                    <Container>
                        {/* <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/webinar">
                                Analysis Webinars
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{'TheSwingbot Live Stream'}</Breadcrumb.Item>
                        </Breadcrumb> */}
                        <Row>
                            <Col 
                                md={this.props.isLive === true ? 9 : 12} 
                                style={{ 
                                    flexDirection: 'row', 
                                    backgroundColor: 'black', 
                                    justifyContent: 'center', 
                                    alignItems: 'center' 
                                }}
                            >
                                <div 
                                    style={{ width: '100%' }}
                                    ref={(divElement) => this.divElement = divElement }
                                >
                                    <div style={{
                                        maxWidth: width, /* you have to have a size or this method doesn't work */
                                        maxHeight: videoHeight,
                                        marginRight: 'auto',
                                        marginLeft: 'auto',
                                        flexDirection: 'row',
                                    }}>
                                    <iframe
                                        title="TheSwingbot"
                                        width={'100%'}
                                        height={videoHeight}
                                        src={`${videoUrl}?autoplay=0&rel=0&showinfo=0`}
                                        frameborder="0" 
                                        allow="autoplay; encrypted-media" 
                                        allowfullscreen>
                                    </iframe>
                                    </div>
                                </div>
                            </Col>
                            {this.props.isLive &&
                                <Col md={this.props.isLive ? 3 : 0}>
                                    <iframe
                                        title="live-chat"
                                        height={videoHeight}
                                        width="300"
                                        src={`https://www.youtube.com/live_chat?v${videoId}&embed_domain=${this.props.domain}`}
                                        frameborder="0"
                                    >
                                    </iframe>
                                </Col>
                            }
                        </Row>
                        <Row style={{ backgroundColor: 'red', height: 60 }}>
                            <Col md={12}>
                                {this.props.authUser.data.is_subscribed ? 'true' : 'false' }
                                {/* <AddToCalendar 
                                    event={{
                                        title: 'Sample Event',
                                        description: 'This is the sample event provided as an example only',
                                        location: 'Portland, OR',
                                        startTime: '2016-09-16T20:15:00-04:00',
                                        endTime: '2016-09-16T21:45:00-04:00'
                                    }}
                                /> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                </PageContainer>
            </div>
        )
    }
}

Webinar.propTypes = {
    id: PropTypes.string,
    domain: PropTypes.string,
    isLive: PropTypes.bool,
};

Webinar.defaultProps = {
    id: null,
    isLive: false,
    domain: 'localhost',
};

const mapStateToProps = ({ videoList, auth }) => {
  const { videos, loadingVideoList } = videoList;
  const { authUser } = auth;
  console.log(authUser);
  return { videos, loadingVideoList, authUser };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getVideosByUserId,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Webinar);
