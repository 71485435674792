exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".VideoList__container--2cK9X {\n    padding: 20px;\n}\n\n.VideoList__containerVideo--X77AW {\n    border-radius: 5px;\n    background-color: #FFFFFF;\n    padding: 10px;\n    margin-top: 10px;\n    border: 1px solid #EEEEEE;\n    overflow: visible;\n  }\n\na.VideoList__videoLink--1e8pk:hover {\n    cursor: pointer;\n}\n\ndiv.VideoList__videoLink--1e8pk:hover {\n    cursor: pointer;\n}\n\np.VideoList__subheading--3fusC {\n    margin-bottom: 10px;\n}\n\n.VideoList__mainVideoContainer--28z3D {\n    margin-top: 20px;\n}\n\n/* .videoLink {\n    max-height: 320px;\n    flex: 0;\n    display: flex;\n    justify-content: center;\n    overflow: hidden;\n    margin-bottom: 10px;\n    text-align: center;\n} */", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/Videos/VideoList.css"],"names":[],"mappings":"AAAA;IACI,cAAc;CACjB;;AAED;IACI,mBAAmB;IACnB,0BAA0B;IAC1B,cAAc;IACd,iBAAiB;IACjB,0BAA0B;IAC1B,kBAAkB;GACnB;;AAEH;IACI,gBAAgB;CACnB;;AAED;IACI,gBAAgB;CACnB;;AAED;IACI,oBAAoB;CACvB;;AAED;IACI,iBAAiB;CACpB;;AAED;;;;;;;;IAQI","file":"VideoList.css","sourcesContent":[".container {\n    padding: 20px;\n}\n\n.containerVideo {\n    border-radius: 5px;\n    background-color: #FFFFFF;\n    padding: 10px;\n    margin-top: 10px;\n    border: 1px solid #EEEEEE;\n    overflow: visible;\n  }\n\na.videoLink:hover {\n    cursor: pointer;\n}\n\ndiv.videoLink:hover {\n    cursor: pointer;\n}\n\np.subheading {\n    margin-bottom: 10px;\n}\n\n.mainVideoContainer {\n    margin-top: 20px;\n}\n\n/* .videoLink {\n    max-height: 320px;\n    flex: 0;\n    display: flex;\n    justify-content: center;\n    overflow: hidden;\n    margin-bottom: 10px;\n    text-align: center;\n} */"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "VideoList__container--2cK9X",
	"containerVideo": "VideoList__containerVideo--X77AW",
	"videoLink": "VideoList__videoLink--1e8pk",
	"subheading": "VideoList__subheading--3fusC",
	"mainVideoContainer": "VideoList__mainVideoContainer--28z3D"
};