import React from 'react'
import Section from './container/Section';
import { PulseLoader } from 'halogenium';
import styles from './Loading.css';
class Loading extends React.Component {
  render() {
    return(
      <Section containerStyle={{ height: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <h1>Loading {this.props.title}</h1>
          <PulseLoader color="green" size="16px" margin="4px" />
        </div>
      </Section>
    )
  }
}

Loading.defaultProps = {
  title: ''
}
export default Loading;
