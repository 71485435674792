import React from 'react';
import Section from '../common/container/Section';
import { colors } from '../../styles';
import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import ReactPlayer from 'react-player';
import styles from './SectionVideoPlayer.css';

const SectionVideoPlayer = (props) => {
console.log(props);
    return (
        <Section
            name="trial"
            className={styles.containerFreeTrial}
            style={{
                backgroundColor: 'transparent',
                color: colors.white,
                textAlign: 'center',
                height: props.containerStyle.height
            }}
        >
            <Container>
                <Row>
                    <Col>
                        <ReactPlayer 
                            url={props.video.zen_mp4}
                            controls
                        />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

SectionVideoPlayer.defaultProps = {
    containerStyle: {
        height: undefined
    }
}

export default SectionVideoPlayer;
