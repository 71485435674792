import * as actionTypes from './types';

export const createBraintreeCustomer = (userId, firstName, lastName, email, sandbox) => ({
    type: actionTypes.CREATE_BRAINTREE_CUSTOMER,
    payload: { userId, firstName, lastName, email, sandbox }
});

export const createBraintreeCustomerSuccess = (result) => ({
    type: actionTypes.CREATE_BRAINTREE_CUSTOMER_SUCCESS,
    payload: result
});

export const createBraintreeCustomerFail = (error) => ({
    type: actionTypes.CREATE_BRAINTREE_CUSTOMER_FAIL,
    payload: error
});

export const createBraintreeCustomerReset = () => ({
    type: actionTypes.CREATE_BRAINTREE_CUSTOMER_RESET,
    payload: null
});

/**
 * Subscribe to Plan
 */
export const subscribeToPlan = (userId, planId) => ({
    type: actionTypes.SUBSCRIBE_TO_PLAN,
    payload: { userId, planId }
});

export const subscribeToPlanSuccess = (result) => ({
    type: actionTypes.SUBSCRIBE_TO_PLAN_SUCCESS,
    payload: result
});

export const subscribeToPlanFail = (error) => ({
    type: actionTypes.SUBSCRIBE_TO_PLAN_FAIL,
    payload: error
});

export const subscribeToPlanReset = () => ({
    type: actionTypes.SUBSCRIBE_TO_PLAN_RESET,
    payload: null
});

/**
 * Get braintree token
 */

export const getBraintreeClientToken = (userId, sandbox) => ({
    type: actionTypes.GET_BRAINTREE_CLIENT_TOKEN,
    payload: { userId, sandbox }
});

export const getBraintreeClientTokenSuccess = (result) => ({
    type: actionTypes.GET_BRAINTREE_CLIENT_TOKEN_SUCCESS,
    payload: result
});

export const getBraintreeClientTokenFail = (error) => ({
    type: actionTypes.GET_BRAINTREE_CLIENT_TOKEN_FAIL,
    payload: error
});

export const getBraintreeClientTokenReset = () => ({
    type: actionTypes.GET_BRAINTREE_CLIENT_TOKEN_RESET,
    payload: null
});

/**
 * Get subscriptions (transactions)
 */
export const getSubscriptionsByUserId = (userId, sandbox) => ({
    type: actionTypes.GET_SUBSCRIPTIONS,
    payload: { userId, sandbox }
});

export const getSubscriptionsByUserIdSuccess = (result) => ({
    type: actionTypes.GET_SUBSCRIPTIONS_SUCCESS,
    payload: result
});

export const getSubscriptionsByUserIdFail = (error) => ({
    type: actionTypes.GET_SUBSCRIPTIONS_FAIL,
    payload: error
});

export const getSubscriptionsByUserIdReset = () => ({
    type: actionTypes.GET_SUBSCRIPTIONS_RESET,
    payload: null
});

/**
 * cancel subscription
 */
export const cancelSubscription = (userId, subscriptionId, sandbox) => ({
    type: actionTypes.CANCEL_SUBSCRIPTION,
    payload: { userId, subscriptionId, sandbox }
});

export const cancelSubscriptionSuccess = (result) => ({
    type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
    payload: result
});

export const cancelSubscriptionFail = (error) => ({
    type: actionTypes.CANCEL_SUBSCRIPTION_FAIL,
    payload: error
});

export const cancelSubscriptionReset = () => ({
    type: actionTypes.CANCEL_SUBSCRIPTION_RESET,
    payload: null
});