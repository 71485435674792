exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".NoResultsFound__noVideosContainer--274ey {\n    flex-direction: column;\n    margin: 20px;\n    padding: 0;\n    background-color: #FFFFFF;\n    background-size: cover;\n    min-height:100%;\n    overflow: hidden;\n    display: flex;\n    flex: 1;\n    height: 100%;\n  }\n\n  p.NoResultsFound__subheading--w-LDP {\n    font-size: 14px;\n  }\n\n  p.NoResultsFound__tips--2wAcy {\n    font-size: 18px;\n    font-weight: bold;\n  }", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/common/NoResultsFound.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,WAAW;IACX,0BAA0B;IAC1B,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,QAAQ;IACR,aAAa;GACd;;EAED;IACE,gBAAgB;GACjB;;EAED;IACE,gBAAgB;IAChB,kBAAkB;GACnB","file":"NoResultsFound.css","sourcesContent":[".noVideosContainer {\n    flex-direction: column;\n    margin: 20px;\n    padding: 0;\n    background-color: #FFFFFF;\n    background-size: cover;\n    min-height:100%;\n    overflow: hidden;\n    display: flex;\n    flex: 1;\n    height: 100%;\n  }\n\n  p.subheading {\n    font-size: 14px;\n  }\n\n  p.tips {\n    font-size: 18px;\n    font-weight: bold;\n  }"],"sourceRoot":""}]);

// exports
exports.locals = {
	"noVideosContainer": "NoResultsFound__noVideosContainer--274ey",
	"subheading": "NoResultsFound__subheading--w-LDP",
	"tips": "NoResultsFound__tips--2wAcy"
};