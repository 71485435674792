exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Breadcrumb__breadcrumb--38MOk {\n    background-color: #FFFFFF;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/Stitch/Detail/Breadcrumb.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;CAC7B","file":"Breadcrumb.css","sourcesContent":[".breadcrumb {\n    background-color: #FFFFFF;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"breadcrumb": "Breadcrumb__breadcrumb--38MOk"
};