import React from 'react';
import PropTypes from 'prop-types';
import styles from './Section.css';

const Section = (props) => (
  <div
    id={props.name}
    className={styles.containerSection}
    style={props.containerStyle}
  >
    <div
      className={styles.innerContainerSection}
      style={props.style}
    >
      {props.children}
    </div>
  </div>
);

Section.propTypes = {
  backgroundColor: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

Section.defaultProps = {
  backgroundColor: '#FFFFFF',
  containerStyle: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
  },
  width: '100%',
  height: undefined,
  style: {
    height: '100%',
    width: '100%',
    backgroundColor: '#FFFFFF'
  }
}

export default Section;
