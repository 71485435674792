/**
 * VideoDetailReducer
 *
 * Fetch the detail of the video from the database
 */
import {
  GET_VIDEO_DETAIL,
  GET_VIDEO_DETAIL_ERROR,
  GET_VIDEO_DETAIL_SUCCESS
} from '../../actions/types'

// set the initial state of the reducer
 const INITIAL_STATE = {
   videoData: {},
   loadingVideoDetail: false,
   loadingVideoDetailError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {

     case GET_VIDEO_DETAIL:
       return {
         ...state,
         videoData: {},
         loadingVideoDetail: true,
         loadingVideoDetailError: null
       }
     break;

     case GET_VIDEO_DETAIL_SUCCESS:
      return {
        ...state,
        videoData: action.payload[0],
        loadingVideoDetail: false,
        loadingVideoDetailError: null
      }
     break;

     case GET_VIDEO_DETAIL_ERROR:
      return {
        ...state,
        videoData: {},
        loadingVideoDetail: false,
        loadingVideoDetailError: action.payload
      }
     break;

     default:
       return state;
   }
 };
