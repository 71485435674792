exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".VideoListItem__containerVideo--20hPw {\n    border-radius: 5px;\n    background-color: #FFFFFF;\n    padding: 10px;\n    margin-top: 10px;\n    border: 1px solid #EEEEEE;\n    overflow: visible;\n    cursor: pointer;\n  }\n\n.VideoListItem__image--1bWah { \n    margin-bottom: '10px'\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/Videos/VideoListItem.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,0BAA0B;IAC1B,cAAc;IACd,iBAAiB;IACjB,0BAA0B;IAC1B,kBAAkB;IAClB,gBAAgB;GACjB;;AAEH;IACI,qBAAqB;CACxB","file":"VideoListItem.css","sourcesContent":[".containerVideo {\n    border-radius: 5px;\n    background-color: #FFFFFF;\n    padding: 10px;\n    margin-top: 10px;\n    border: 1px solid #EEEEEE;\n    overflow: visible;\n    cursor: pointer;\n  }\n\n.image { \n    margin-bottom: '10px'\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"containerVideo": "VideoListItem__containerVideo--20hPw",
	"image": "VideoListItem__image--1bWah"
};