import React from 'react';

import PageContainer from '../common/container/PageContainer';

import Image from 'react-bootstrap/lib/Image';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';

import { loginTyping, resetPassword, resetPasswordClear } from '../../actions'
import { FieldGroup } from '../../util/misc';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PulseLoader } from 'halogenium';
import styles from './LoginAccount.css'

class ResetPassword extends React.Component {
  /**
   * state to hold the form vars
   */
  state = {
    code: this.props.match.params.code,
    password: '',
    passwordConfirm: ''
  }

  componentDidMount = () => {
    this.props.resetPasswordClear();
  }

  changePassword = (event) => {
    // reset errors...
    this.props.loginTyping();
    this.setState({
      password: event.target.value.trim(),
    })
  }

  changePasswordConfirm = (event) => {
    // reset errors...
    this.props.loginTyping();
    this.setState({
      passwordConfirm: event.target.value.trim(),
    })
  }
  /**
   * validate the fields
   */
  validateFields = () => {
    return (
      this.state.code !== '' &&
      this.state.password !== '' &&
      this.state.password.length > 4 &&
      this.state.passwordConfirm !== '' &&
      this.state.password === this.state.passwordConfirm
    );
  }
  /**
   * submit the registration
   */
  submitForm = () => {
    const {
      code,
      password,
      passwordConfirm
    } = this.state;
    this.props.resetPassword(password, passwordConfirm, code);
  }
  /**
   * render the reset form
   */
  render() {
    const canSubmit = this.validateFields();
    const {
      resetPasswordSuccess,
      loadingResetPassword,
      loadingResetPasswordError,
    } = this.props;
    const validationState = loadingResetPasswordError !== null ? 'error' : null;
    return(
      <PageContainer header={false}>
      <div className={styles.pageContainer}>
        <div className={styles.innerLoginContainer}>
          <div>
            <div className={styles.topHeader}>
              <div>
                <Image
                  src={require('../../assets/images/swingbot_logo.png')}
                  width={60}
                  height={60}
                  className="center-block"
                />
              </div>
              <h2>Reset Password</h2>
              <p className={styles.subHeading}>Choose a new password</p>
            </div>
            { loadingResetPasswordError !== null && resetPasswordSuccess !== true &&
              <div>
              <Col sm={12}>
                <div className={styles.bottomContainer}>
                  <h4>Error resetting password</h4>
                </div>
              </Col>
            </div>
            }
            { loadingResetPassword === false && resetPasswordSuccess !== true &&
              <div className={styles.bottomLoginContainer}>
              <Form>
                <Col sm={12}>
                  <FieldGroup
                    id="formControlsPassword"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    onChange={this.changePassword}
                    value={this.state.password}
                  />
                  <FieldGroup
                    id="formControlsPasswordConfirm"
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    onChange={this.changePasswordConfirm}
                    value={this.state.passwordConfirm}
                  />
                </Col>
                <Col sm={12}>
                  <FormGroup>
                    <Button
                      disabled={!canSubmit}
                      block
                      bsSize={'large'}
                      bsStyle={'success'}
                      onClick={this.submitForm}
                    >
                        Click to Reset Password
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
              <Col sm={12}>
                <hr style={{ borderColor: '#CCCCCC' }}/>
                <div className={styles.bottomContainer}>
                  Not a member? <a href="/register">Sign up</a>
                </div>
              </Col>
              <Col sm={12}>
                <hr />
                <div className={styles.bottomContainer}>
                  Already a member? <a href="/login">Sign in</a>
                </div>
              </Col>
            </div>
            }
            { resetPasswordSuccess === true &&
              <div className={styles.bottomLoginContainer}>
              <Col sm={12}>
                <hr style={{ borderColor: '#CCCCCC' }}/>
                <div className={styles.bottomContainer}>
                  Success! <a href="/login">click here to login</a>
                </div>
              </Col>
            </div>
            }
            { loadingResetPassword === true && resetPasswordSuccess !== true &&
              <div style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}>
                <div style={{ textAlign: 'center' }}>
                  <PulseLoader color="green" size="16px" margin="4px" />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      </PageContainer>
    );
  }
}
const mapStateToProps = ({ resetPassword }) => {
  const {
    resetPasswordSuccess,
    loadingResetPassword,
    loadingResetPasswordError
  } = resetPassword;
  return {
    resetPasswordSuccess,
    loadingResetPassword,
    loadingResetPasswordError
  };
}
const mapDispatchToProps = dispatch => bindActionCreators({
  resetPassword,
  resetPasswordClear,
  loginTyping
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
