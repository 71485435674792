/**
 * Unsubscribe
 */
import {
    UNSUBSCRIBE,
    UNSUBSCRIBE_ERROR,
    UNSUBSCRIBE_SUCCESS,
    UNSUBSCRIBE_CLEAR
  } from '../../actions/types'
  // set the initial state of the reducer
   const INITIAL_STATE = {
     unsubscribeSuccess: null,
     loadingUnsubscribe: false,
     loadingUnsubscribeError: null,
   };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
       /**
        * activate user
        */
       case UNSUBSCRIBE:
         return {
           ...state,
           unsubscribeSuccess: null,
           loadingUnsubscribe: true,
           loadingUnsubscribeError: null
         }
  
       case UNSUBSCRIBE_SUCCESS:
        return {
          ...state,
          unsubscribeSuccess: true,
          loadingUnsubscribe: false,
          loadingUnsubscribeError: null,
        }
  
       case UNSUBSCRIBE_ERROR:
        return {
          ...state,
          unsubscribeSuccess: false,
          loadingUnsubscribe: false,
          loadingUnsubscribeError: action.payload
        }
  
      case UNSUBSCRIBE_CLEAR:
         return {
           ...state,
           unsubscribeSuccess: null,
           loadingUnsubscribe: false,
           loadingUnsubscribeError: null
         }
  
       default:
         return state;
     }
   };
  