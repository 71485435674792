/**
 * LiveBroadcastListReducer
 *
 * Fetch the list of live broadcasts from YouTube
 */
import {
    GET_LIVE_BROADCAST_LIST,
    GET_LIVE_BROADCAST_LIST_ERROR,
    GET_LIVE_BROADCAST_LIST_SUCCESS,
    GET_EXISTING_BROADCAST_LIST,
    GET_EXISTING_BROADCAST_LIST_ERROR,
    GET_EXISTING_BROADCAST_LIST_SUCCESS,
    GET_UPCOMING_BROADCAST_LIST,
    GET_UPCOMING_BROADCAST_LIST_ERROR,
    GET_UPCOMING_BROADCAST_LIST_SUCCESS
  } from '../../actions/types'
  
  // set the initial state of the reducer
   const INITIAL_STATE = {
     liveBroadcasts: [],
     totalLiveBroadcasts: null,
     loadingLiveBroadcastList: null,
     loadingLiveBroadcastListError: null,

     upcomingBroadcasts: [],
     totalUpcomingBroadcasts: null,
     loadingUpcomingBroadcastList: null,
     loadingUpcomingBroadcastListError: null,

     pastBroadcasts: [],
     totalExistingBroadcasts: null,
     loadingExistingBroadcastList: null,
     loadingExistingBroadcastListError: null,
   };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
       case GET_LIVE_BROADCAST_LIST:
        console.log('get live broadcast list reducer');
         return {
           ...state,
           liveBroadcasts: [],
           loadingLiveBroadcastList: true,
           loadingLiveBroadcastListError: null
         }
  
       case GET_LIVE_BROADCAST_LIST_SUCCESS:
        return {
          ...state,
          liveBroadcasts: action.payload,
          loadingLiveBroadcastList: null,
          loadingLiveBroadcastListError: null
        }
  
       case GET_LIVE_BROADCAST_LIST_ERROR:
        return {
          ...state,
          liveBroadcasts: [],
          loadingLiveBroadcastList: null,
          loadingLiveBroadcastListError: action.payload
        }

        case GET_EXISTING_BROADCAST_LIST:
        console.log('get live broadcast list reducer');
         return {
           ...state,
           pastBroadcasts: [],
           loadingExistingBroadcastList: true,
           loadingExistingBroadcastListError: null
         }
  
       case GET_EXISTING_BROADCAST_LIST_SUCCESS:
        return {
          ...state,
          pastBroadcasts: action.payload,
          loadingExistingBroadcastList: null,
          loadingExistingBroadcastListError: null
        }
  
       case GET_EXISTING_BROADCAST_LIST_ERROR:
        return {
          ...state,
          pastBroadcasts: [],
          loadingExistingBroadcastList: null,
          loadingExistingBroadcastListError: action.payload
        }

        case GET_UPCOMING_BROADCAST_LIST:
        console.log('get live broadcast list reducer');
         return {
           ...state,
           upcomingBroadcasts: [],
           loadingUpcomingBroadcastList: true,
           loadingUpcomingBroadcastListError: null
         }
  
       case GET_UPCOMING_BROADCAST_LIST_SUCCESS:
        return {
          ...state,
          upcomingBroadcasts: action.payload,
          loadingUpcomingBroadcastList: null,
          loadingUpcomingBroadcastListError: null
        }
  
       case GET_UPCOMING_BROADCAST_LIST_ERROR:
        return {
          ...state,
          upcomingBroadcasts: [],
          loadingUpcomingBroadcastList: null,
          loadingUpcomingBroadcastListError: action.payload
        }
  
       default:
         return state;
     }
   };
  