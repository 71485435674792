exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.AppBadges__badgeContainer--3pa4U {\n    display: flex;\n    flex-direction: row;\n    margin-top: 20px;\n    justify-content: flex-start;\n    margin-bottom: 20px;\n}\n\n.AppBadges__appStoreBadge--3xOrH {\n    width: 200px;\n    margin-right: 10px;\n    justify-content: space-between;\n}\n\n@media only screen and (max-width: 1024px) {    \n    .AppBadges__badgeContainer--3pa4U {\n        flex-direction: column;\n        justify-content: center;\n    }\n    .AppBadges__appStoreBadge--3xOrH {\n        width: 140px;\n        margin-right: 0;\n        margin-bottom: 10px;\n    }\n}\n", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/common/AppBadges.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,oBAAoB;IACpB,iBAAiB;IACjB,4BAA4B;IAC5B,oBAAoB;CACvB;;AAED;IACI,aAAa;IACb,mBAAmB;IACnB,+BAA+B;CAClC;;AAED;IACI;QACI,uBAAuB;QACvB,wBAAwB;KAC3B;IACD;QACI,aAAa;QACb,gBAAgB;QAChB,oBAAoB;KACvB;CACJ","file":"AppBadges.css","sourcesContent":["\n.badgeContainer {\n    display: flex;\n    flex-direction: row;\n    margin-top: 20px;\n    justify-content: flex-start;\n    margin-bottom: 20px;\n}\n\n.appStoreBadge {\n    width: 200px;\n    margin-right: 10px;\n    justify-content: space-between;\n}\n\n@media only screen and (max-width: 1024px) {    \n    .badgeContainer {\n        flex-direction: column;\n        justify-content: center;\n    }\n    .appStoreBadge {\n        width: 140px;\n        margin-right: 0;\n        margin-bottom: 10px;\n    }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"badgeContainer": "AppBadges__badgeContainer--3pa4U",
	"appStoreBadge": "AppBadges__appStoreBadge--3xOrH"
};