/**
 * StitchDeactivateVideoReducer
 *
 * Deactivate a stitch video
 */
import {
    STITCH_DEACTIVATE_VIDEO,
    STITCH_DEACTIVATE_VIDEO_ERROR,
    STITCH_DEACTIVATE_VIDEO_SUCCESS,
    STITCH_DEACTIVATE_VIDEO_RESET
  } from '../../actions/types'
  
    // set the initial state of the reducer
    const INITIAL_STATE = {
        stitchDeactivateVideoSuccess: null,
        loadingStitchDeactivateVideo: null,
        stitchDeactivateVideoError: null,
    };
  
   export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
  
      case STITCH_DEACTIVATE_VIDEO_RESET:
      console.log('reset')
         return {
           ...state,
           stitchDeactivateVideoSuccess: null,
           loadingStitchDeactivateVideo: null,
           stitchDeactivateVideoError: null
         }

       case STITCH_DEACTIVATE_VIDEO:
         return {
           ...state,
           stitchDeactivateVideoSuccess: null,
           loadingStitchDeactivateVideo: true,
           stitchDeactivateVideoError: null
         }
  
       case STITCH_DEACTIVATE_VIDEO_SUCCESS:
        return {
          ...state,
          stitchDeactivateVideoSuccess: true,
          loadingStitchDeactivateVideo: null,
          stitchDeactivateVideoError: null
        }
  
       case STITCH_DEACTIVATE_VIDEO_ERROR:
        return {
          ...state,
          stitchAvailable: null,
          loadingStitchDeactivateVideo: null,
          stitchDeactivateVideoError: action.payload
        }
  
       default:
         return state;
     }
   };
  