import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageContainer from '../common/container/PageContainer'
import { getVideosByUserId } from '../../actions'
import VideoListItem from './VideoListItem'
import Loading from '../common/Loading'
import SwingbotHeaderClear from '../common/navbar/SwingbotHeaderClear';
import SectionHeader from '../common/header/SectionHeader';
import UploadSwing from '../common/UploadSwing';
// react-bootstrap
import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';

import AppBadges from '../common/AppBadges';

import styles from './VideoList.css';

/**
 * class VideoList
 */
class VideoList extends React.Component {
  componentDidMount = () => {
    // lets fetch the videos
    this.props.getVideosByUserId(this.props.authUser.data.user_id);
  }
  /**
   * render the video grid
   */
  renderVideoList = () => {
    const { videos } = this.props;
    return videos && videos.length > 0 ? videos.map((video, index) => {
        return(
          <VideoListItem
            key={`${video.video_id}_${index}`}
            videoId={video.video_id}
            thumb={video.zen_thumb}
            video={video}
            onClick={this.handleSelectVideo}
          />
        )
    }) : <UploadSwing />
  }

  handleSelectVideo = (videoId) => {
    this.props.history.push({
      pathname: '/analysis/' + videoId
    });
  }

  /**
   * render
   */
  render() {
    return (
      <PageContainer title="Videos">
        <SwingbotHeaderClear variant="dark" />
        <SectionHeader 
          title={'Video Uploads'} 
          description={'A library of all the videos you have uploaded using Swingbot.'}
        />
        { !this.props.loadingVideoList &&
          <Container className={styles.container}>
            <Row>
              {this.renderVideoList()}
            </Row>
          </Container>
        }
        { this.props.loadingVideoList && <Loading title={'Video Uploads'} />}
      </PageContainer>
    )
  }
}

const mapStateToProps = ({ videoList, auth }) => {
  const { videos, loadingVideoList } = videoList;
  const { authUser } = auth;
  return { videos, loadingVideoList, authUser };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getVideosByUserId,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(VideoList);
