import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'
import PageContainer from '../common/container/PageContainer';
import Button from 'react-bootstrap/lib/Button';
import Image from 'react-bootstrap/lib/Image';
import { PulseLoader } from 'halogenium';
import { 
    getInvitationByNonce, 
    acceptInvitationRequest, 
    denyInvitationRequest,
    resetInvitationRequests 
} from '../../actions/Connection';

import styles from './ConnectionRequest.css';

/**
 * Connection Request
 * - show the user the request and if they want to accept the 
 *      request they have to login first...
 */
class ConnectionRequest extends React.Component {

  componentDidMount() {
      // fetch the details of the connection request
      // based on the cr code...
      const query = queryString.parse(this.props.location.search);
      this.props.resetInvitationRequests();
      this.props.getInvitationByNonce(query.cr);
  }

  handleDenyRequest = () => {
    const { auth } = this.props;
    const userId = auth.authUser.data.user_id;
    const query = queryString.parse(this.props.location.search);
    this.props.denyInvitationRequest(userId, query.cr);
  }

  handleAcceptRequest = () => {
    const { auth } = this.props;
    const userId = auth.authUser.data.user_id;
    const query = queryString.parse(this.props.location.search);
    this.props.acceptInvitationRequest(userId, query.cr);
  }

  render() {
      const { auth, getInvitationDataLoading, invitationData } = this.props;
      // we have to make sure the invitee_id matches the current logged in user id
      const isForLoggedInUser = invitationData !== false && auth.authUser.data.user_id === ((invitationData || {}).invitee_id || false);
      const {
          denyInvitationLoading,
          denyInvitationSuccess,
          acceptInvitationLoading,
          acceptInvitationSuccess
      } = this.props;
    return (
      <PageContainer header={false}>
        <div className={styles.pageContainer}>
          <div className={styles.innerLoginContainer}>
            <div>
              <div className={styles.topHeader}>
                <div>
                  <Image
                    src={require('../../assets/images/swingbot_logo.png')}
                    width={60}
                    height={60}
                    className="center-block"
                  />
                </div>
                { getInvitationDataLoading === false && invitationData !== false && isForLoggedInUser === true &&
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <h2>Connection Request</h2>
                        <p className={styles.subHeading}>{`${invitationData.inviter_f_name} ${invitationData.inviter_l_name} has sent you a connection request.`}</p>
                        <br />
                        { denyInvitationSuccess === null && acceptInvitationSuccess === null &&
                            <div>
                                <Button
                                    disabled={denyInvitationLoading === true || acceptInvitationLoading === true}
                                    block
                                    size={'large'}
                                    onClick={this.handleAcceptRequest}
                                    className={styles.accept}
                                >
                                    Accept
                                </Button>
                                <Button
                                    disabled={denyInvitationLoading === true || acceptInvitationLoading === true}
                                    block
                                    size={'large'}
                                    onClick={this.handleDenyRequest}
                                    className={styles.deny}
                                >
                                    Deny
                                </Button>
                            </div>
                        }
                        { denyInvitationSuccess !== null &&
                            <div style={{ textAlign: 'center' }}>
                                <p className={styles.subHeading}>Request denied successfully.</p>
                                <br />
                                <Button
                                    block
                                    size={'large'}
                                    onClick={this.props.goToVideos}
                                    className={styles.accept}
                                >
                                    Click to Go Home
                                </Button>
                            </div>
                        }
                        { acceptInvitationSuccess !== null &&
                            <div style={{ textAlign: 'center' }}>
                                <p>Request accepted successfully.</p>
                                <br />
                                <Button
                                    block
                                    size={'large'}
                                    onClick={this.props.goToVideos}
                                    className={styles.accept}
                                >
                                    Click to Go Home
                                </Button>
                            </div>
                        }
                        { (denyInvitationLoading === true || acceptInvitationLoading === true) &&
                            <div>
                                <PulseLoader />
                            </div>
                        }
                    </div>
                }
                { getInvitationDataLoading === false && invitationData === false &&
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <h2>Connection Request</h2>
                        <p className={styles.subHeading}>Hmm, the request could not be found.</p>
                        <br />
                        <Button
                            block
                            size={'large'}
                            onClick={this.props.goToLogin}
                            className={styles.accept}
                        >
                            Back to Login
                        </Button>
                    </div>
                }
                { (getInvitationDataLoading === false && invitationData !== false && isForLoggedInUser === false) &&
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <h2>Connection Request</h2>
                        <p className="subHeading">Hmm, this request was meant for someone else.</p>
                        <br />
                        <Button
                            block
                            size={'large'}
                            onClick={this.props.goToLogin}
                            className={styles.accept}
                        >
                            Back to Login
                        </Button>
                    </div>
                }
                { getInvitationDataLoading === true &&
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <h2>Connection Request</h2>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <PulseLoader color="green" size="16px" margin="4px" />
                        </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
  );
  }
}

const mapStateToProps = ({ auth, user, connection }) => {
  const {
    getInvitationDataLoading,
    getInvitationDataError,
    invitationData,
    denyInvitationLoading,
    denyInvitationError,
    denyInvitationSuccess,
    acceptInvitationLoading,
    acceptInvitationError,
    acceptInvitationSuccess,
  } = connection;
  return {
    user,
    auth,
    getInvitationDataLoading,
    getInvitationDataError,
    invitationData,
    denyInvitationLoading,
    denyInvitationError,
    denyInvitationSuccess,
    acceptInvitationLoading,
    acceptInvitationError,
    acceptInvitationSuccess,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getInvitationByNonce,
    acceptInvitationRequest,
    denyInvitationRequest,
    resetInvitationRequests,
    goToLogin: () => push('/login'),
    goToVideos: () => push('/video'),
    viewConnectionRequest: (search) => push(search),
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionRequest)
