/**
 * GET /upload get a signed upload url from amazon for s3
 */
import {
  GET_UPLOAD_URL,
  GET_UPLOAD_URL_ERROR,
  GET_UPLOAD_URL_SUCCESS
} from '../types'
// load up the AWS API Client
// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');

/**
 * GET /upload
 */
export const getSignedUrlUpload = (file) => {
  return (dispatch) => {
    dispatch({
      type: GET_UPLOAD_URL,
      payload: null
    });
    return axios({
      method: 'get',
      url: endpointForApi('www', 'upload?filename=' + file.name + '&filetype=' + file.type),
      headers: headersForApi('www')
    })
    .then(function (result) {
      const signedUrl = result.data.data;
      const options = {
        headers: {
          'Content-Type': file.type,
        }
      };
      return axios.put(signedUrl, file, options);
    })
    .then(function (result) {
    })
      .then(result => {
        dispatch({
          type: GET_UPLOAD_URL_SUCCESS,
          payload: result.data.data
        });
      })
      .catch(result => {
        dispatch({
          type: GET_UPLOAD_URL_ERROR,
          payload: result
        });
      });
    };
  };
