import React from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/lib/Image';
import CountdownTimer from '../common/CountdownTimer';

import './Webinar.css';

class WebinarListItem extends React.Component {
    render() {
        const {
            title,
            description,
            thumbnail,
            startTime,
            endTime,
            id
        } = this.props;

        const startTimeFormatted = startTime !== null ? new Date(startTime).toString() : '';
        const endTimeFormatted = endTime !== null ? new Date(endTime).toString() : '';

        return (
            <div className="webinarListItemContainer">
                <div className="webinarImageContainer">
                    <a href={`/webinar/${id}`}>
                        <Image src={thumbnail} className="webinarImage" />
                    </a>
                </div>
                <div className="webinarListItemBottomContainer">
                    <h1><a href={`/webinar/${id}`}><strong>{title}</strong></a></h1>
                    <p>{description}</p>
                    <p>Starts: {startTimeFormatted}</p>
                    <p>Ends: {endTimeFormatted}</p>
                    <CountdownTimer endDate={new Date(endTime)} />
                </div>
            </div>
        )
    }
}

WebinarListItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    thumbnail: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
};

WebinarListItem.defaultProps = {
    title: '',
    description: '',
    thumbnail: null,
    startTime: null,
    endTime: null
}
export default WebinarListItem;