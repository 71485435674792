/**
 * UserRegistrationReducer
 *
 * User registration reducer
 */
import {
  REGISTER_USER,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_RESET
} from '../../actions/types'
// set the initial state of the reducer
 const INITIAL_STATE = {
   authUser: null,
   loadingRegisterUser: false,
   loadingRegisterUserError: null,
   token: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     /**
      * login user
      */
     case REGISTER_USER:
       return {
         ...state,
         authUser: null,
         loadingRegisterUser: true,
         loadingRegisterUserError: null
       }

     case REGISTER_USER_SUCCESS:
      return {
        ...state,
        authUser: action.payload.data,
        loadingRegisterUser: true,
        loadingRegisterUserError: null,
        token: action.payload.data.token,
        // authUser: action.payload.data,
        // loadingLoginUser: false,
        // loadingLoginUserError: null,
        // token: action.payload.token,
        // isInvalid: null,
        // isExpired: null
      }

     case REGISTER_USER_ERROR:
      return {
        ...state,
        authUser: null,
        loadingRegisterUser: false,
        loadingRegisterUserError: ((action.payload.response || {}).data || {}).error || null
      }

    case REGISTER_USER_RESET:
      return {
        ...state,
        authUser: null,
        loadingRegisterUser: false,
        loadingRegisterUserError: null,
        token: null
      }

     default:
       return state;
   }
 };
