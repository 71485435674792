import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux'
// import { verifyToken, isTokenExpired } from '../util/jwt';
import { redirectFromQueryString } from '../util/redirect';

const AuthorizedRedirectRoute = ({ component: Component, ...rest }) => {
  // TODO: figure out the expired stuff..
  // const {
  //   token,
  //   data,
  //   success
  // } = rest.authUser;
  // check to see if the token is expired...
  //const isExpired = isTokenExpired(rest.authUser.token);
  const isExpired = false;
  return (
    <Route
      {...rest}
      render={ (props) => {
        // query string?
        // if there is a query string, we have to create 
        // the redirect data to pass to the Redirect component
        const queryString = rest.location.search || false;
        const redirectData = redirectFromQueryString(queryString);
        
        // for now lets just check the user, but in the future
        // maybe we should check the token?
        // should we check to see if the user is subscribed?
        return (
            isExpired !== true &&
            rest.authUser !== null &&
            rest.authUser.token !== null &&
            rest.authUser.success === true
        ) ? (
            <Component { ...props } />
        ) : (
            <Redirect
            to={{
                pathname: '/login',
                state: { from: props.location },
                search: redirectData !== false ? redirectData.search : null
            }}
            />
        )}}
    />
  )
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
}

export default connect(
  mapStateToProps, null, null, { pure: false }
)(AuthorizedRedirectRoute)
