import {
    GET_STITCH_DETAIL,
    GET_STITCH_DETAIL_ERROR,
    GET_STITCH_DETAIL_SUCCESS,
  } from '../types'
  // endpoint helpers and axios
  import { endpointForApi, headersForApi } from '../../api/swingbotApi';
  const axios = require('axios');
  /**
   * get video list
   */
  export const getStitchDetailByStitchId = (stitchId) => {
    return (dispatch) => {
      dispatch({
        type: GET_STITCH_DETAIL,
        payload: stitchId
      });
      const endpoint = endpointForApi('stitch', `stitch/${stitchId}`);
      return axios({
        url: endpoint,
        method: 'get',
        headers: headersForApi('stitch')
      })
        .then(result => {
          console.log('success', result.data.data);
          dispatch({
            type: GET_STITCH_DETAIL_SUCCESS,
            payload: result.data.data
          });
        })
        .catch(result => {
          console.log('error', result);
          dispatch({
            type: GET_STITCH_DETAIL_ERROR,
            payload: result
          });
        });
      };
    };
  