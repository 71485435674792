/**
 * Choose Password (complete registration)
 * 
 * 1. A user invites a buddy
 * 2. A user is created by email
 * 3. A user must complete registration (this component)
 * 4. Then allow the user to login with the new password (button to login)
 */
import React from 'react';
import PageContainer from '../common/container/PageContainer';
import Image from 'react-bootstrap/lib/Image';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import queryString from 'query-string';
import { completeRegistration, completeRegistrationReset } from '../../actions/User'
import { getInvitationByNonce } from '../../actions/Connection';
import { FieldGroup } from '../../util/misc';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PulseLoader } from 'halogenium';
import styles from './LoginAccount.css'

class ChoosePassword extends React.Component {
  /**
   * state to hold the form vars
   */
  state = {
    code: this.props.match.params.code,
    password: '',
    passwordConfirm: ''
  }

  componentDidMount = () => {
    // lets parse the querystring for the nonce...
    this.props.completeRegistrationReset();
    const query = queryString.parse(this.props.location.search);
    // let's fetch the invitation request by nonce if there is a cr in the query string...
    if (query['cr']) {
        // fetching the invitation data
        this.props.getInvitationByNonce(query.cr);
    }
  }

  changePassword = (event) => {
    // choose errors...
    this.setState({
      password: event.target.value.trim(),
    })
  }

  changePasswordConfirm = (event) => {
    // choose errors...
    this.setState({
      passwordConfirm: event.target.value.trim(),
    })
  }
  /**
   * validate the fields
   */
  validateFields = () => {
    return (
      this.state.password !== '' &&
      this.state.password.length > 4 &&
      this.state.passwordConfirm !== '' &&
      this.state.password === this.state.passwordConfirm
    );
  }
  /**
   * submit the complete registration form
   * to set the password and status to 2
   */
  submitForm = () => {
    const nonce = this.getNonce();
    const { password } = this.state;
    // submit the form for the change of password...
    this.props.completeRegistration(nonce, password);
  }
  /**
   * get the nonce from the query string
   */
  getNonce = () => {
    const query = queryString.parse(this.props.location.search);
    // let's fetch the invitation request by nonce if there is a cr in the query string...
    return query['cp'] ? query.cp : false;
  }
  /**
   * render the choose form
   */
  render() {
    const canSubmit = this.validateFields();
    const {
        invitationData,
        completeRegistrationSuccess,
        loadingCompleteRegistration,
        loadingCompleteRegistrationError,
    } = this.props;
    console.log(invitationData);
    return(
      <PageContainer header={false}>
        <div className={styles.pageContainer}>
            <div className={styles.innerLoginContainer}>
            <div>
                <div className={styles.topHeader}>
                <div>
                    <Image
                    src={require('../../assets/images/swingbot_logo.png')}
                    width={60}
                    height={60}
                    className="center-block"
                    />
                </div>
                <h2>Choose Password</h2>
                <p className={styles.subHeading}>Choose a new password</p>
                </div>
                { loadingCompleteRegistrationError !== null && completeRegistrationSuccess !== true &&
                    <div>
                        <Col sm={12}>
                            <div className={styles.bottomContainer}>
                            <h4>Error choosing password</h4>
                            </div>
                        </Col>
                    </div>
                }
                { loadingCompleteRegistration === false && completeRegistrationSuccess !== true &&
                <div className={styles.bottomLoginContainer}>
                <Form>
                    <Col sm={12}>
                    <FieldGroup
                        id="formControlsPassword"
                        type="password"
                        label="Password"
                        placeholder="Password"
                        onChange={this.changePassword}
                        value={this.state.password}
                    />
                    <FieldGroup
                        id="formControlsPasswordConfirm"
                        type="password"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        onChange={this.changePasswordConfirm}
                        value={this.state.passwordConfirm}
                    />
                    </Col>
                    <Col sm={12}>
                        <FormGroup>
                            <Button
                            disabled={!canSubmit}
                            block
                            onClick={this.submitForm}
                            >
                                Click to Set New Password
                            </Button>
                        </FormGroup>
                    </Col>
                </Form>
                <Col sm={12}>
                    <hr style={{ borderColor: '#CCCCCC' }}/>
                    <div className={styles.bottomContainer}>
                    <a href="/">Back Home</a>
                    </div>
                </Col>
                </div>
                }
                { completeRegistrationSuccess === true &&
                    <div className={styles.bottomLoginContainer}>
                        <Col sm={12}>
                            <hr style={{ borderColor: '#CCCCCC' }}/>
                            { invitationData !== null &&
                                <div className={styles.bottomContainer}>
                                    Success! <a href={`/login?cr=${invitationData.nonce}`}>click here to login</a>
                                </div>
                            }
                            { invitationData === null &&
                                <div className={styles.bottomContainer}>
                                    Success! <a href={'/login'}>click here to login</a>
                                </div>
                            }
                        </Col>
                    </div>
                }
                { loadingCompleteRegistration === true && completeRegistrationSuccess !== true &&
                <div style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%'
                }}>
                    <div style={{ textAlign: 'center' }}>
                    <PulseLoader color="green" size="16px" margin="4px" />
                    </div>
                </div>
                }
            </div>
            </div>
        </div>
      </PageContainer>
    );
  }
}
const mapStateToProps = ({ completeRegistration, connection }) => {
  const {
    completeRegistrationSuccess,
    loadingCompleteRegistration,
    loadingCompleteRegistrationError
  } = completeRegistration;
  const { invitationData } = connection;
  return {
    completeRegistrationSuccess,
    loadingCompleteRegistration,
    loadingCompleteRegistrationError,
    invitationData
  };
}
const mapDispatchToProps = dispatch => bindActionCreators({
    completeRegistration,
    completeRegistrationReset,
    getInvitationByNonce
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ChoosePassword)
