import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux'

const AuthorizedNonSubscriberRoute = ({ component: Component, ...rest }) => {
  const isExpired = false;
  return (
    <Route
      {...rest}
      render={ (props) => {
      // for now lets just check the user, but in the future
      // maybe we should check the token?
      // should we check to see if the user is subscribed?
      if (
        isExpired !== true &&
        rest.authUser !== null &&
        rest.authUser.token !== null &&
        rest.authUser.success === true
      ) {
        // check if the user is subscribed, pass to account page...
        if (rest.authUser.data.is_subscribed === true) {
            // redirect to subscribe page
            return (
                <Redirect
                    to={{
                        pathname: '/account',
                        state: { from: props.location }
                    }}
                />
            );
            } else {
                return (<Component { ...props } />);
            }
        } else {
            // not logged in...
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />
            );
        }
      }}
    />
  )
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
}

export default connect(
  mapStateToProps, null, null, { pure: false }
)(AuthorizedNonSubscriberRoute)
