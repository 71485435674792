/**
 * StitchDetail
 *
 * Get the stitch details
 */
import {
    GET_STITCH_DETAIL,
    GET_STITCH_DETAIL_ERROR,
    GET_STITCH_DETAIL_SUCCESS,
  } from '../../actions/types'
  
    // set the initial state of the reducer
    const INITIAL_STATE = {
        stitchDetailSuccess: null,
        loadingStitchDetail: null,
        stitchDetailError: null,
    };
  
   export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
  
       case GET_STITCH_DETAIL:
         return {
           ...state,
           stitchDetails: null,
           loadingStitchDetail: true,
           stitchDetailError: null
         }
  
       case GET_STITCH_DETAIL_SUCCESS:
        return {
          ...state,
          stitchDetails: action.payload,
          loadingStitchDetail: null,
          stitchDetailError: null
        }
  
       case GET_STITCH_DETAIL_ERROR:
        return {
          ...state,
          stitchDetails: null,
          loadingStitchDetail: null,
          stitchDetailError: action.payload
        }
  
       default:
         return state;
     }
   };
  