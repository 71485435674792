import React from 'react';
import Image from 'react-bootstrap/lib/Image';
import styles from './UploadSwing.css';

class UploadSwing extends React.Component {
    render() {
        return (
            <div className={styles.noVideosContainer}>
                <h1>Upload your Swing</h1>
                <p className={styles.noVideosSummary}>Swingbot will analyze your swing and create custom instructional videos in minutes.</p>
                <div className={styles.badgeContainer}>
                <a href="https://itunes.apple.com/us/app/swingbot-golf-swing-video/id516200328?mt=8" target="_blank">
                    <Image
                    className={styles.appStoreBadge}
                    src={require('../../assets/images/app-store-logo.png')}
                    />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.databake.swingbot" target="_blank">
                    <Image
                    className={styles.appStoreBadge}
                    src={require('../../assets/images/google_web.png')}
                    />
                </a>
                </div>
            </div>
        )
    };
}

export default UploadSwing;
