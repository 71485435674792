import {
    GET_ANALYSIS_HISTORY_LIST,
    GET_ANALYSIS_HISTORY_LIST_ERROR,
    GET_ANALYSIS_HISTORY_LIST_SUCCESS
  } from '../types'
  // endpoint helpers and axios
  import { endpointForApi, headersForApi } from '../../api/swingbotApi';
  const axios = require('axios');
  /**
   * get analysis list
   */
  export const getAnalysisHistory = (userId) => {
    return (dispatch) => {
      dispatch({
        type: GET_ANALYSIS_HISTORY_LIST,
        payload: null
      });
      const endpoint = endpointForApi('user', `users/${userId}/history`);
      return axios({
        url: endpoint,
        method: 'get',
        headers: headersForApi('user')
      })
        .then(result => {
          dispatch({
            type: GET_ANALYSIS_HISTORY_LIST_SUCCESS,
            payload: result.data.data
          });
        })
        .catch(result => {
          dispatch({
            type: GET_ANALYSIS_HISTORY_LIST_ERROR,
            payload: result
          });
        });
      };
    };
  