import React from 'react';
/**
 * capitalizeFirstLetter
 * Capitalize the first letter of a string
 *
 * @param  string string  the string to be capitalized
 * @return string   the finished string
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
/**
 * convert a standaed "word" to a lexicon word
 */
export const convertToLexicon = (name) => {
  return name.toLowerCase().split(' ').join('_');
};

export const convertToDisplayName = (name) => {
  var lower = name.toLowerCase();
  return lower.replace(/(^| )(\w)/g, function(x) {
    return x.toUpperCase();
  });
};

/**
 * generate a random string
 */
export const randomString = (length = 20) => {
	const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
	let string = '';
  for (var i = 0; i < length; i++) {
    string += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return string;
};
/**
 * isError
 */
export const isError = (value) => {
 return value instanceof Error && typeof value.message !== 'undefined';
};
/**
 * isString
 */
export const isString = (value) => {
 return typeof value === 'string';
};
/**
 * check if the code is passing or failing
 * @example "P1" is passing
 */
export const isPassing = (code) => {
  return code.indexOf('P') > -1 ? true : false;
};
/**
 * is object
 */
export const isObject = (obj) => {
  return obj === Object(obj);
};
/**
 * renderValue
 *
 * Convert the value into a link if it is deemed necessary
 */
export const convertToLink = (key, value) => {
  const lowerKey = key.toLowerCase();
  let finalValue = value;
  switch(lowerKey) {
    case 'analysis_id':
      finalValue = <a href={`/analysis/${value}`}>{value}</a>;
    break;
    case 'video_id':
      finalValue = <a href={`/videos/${value}`}>{value}</a>;
    break;
    case 'user_id':
      finalValue = <a href={`/users/${value}`}>{value}</a>;
    break;
    case 'target_id':
      finalValue = <a href={`/analysis/targets/${value}`}>{value}</a>;
    break;
    case 'program_id':
      finalValue = <a href={`/analysis/programs/${value}`}>{value}</a>;
    break;
    default:
      // check to see if this is a link!
      if (isLink(value) === true) {
        finalValue = <a href={value}>{value}</a>;
      }
    break;
  }
  return <p style={{ wordBreak: 'break-all'}}>{finalValue}</p>;
};
/**
 * check if the data is a url
 */
export const isLink = (data) => {
  if (typeof data === 'string' === true) {
    return data.substring(0,4) === 'http';
  } else {
    return false;
  }
};
