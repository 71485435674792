/**
 * Analyze Video Details
 */
import {
    ANALYZE_VIDEO_DETAIL,
    ANALYZE_VIDEO_DETAIL_ERROR,
    ANALYZE_VIDEO_DETAIL_SUCCESS
  } from '../../actions/types'
  
  // set the initial state of the reducer
   const INITIAL_STATE = {
     analysisResults: null,
     loadingAnalyzeVideoDetail: null,
     loadingAnalyzeVideoDetailError: null,
   };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
  
       case ANALYZE_VIDEO_DETAIL:
         return {
           ...state,
           analysisResults: null,
           loadingAnalyzeVideoDetail: true,
           loadingAnalyzeVideoDetailError: null
         }
  
       case ANALYZE_VIDEO_DETAIL_SUCCESS:
        return {
          ...state,
          analysisResults: action.payload,
          loadingAnalyzeVideoDetail: null,
          loadingAnalyzeVideoDetailError: null
        }
  
       case ANALYZE_VIDEO_DETAIL_ERROR:
        return {
          ...state,
          analysisResults: null,
          loadingAnalyzeVideoDetail: null,
          loadingAnalyzeVideoDetailError: action.payload
        }
  
       default:
         return state;
     }
   };
  