import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'

import PageContainer from '../common/container/PageContainer';
import SectionHeader from '../common/header/SectionHeader';
import SwingbotHeaderClear from '../common/navbar/SwingbotHeaderClear';

import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Card from 'react-bootstrap/lib/Card';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import Badge from 'react-bootstrap/lib/Badge';
import Loading from '../common/Loading';

import { 
    subscribeToPlan, 
    subscribeToPlanReset,
    getBraintreeClientToken, 
    createBraintreeCustomer,
    createBraintreeCustomerGetToken
} from '../../actions/Subscribe';

import DropIn from "braintree-web-drop-in-react";

import styles from './Cart.css';

const plans = {
    'monthly': {
        price: '19.99',
        name: 'PRO-MONTHLY',
        months: 1,
        term: 'monthly'
    },
    '3-month': {
        price: '29.99',
        name: 'PRO-3',
        months: 3,
        term: '3 months'
    },
    'yearly': {
        price: '99.99',
        name: 'PRO-ANNUAL',
        months: 12,
        term: 'annually'
    },
};
class Cart extends React.Component {

    instance; 
    state = {
        sandbox: false,
        paymentNonce: null,
        planId: null,
        planSelected: '3-month'
    }

    componentDidMount = () => {
        const {
            user_id,
            f_name,
            l_name,
            email
        } = this.props.authUser.data;
        // reset...
        this.props.subscribeToPlanReset();
        // lets attempt to create a customer in the vault (using our custom data)
        // and then piggy-back and get the token after we tried to create the customer
        this.props.createBraintreeCustomerGetToken(user_id, f_name, l_name, email, this.state.sandbox);
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props !== nextProps) {
            // Check that the subscription was a success
            if (nextProps.subscribe.subscriptionSuccess !== this.props.subscribe.subscriptionSuccess){
                if (nextProps.subscribe.subscriptionSuccess !== null) {
                    // send the user to the thank you page...
                    this.props.navSubscriptionSuccess();
                }
            }
        }
    }

    handleSelectPlan = (planType) => {
        this.setState({
            planSelected: planType
        });
    }
    /**
     * handleBuyButton
     * 
     * 1. Request a payment nonce from braintree based on the 
     *      payment information that we have typed in.
     * 2. Receive a nonce from braintree if all is well with the payment
     *      information, and send that nonce along with the plan information
     *      to our server to make a subscription purchase.
     */
    handleBuyButton = () => {
        // 1. Request the nonce from braintree
        this.instance.requestPaymentMethod()
            .then(data => {
                const paymentNonce = data.nonce;
                if (paymentNonce) {
                    // get the plan name from the plans object
                    let planName = plans[this.state.planSelected].name;
                    // get the user id as well
                    const userId = this.props.authUser.data.user_id;
                    // now we can make the request to our server
                    // to make the purchase.
                    this.props.subscribeToPlan(userId, paymentNonce, planName);
                } else {
                    console.log('no payment nonce', data);
                }
            })
            .catch(e => {
                this.setState({
                    paymentNonce: null
                })
            });
    }

    handleRadioSelect = (plan) => {
        this.setState({
            planSelected: plan
        });
    }

    renderBraintreeUI = () => {
        let planPrice = plans[this.state.planSelected].price;
        return (
            <div className={styles.braintreeContainer}>
                <DropIn
                    options={{ 
                        authorization: this.props.clientToken,
                        // applePay: {
                        //     displayName: 'Databake LLC',
                        //     paymentRequest: {
                        //     label: 'Swingbot Pro Membership',
                        //         total: planPrice
                        //     }
                        // },
                        paypal: {
                            flow: 'checkout',
                            amount: planPrice,
                            currency: 'USD'
                        },
                        // paypalCredit: {
                        //    flow: 'checkout',
                        //    amount: '10.00',
                        //    currency: 'USD'
                        //   },
                        venmo: true
                    }}
                    onInstance={instance => (this.instance = instance)}
                />
                {this.renderBuyButton()}
            </div>
        );
    };

    renderBuyButton = () => (
        <Button 
            size="large"
            block
            className={styles.buyButton}
            onClick={() => this.handleBuyButton()}
        >
            Click to Subscribe
        </Button>
    );

    renderPriceList = () => {
        const { planSelected } = this.state;
        // loop through the plans
        return Object.keys(plans).map(planKey => {
            const plan = plans[planKey];
            const months = plan.months > 1 ? 'months': 'month';
            const planSelectedStyle = planSelected === planKey
                ? classNames(styles.cardPrice, styles.cardActive)
                : styles.cardPrice;
            return (
                <Card 
                    onClick={()=> this.handleSelectPlan(planKey)}
                    className={planSelectedStyle}
                >
                    <div className={styles.cardInnerContainer}>
                        <input
                            type="radio" 
                            name="plan" 
                            onChange={() => this.handleRadioSelect(planKey)} 
                            checked={this.state.planSelected === planKey}
                        />
                        <p className={styles.priceText}>{`${plan.months} ${months} $${plan.price}`}</p>
                    </div>
                </Card>
            );
        });
    }

    render() {
        const { planSelected } = this.state;
            {/* <Badge 
                className={styles.badgeDiscount}
                variant="success"
                size="large"
            >
                Save 59%
            </Badge> */}
        return (
            <PageContainer>
                <SwingbotHeaderClear variant="dark" />
                <SectionHeader title={'Membership'} />
                    {this.props.subscribe.subscribeLoading === false &&
                        <Container className={styles.container}>
                            <Row>
                                <Col md={6}>
                                    <h2>Choose a monthly plan</h2>
                                    <p>Join the thousands of golfers who have received swing analysis from Swingbot. Receive custom instructional videos based on your uploaded swing.</p>
                                </Col>
                                <Col>
                                    <Form>
                                        {this.renderPriceList()}
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    }
                    {this.props.subscribe.subscribeLoading === false &&
                        <Container>
                            <Row style={{ textAlign: 'right' }}>
                                <Col>
                                    {this.props.clientToken !== null && this.renderBraintreeUI()}
                                </Col>
                            </Row>
                        </Container>
                    }
                    {this.props.subscribe.subscribeLoading === true && <Loading />}
            </PageContainer>
        );
    }
}

const mapStateToProps = ({ auth, subscribe }) => {
    const { authUser } = auth;
    const { 
        clientToken,
        createBraintreeCustomerSuccess 
    } = subscribe;
    return {
      authUser,
      subscribe,
      clientToken,
      createBraintreeCustomerSuccess
    };
  };

const mapDispatchToProps = dispatch => bindActionCreators({
    createBraintreeCustomer,
    createBraintreeCustomerGetToken,
    getBraintreeClientToken,
    subscribeToPlan,
    subscribeToPlanReset,
    navSubscriptionSuccess: () => push('/cart-thankyou'),
    navAccount: () => push('/account')
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
