/**
 * AnalysisDetailReducer
 *
 * Fetch the detail of the analysis from the database
 */
import {
  GET_ANALYSIS_DETAIL,
  GET_ANALYSIS_DETAIL_ERROR,
  GET_ANALYSIS_DETAIL_SUCCESS
} from '../../actions/types'

// set the initial state of the reducer
 const INITIAL_STATE = {
   analysisData: {},
   loadingAnalysisDetail: null,
   loadingAnalysisDetailError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {

     case GET_ANALYSIS_DETAIL:
       return {
         ...state,
         analysisData: {},
         loadingAnalysisDetail: true,
         loadingAnalysisDetailError: null
       }
     break;

     case GET_ANALYSIS_DETAIL_SUCCESS:
      return {
        ...state,
        analysisData: action.payload[0],
        loadingAnalysisDetail: false,
        loadingAnalysisDetailError: null
      }
     break;

     case GET_ANALYSIS_DETAIL_ERROR:
      return {
        ...state,
        analysisData: {},
        loadingAnalysisDetail: null,
        loadingAnalysisDetailError: action.payload
      }
     break;

     default:
       return state;
   }
 };
