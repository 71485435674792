import React from 'react';
import styles from './NoResultsFound.css'
import { 
    Image, 
    Col,
    Row,
    Container 
} from 'react-bootstrap/lib';

class NoResultsFound extends React.Component {
    render() {
        return (
            <Container className={styles.noVideosContainer}>
                <Row>
                    <Col md={6}>
                        <h2>No Results Found</h2>
                        <p className={styles.subheading}>This may be due to an issue detecting a golfer in the uploaded video.</p>
                    </Col>
                    <Col md={6}>
                        <p className={styles.tips}>Here are some tips for recording your swing:</p>
                        <ul>
                            <li>Make sure your ENTIRE body is visible in the recording</li>
                            <li>Make sure you record your ENTIRE swing</li>
                            <li>Make sure you record in ample light (no crazy shadows!)</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default NoResultsFound;
