import React from 'react';

class About extends React.Component {
  render() {
    return (
      <div>about</div>
    );
  };
};

export default About;
