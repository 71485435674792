import React from 'react'
import PageContainer from '../common/container/PageContainer'
import SwingbotHeaderClear from '../common/navbar/SwingbotHeaderClear';
import SectionFreeTrial from '../About/sections/SectionFreeTrial';

/**
 * class AboutSubscription
 */
class AboutSubscription extends React.Component {
  /**
   * render
   */
  render() {
    return (
        <PageContainer title="Videos">
          {/* <div className="pageContainer"> */}
            <SwingbotHeaderClear variant="dark" />
            <SectionFreeTrial containerStyle={{ height: '100vh' }} />
        {/* </div> */}
        </PageContainer>
    )
  }
}

export default AboutSubscription;
