import {
    FEEDBACK_ANALYSIS,
    FEEDBACK_ANALYSIS_ERROR,
    FEEDBACK_ANALYSIS_SUCCESS,
    FEEDBACK_ANALYSIS_RESET
  } from '../types'
  // endpoint helpers and axios
  import { endpointForApi, headersForApi } from '../../api/swingbotApi';
  const axios = require('axios');
  /**
   * Analysis Feedback
   */
  export const submitFeedbackAnalysis = (userId, body) => {
    return (dispatch) => {
      dispatch({
        type: FEEDBACK_ANALYSIS,
        payload: { userId, ...body }
      });
      const endpoint = endpointForApi('feedback', `analysis`);
      return axios({
        url: endpoint,
        method: 'post',
        headers: headersForApi('feedback')
      })
        .then(result => {
          dispatch({
            type: FEEDBACK_ANALYSIS_SUCCESS,
            payload: result.data.data
          });
        })
        .catch(result => {
          dispatch({
            type: FEEDBACK_ANALYSIS_ERROR,
            payload: result
          });
        });
      };
    };
  
    export const submitFeedbackAnalysisReset = () => {
        return (dispatch) => {
            dispatch({
              type: FEEDBACK_ANALYSIS_RESET,
              payload: null
            });
        }
    };