/**
 * Forgot password
 */
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
} from '../../actions/types'
// set the initial state of the reducer
 const INITIAL_STATE = {
   forgotPasswordSuccess: null,
   loadingForgotPassword: false,
   loadingForgotPasswordError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     /**
      * activate user
      */
     case FORGOT_PASSWORD:
       return {
         ...state,
         forgotPasswordSuccess: null,
         loadingForgotPassword: true,
         loadingForgotPasswordError: null
       }

     case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: true,
        loadingForgotPassword: false,
        loadingForgotPasswordError: null,
      }

     case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordSuccess: false,
        loadingForgotPassword: false,
        loadingForgotPasswordError: action.payload
      }

     default:
       return state;
   }
 };
