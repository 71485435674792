/**
 * StitchActivateVideoReducer
 *
 * Activate a stitch video
 */
import {
    STITCH_ACTIVATE_VIDEO,
    STITCH_ACTIVATE_VIDEO_ERROR,
    STITCH_ACTIVATE_VIDEO_SUCCESS,
    STITCH_ACTIVATE_VIDEO_RESET
  } from '../../actions/types'
  
    // set the initial state of the reducer
    const INITIAL_STATE = {
        stitchActivateVideoSuccess: null,
        loadingStitchActivateVideo: null,
        stitchActivateVideoError: null,
    };
  
   export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      
      case STITCH_ACTIVATE_VIDEO_RESET:
         return {
           ...state,
           stitchActivateVideoSuccess: null,
           loadingStitchActivateVideo: null,
           stitchActivateVideoError: null
         }

       case STITCH_ACTIVATE_VIDEO:
         return {
           ...state,
           stitchActivateVideoSuccess: null,
           loadingStitchActivateVideo: true,
           stitchActivateVideoError: null
         }
  
       case STITCH_ACTIVATE_VIDEO_SUCCESS:
        return {
          ...state,
          stitchActivateVideoSuccess: true,
          loadingStitchActivateVideo: null,
          stitchActivateVideoError: null
        }
  
       case STITCH_ACTIVATE_VIDEO_ERROR:
        return {
          ...state,
          stitchAvailable: null,
          loadingStitchActivateVideo: null,
          stitchActivateVideoError: action.payload
        }
  
       default:
         return state;
     }
   };
  