/**
 * jwt actions
 */
const jwt = require('jsonwebtoken');
/**
 * verifyToken
 */
export const verifyToken = (token) => {
  return new Promise(function(resolve, reject){
    if (token) {
      return jwt.verify(token, process.env.REACT_APP_WWW_JWT_SECRET, function(err, decoded){
        if (err) {
          reject(err);
        } else {
          resolve(decoded);
        }
      })
    } else {
      return reject(new Error('null token'))
    }
  });
};

export const isTokenExpired = (token) => {
  return jwt.decode(token, process.env.REACT_APP_WWW_JWT_SECRET, function(err, decoded) {
    if (err) {
      // lets determine if this is a expiration error or not...
      return err;
    } else{
      return decoded;
    }
  });
}
