import React from 'react';

import PageContainer from '../common/container/PageContainer';
import Button from 'react-bootstrap/lib/Button';
import Image from 'react-bootstrap/lib/Image';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';

import { forgotPassword, loginTyping } from '../../actions'
import { FieldGroup } from '../../util/misc';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PulseLoader } from 'halogenium';
import styles from './ForgotPassword.css';

class ForgotPassword extends React.Component {
  /**
   * state to hold the form vars
   */
  state = {
    email: '',
  }

  changeEmail = (event) => {
    // reset errors...
    this.props.loginTyping();
    this.setState({
      email: event.target.value.trim(),
    })
  }
  /**
   * validate the fields
   */
  validateFields = () => {
    return (this.state.email !== '');
  }
  /**
   * submit the registration
   */
  submitForm = () => {
    const {
      email,
    } = this.state;
    this.props.forgotPassword(email);
  }
  /**
   * render the form
   */
  render() {
    const canSubmit = this.validateFields();
    const {
      forgotPasswordSuccess,
      loadingForgotPassword,
      loadingForgotPasswordError
    } = this.props;
    const validationState = loadingForgotPasswordError !== null ? 'error' : null;
    return(
      <PageContainer header={false}>
      <div className={styles.pageContainer}>
        <div className={styles.innerLoginContainer}>
          <div>
            <div className={styles.topHeader}>
              <div>
                <Image
                  src={require('../../assets/images/swingbot_logo.png')}
                  width={60}
                  height={60}
                  className="center-block"
                />
              </div>
              <h2>Everyone forgets</h2>
              <p className={styles.subHeading}>Request a password reset</p>
            </div>
            { loadingForgotPassword === false && forgotPasswordSuccess === null &&
              <div className={styles.bottomLoginContainer}>
              <Form vertical>
                <Col sm={12}>
                  <FieldGroup
                    id="formControlsEmail"
                    type="email"
                    label="Email"
                    placeholder="Email address"
                    onChange={this.changeEmail}
                    value={this.state.email}
                    autoFocus
                    validationState={validationState}
                  />
                </Col>
                <Col sm={12}>
                  <FormGroup>
                    <Button
                      disabled={!canSubmit}
                      block
                      bsSize={'large'}
                      bsStyle={'login'}
                      onClick={this.submitForm}
                    >
                        Click to Submit Request
                    </Button>
                  </FormGroup>
                </Col>
              </Form>
              <Col sm={12}>
                <hr style={{ borderColor: '#CCCCCC' }}/>
                <div className={styles.bottomContainer}>
                  Already a member? <a href="/login">Sign in</a>
                </div>
              </Col>
            </div>
            }
            { loadingForgotPassword === true &&
              <div style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}>
                <div style={{ textAlign: 'center' }}>
                  <PulseLoader color="green" size="16px" margin="4px" />
                </div>
              </div>
            }
            { loadingForgotPassword === false && forgotPasswordSuccess === true &&
              <div style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                height: '100%',
                width: '100%'
              }}>
                <div style={{ textAlign: 'center' }}>
                  <h4>Sent!</h4>
                </div>
                <Col sm={12}>
                  <hr style={{ borderColor: '#CCCCCC' }}/>
                  <div className={styles.bottomContainer}>
                    <a href="/login">Back to Login</a>
                  </div>
                </Col>
              </div>
            }
          </div>
        </div>
      </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = ({ forgotPassword }) => {
  const {
    forgotPasswordSuccess,
    loadingForgotPassword,
    loadingForgotPasswordError,
  } = forgotPassword;
  return {
    forgotPasswordSuccess,
    loadingForgotPassword,
    loadingForgotPasswordError,
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  forgotPassword,
  loginTyping
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
