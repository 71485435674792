import React from 'react';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';

export const FieldGroup = ({ id, help, ...props }) => (
   <FormGroup controlId={id} validationstate={props.validationstate}>
     <FormControl {...props} />
     {help && <p>{help}</p>}
     {props.validationstate === 'error' && <FormControl.Feedback />}
   </FormGroup>
)

export const FieldGroupWithLabel = ({ id, help, ...props }) => (
  <FormGroup controlId={id} validationstate={props.validationstate}>
    <p>{props.label}</p>
    <FormControl {...props} />
    {help && <p>{help}</p>}
    {props.validationstate === 'error' && <FormControl.Feedback />}
  </FormGroup>
)

export const colorForScore = (score) => {
  let color = 'gray';
  if (score > 0) {
    color = 'red';
  }
  if (score > 60) {
    color = 'orange';
  }
  if (score > 70) {
    color = 'blue';
  }
  if (score > 80) {
    color = 'light-green';
  }
  if (score > 90) {
    color = 'green';
  }
  return color;
};
