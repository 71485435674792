import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
//import { AsyncStorage } from 'react-native';
import { routerMiddleware } from 'react-router-redux'
import { tokenMiddlewareCheck } from './middleware/token'
import { analyticsGTM } from './middleware/googleTagManager';
//import { composeWithDevTools } from 'redux-devtools-extension';
import { reducers } from './reducers';
// Create the browser history for routing
import createHistory from 'history/createBrowserHistory'
const history = createHistory()
const historyRouterMiddleware = routerMiddleware(history);
const config = {
  key: 'root',
  storage,
  blacklist: ['historyRouterMiddleware']
};

const reducersCombined = persistCombineReducers(config, reducers);

const middleware = [
  tokenMiddlewareCheck,
  analyticsGTM,
  thunk,
  historyRouterMiddleware
]

export const configureStore = () => {
  const store = createStore(
    reducersCombined,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(...middleware),
  );
  const persistor = persistStore(store);
  return { persistor, store, history };
};
