import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import PageContainer from '../common/container/PageContainer'
import { getAnalysisResultsByVideoId } from '../../actions';
import AnalysisResultPanel from './AnalysisResultPanel';
import Loading from '../common/Loading'
import { isPassing } from '../../util/strings';

import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Container from 'react-bootstrap/lib/Container';

import BreadcrumbTrail from 'components/common/BreadcrumbTrail';
import SwingbotHeaderClear from '../common/navbar/SwingbotHeaderClear';
import SectionHeader from '../common/header/SectionHeader';
import NoResultsFound from '../common/NoResultsFound';  
import AnalysisError from '../common/AnalysisError';  
import SectionVideoPlayer from '../Videos/SectionVideoPlayer';
import ReactPlayer from 'react-player';
import styles from './AnalyzeVideoDetail.css';
/**
 * class AnalyzeVideoDetail
 */
class AnalyzeVideoDetail extends React.Component {
  /**
   * mount
   */
  componentDidMount = () => {
    // lets fetch the Analysis
    this.props.getAnalysisResultsByVideoId(this.props.match.params.videoId);
  };
  /**
   * render the Analysis as panels
   *
   * We should loop through each of the keys and
   * render a separate panel (AnalysisResultPanel)
   *
   * IF the keys do not equal frame_images, camera_angle, etc...
   */
  renderAnalysisPanelDetail = () => {
    const { analysisResults } = this.props;
    // const { analysis_data, credit_data } = analysisResults;
    return Object.keys(analysisResults).length > 0 ? Object.keys(analysisResults)
      .filter(key => {
        // check the keys...
        return (
          key !== 'camera_angle' &&
          key !== 'frame_images' &&
          key !== 'success' &&
          key !== 'credit_data' &&
          key !== 'analysis_data' &&
          key !== 'frames' &&
          key !== 'points' &&
          key !== 'tempo'
        );
      })
      .map(key => {
        const {
          code,
          def,
          msg,
          video_id,
          target_data,
          target_summary_id,
          image_lines_url_png,
          probability_pass,
          probability_low,
          // equation_results
        } = analysisResults[key];
        const panelStyle = isPassing(code) ? 'success' : 'danger';
        return (
          <AnalysisResultPanel
            key={key}
            panelHeader={target_data.display_name}
            panelStyle={panelStyle}
            data={{
              code,
              def,
              msg,
              video_id,
              display_name: target_data.display_name,
              score: target_data.percent_score,
              image: image_lines_url_png,
              target_summary_id,
              // target_id: target_data.target_id,
              // licensee_credit_id: credit_data.licensee_credit_id,
              // analysis_id: analysis_data.analysis_id,
              probability_low,
              probability_pass 
              // calculation_1_pseudo: equationResultsForCode.equation_result.calculation_1_pseudo,
              // calculation_1_result: JSON.stringify(equationResultsForCode.calculation_1_result),
              // calculation_2_pseudo: equationResultsForCode.equation_result.calculation_2_pseudo,
              // calculation_2_result: JSON.stringify(equationResultsForCode.calculation_2_result)
              //equationResults: equationResultsForCode.equation_result
            }}
          />
        );
      }) : <NoResultsFound />;
  };
  /**
   * render breadcrumbs
   */
  renderBreadcrumbs = () => {
    return this.props.analysisResults !== null ? (
      <BreadcrumbTrail
        crumbs={[
          {
            title: 'Home',
            href: '/',
            active: false
          },
          {
            title: 'Videos',
            href: '/video',
            active: false
          },
          {
            title: 'Analysis Results',
            href: null,
            active: true
          },
        ]}
      />
    ) : null;
  };
  /**
   * render
   */
  render() {
    const { analysisResults, loadingAnalyzeVideoDetailError } = this.props;
    return (
      <PageContainer>
        <SwingbotHeaderClear variant="dark" />
        <SectionHeader 
          title={'Analysis Result'} 
          description={'The results of the Swingbot video processing.'}
        />
        {analysisResults !== null && loadingAnalyzeVideoDetailError === null && 
          <Container>
            <Row>
              <Col>
                {/* <div className={styles.videoContainer}>
                  <ReactPlayer 
                    url={this.props.analysisResults.analysis_data.videoData.zen_mp4}
                    controls
                    className={styles.videoContainer}
                  />
                </div> */}
              </Col>
            </Row>
          </Container>
        }
        {analysisResults !== null && loadingAnalyzeVideoDetailError === null && (
          <Container>
            <Row>
              <Col>
                {this.renderAnalysisPanelDetail()}
              </Col>
            </Row>
          </Container>
        )}
        {analysisResults === null && loadingAnalyzeVideoDetailError === null && <Loading />}
        { loadingAnalyzeVideoDetailError !== null && (
          <Container>
            <Row>
              <Col>
                <AnalysisError />
              </Col>
            </Row>
          </Container>
        )}
      </PageContainer>
    );
  }
}

const mapStateToProps = ({ analyzeVideoDetail }) => {
  const { 
    analysisResults, 
    loadingAnalyzeVideoDetail,
    loadingAnalyzeVideoDetailError
  } = analyzeVideoDetail;
  return { 
    analysisResults, 
    loadingAnalyzeVideoDetail,
    loadingAnalyzeVideoDetailError
  };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        getAnalysisResultsByVideoId,
        backToAnalysis: () => push('/analyses'),
        backToAnalysisWithId: analysisId => push('/analyses/' + analysisId)
    },dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzeVideoDetail);
