import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'

import Image from 'react-bootstrap/lib/Image';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import DropdownItem from 'react-bootstrap/lib/DropdownItem';

import styles from './SwingbotHeader.css';
import {
  logout
} from '../../../actions'

class SwingbotHeader extends React.Component {
  constructor(props) {
    super(props);
    // this.toggle = this.toggleNavbar.bind(this);
    if (props.autohide === true) {
      // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
      if (typeof window !== 'undefined') {
        let prevScrollpos = window.pageYOffset;
        window.onscroll = function () {
          const maxScroll = document.body.clientHeight - window.innerHeight;
          let currentScrollPos = window.pageYOffset;
          if (
              (maxScroll > 0 && prevScrollpos > currentScrollPos && prevScrollpos <= maxScroll) 
            || (maxScroll <= 0 && prevScrollpos > currentScrollPos)
            || (prevScrollpos <= 0 && currentScrollPos <= 0)
            ) {
            document.getElementById("navbar").style.top = "-60px";
          } else {
            document.getElementById("navbar").style.top = "0"; // adjustable based your need
          }
          prevScrollpos = currentScrollPos;
        }
      }
    }
  }

  render() {
    return(
      <Navbar fixedTop fluid expanded={false} id="navbar">
        <Navbar.Header>
          <div className={styles.mainContainer}>
            <div className={styles.logo}>
              <Image
                src={require('../../../assets/images/swingbot_logo.png')}
                height={32}
                width={32}
              />
              <h4 style={{ marginLeft: '5px', fontSize: '20px' }}>Swingbot</h4>
            </div>
          </div>
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav className={styles.navigationItems} pullRight>
            <DropdownItem href="/video">Videos</DropdownItem>
            <DropdownItem href="https://swingbot.uservoice.com">Support</DropdownItem>
            <Nav.Item eventKey={2} onClick={() => {
                this.props.logout();
              }}>
      				Logout
      			</Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

SwingbotHeader.propTypes = {
  autohide: PropTypes.bool,
  fixed: PropTypes.bool,
};

SwingbotHeader.defaultProps = {
  autohide: false,
  fixed: true,
};

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
}
const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  navHome: () => push('/'),
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SwingbotHeader)
