import React from 'react';
import Section from '../../common/container/Section';
import { colors } from '../../../styles';
import Image from 'react-bootstrap/lib/Image';
import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import styles from './SectionSampleImages.css';

const sample1 = require('../../../assets/images/samples/28_backswing.png');
const sample2 = require('../../../assets/images/samples/63_address.png');
const sample3 = require('../../../assets/images/samples/75_backswing.png');
const sample4 = require('../../../assets/images/samples/110_at_the_top.png');


class SectionSampleImages extends React.Component {

    render() {
        return (
            <Section
                name="sample"
                containerStyle={{
                    backgroundColor: colors.darkGray
                }}
                style={{
                    backgroundColor: colors.darkGray,
                    width: '100%',
                    color: colors.white,
                }}
            >
                <Container className={styles.gridContainer}>
                    <Row className={styles.sampleImageRow}>
                        <Col lg={12} style={{ textAlign: 'center' }}>
                            <h1>The Future is Now</h1>
                            <p>Swingbot can see what humans can't. We use Artificial Intelligence (AI) to detect your body points and positions, and then analyze those results using trusted PGA Instruction.</p>
                            <p>Here are just a few samples that were generated by Swingbot.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={true} className={styles.sampleImageRow}>
                            <Image src={sample1} rounded fluid />
                        </Col>
                        <Col lg={true} className={styles.sampleImageRow}>
                            <Image src={sample2} rounded fluid />
                        </Col>
                        <Col lg={true} className={styles.sampleImageRow}>
                            <Image src={sample3} rounded fluid />
                        </Col>
                        <Col lg={true} className={styles.sampleImageRow}>
                            <Image src={sample4} rounded fluid />
                        </Col>
                        {/* <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample1} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample2} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample3} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample4} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample1} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample2} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample3} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample4} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample1} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample2} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample3} responsive rounded />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={12} className={styles.sampleImageRow}>
                            <Image src={sample4} responsive rounded />
                        </Col> */}
                    </Row>
                </Container>
            </Section>
        );
    }
}

export default SectionSampleImages;

