import React from 'react';
import queryString from 'query-string';
import PageContainer from '../common/container/PageContainer';
import Image from 'react-bootstrap/lib/Image';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Row from 'react-bootstrap/lib/Row';
import { GoogleLogin } from 'react-google-login'
import { googleVerifyToken, register, registerReset } from '../../actions'
import { FieldGroup } from '../../util/misc';
import { PulseLoader } from 'halogenium';
import { redirectFromQueryString, hasQueryString } from '../../util/redirect';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { capitalizeFirstLetter } from '../../util/strings';
import { completeRegistration } from '../../actions/User';
import { getInvitationByNonce } from '../../actions/Connection';

import styles from './RegisterAccount.css'

class RegisterAccount extends React.Component {
  /**
   * state to hold the form vars
   */
  state = {
    email: '',
    emailConfirm: '',
    password: '',
    firstName: '',
    lastName: ''
  }

  componentDidMount = () => {
    // parse the query string first...
    const query = queryString.parse(this.props.location.search);
    this.props.registerReset();
    // let's fetch the invitation request by nonce if there is a cr in the query string...
    if (query['cr']) {
      // fetching the invitation data
      this.props.getInvitationByNonce(query.cr);
    }
  }

  changeEmail = (event) => {
    this.setState({
      email: event.target.value.trim(),
    })
  }

  changeEmailConfirm = (event) => {
    this.setState({
      emailConfirm: event.target.value.trim(),
    })
  }

  changeFirstName = (event) => {
    this.setState({
      firstName: capitalizeFirstLetter(event.target.value.trim()),
    })
  }

  changeLastName = (event) => {
    this.setState({
      lastName: capitalizeFirstLetter(event.target.value).trim(),
    })
  }

  changePassword = (event) => {
    this.setState({
      password: event.target.value.trim(),
    })
  }
  /**
   * validate the fields
   */
  validateFields = (completeRegistrationForm) => {
    return completeRegistrationForm === false ? (
      this.state.email !== '' &&
      this.state.emailConfirm !== '' &&
      this.state.firstName !== '' &&
      this.state.lastName !== '' &&
      this.state.password !== '' &&
      this.state.password.length > 4
    ) : (
      this.state.password !== '' &&
      this.state.password.length > 4
    );
  }
  /**
   * submit the registration
   */
  submitForm = () => {
    const {
      email,
      emailConfirm,
      password,
      firstName,
      lastName
    } = this.state;
    // get the redirect if any...
    const redirect = redirectFromQueryString(this.props.location.search);
    this.props.register(
      email,
      emailConfirm,
      password,
      firstName,
      lastName,
      redirect
    );
  }
  /**
   * complete the user registration 
   * using the password that the user chooses.
   */
  submitCompleteRegistration = () => {
    // we need to get thte password from the 
    // state, and the rest comes from the invitationData
    const { invitee_email } = this.props.invitationData;
    console.log(invitee_email, this.state.password);
    // this.props.completeRegistration(invitee_email, this.state.password);
  }

  /**
   * get the response from the google login
   */
  handleGoogleLogin = (response) => {
    if ('tokenId' in response) {
      // lets get the token from the google login, send it to our server and decode it
      // we have to send the token to our server and verify the token
      // as well as handle any register/login for the user...
      // the end result in both cases should be a userData object
      this.props.googleVerifyToken(response.tokenId);
    } else {
      // no token folks...
    }
  }
  renderRegistrationError = () => {
    const { loadingRegisterUserError } = this.props;
    return loadingRegisterUserError !== null ? (
      <div>
        <p className={styles.registerError}>{loadingRegisterUserError}</p>
      </div>
    ) : null;
  }

  renderForm = () => {
    return (
      <Col sm={12}>
        <FieldGroup
          id="formControlsFirstName"
          autoFocus
          type="text"
          label="First name"
          placeholder="First name"
          onChange={this.changeFirstName}
          value={this.state.firstName}
        />
        <FieldGroup
          id="formControlsLastName"
          type="text"
          label="Last name"
          placeholder="Last name"
          onChange={this.changeLastName}
          value={this.state.lastName}
        />
        <FieldGroup
          id="formControlsEmail"
          type="email"
          label="Email address"
          placeholder="Email address"
          onChange={this.changeEmail}
          value={this.state.email}
        />
        <FieldGroup
          id="formControlsConfirmEmail"
          type="email"
          label="Confirm Email address"
          placeholder="Confirm email address"
          onChange={this.changeEmailConfirm}
          value={this.state.emailConfirm}
        />
        <FieldGroup
          id="formControlsPassword"
          type="password"
          label="Choose a password"
          placeholder="Password"
          onChange={this.changePassword}
          value={this.state.password}
        />
      </Col>
    );
  }

  renderCompleteRegistrationForm = () => {
    const {
      invitee_email,
      invitee_f_name,
      invitee_l_name,
    } = this.props.invitationData;
    return this.props.invitationData !== null ? (
      <Col sm={12}>
        <FieldGroup
          id="formControlsFirstName"
          autoFocus
          type="text"
          label="First name"
          placeholder="First name"
          onChange={this.changeFirstName}
          value={invitee_f_name}
          readOnly
        />
        <FieldGroup
          id="formControlsLastName"
          type="text"
          label="Last name"
          placeholder="Last name"
          onChange={this.changeLastName}
          value={invitee_l_name}
          readOnly
        />
        <FieldGroup
          id="formControlsEmail"
          type="email"
          label="Email address"
          placeholder="Email address"
          onChange={this.changeEmail}
          value={invitee_email}
          readOnly
        />
        <FieldGroup
          id="formControlsPassword"
          type="password"
          label="Choose a password"
          placeholder="Password"
          onChange={this.changePassword}
          value={this.state.password}
        />
      </Col>
    ) : (
      <PulseLoader />
    );
  }
  renderSubmitButton = (completeRegisration, canSubmit) => {
    return completeRegisration === false ? (
      <FormGroup>
        <Button
          disabled={!canSubmit}
          block
          size={'large'}
          onClick={this.submitForm}
        >
            Click to create your account
        </Button>
      </FormGroup>
    ) : (
      <FormGroup>
        <Button
          disabled={!canSubmit}
          block
          size={'large'}
          onClick={this.submitCompleteRegistration}
        >
            Click to complete registration
        </Button>
      </FormGroup>
    );
  }
  /**
   * render the form
   */
  render() {
    const completeRegistrationForm = hasQueryString(this.props.location.search);
    const title = completeRegistrationForm === true
      ? 'Complete Registration'
      : 'Become a Member';
    const canSubmit = this.validateFields(completeRegistrationForm);
    return(
      <PageContainer header={false}>
        <div className={styles.pageContainer}>
          <div className={styles.innerRegisterContainer}>
            <div>
              <div className={styles.topHeader}>
                <div>
                  <Image
                    src={require('../../assets/images/swingbot_logo.png')}
                    width={60}
                    height={60}
                    className="center-block"
                  />
                </div>
                <h2>{title}</h2>
                {this.renderRegistrationError()}
              </div>
              <Form>
                {completeRegistrationForm === false && this.renderForm()}
                {completeRegistrationForm === true && this.props.invitationData !== null && this.renderCompleteRegistrationForm()}
                <Row>
                  <Col>
                    <p className={styles.registerText}>By registering with Swingbot, you agree to our <a href="/terms">Terms and Conditions</a></p>
                  </Col>
                </Row>
                <Col sm={12}>
                  {this.renderSubmitButton(completeRegistrationForm, canSubmit)}
                </Col>
              </Form>
              <hr />
              <Col sm={12}>
                <div className={styles.googleContainer}>
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Continue with Google"
                    onSuccess={this.handleGoogleLogin}
                    onFailure={this.handleGoogleLogin}
                    style={{
                      height: '44px',
                      display: 'inline-block',
                      color: '#FFF',
                      width: '100%',
                      borderRadius: '5px',
                      backgroundColor: '#FF0000',
                      whiteSpace: 'nowrap',
                      fontFamily: 'Roboto, sans-serif',
                      border: 'none',
                      fontSize: '18px',
                    }}
                  />
                </div>
              </Col>
              <Col sm={12}>
                <div className={styles.bottomContainer}>
                  Already a member? <a href="/login">Sign in</a>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}
const mapStateToProps = ({ auth, registerAccount, connection }) => {
  const {
    token,
    authUser
  } = auth;
  const {
    getInvitationDataLoading,
    getInvitationDataError,
    invitationData,
    denyInvitationLoading,
    denyInvitationError,
    denyInvitationSuccess,
    acceptInvitationLoading,
    acceptInvitationError,
    acceptInvitationSuccess,
  } = connection;
  const {
    loadingRegisterUser,
    loadingRegisterUserError,
  } = registerAccount;
  const { googleVerifyTokenData } = googleVerifyToken;
  return {
    token,
    authUser,
    loadingRegisterUser,
    loadingRegisterUserError,
    googleVerifyTokenData,
    getInvitationDataLoading, // invitations...
    getInvitationDataError,
    invitationData,
    denyInvitationLoading,
    denyInvitationError,
    denyInvitationSuccess,
    acceptInvitationLoading,
    acceptInvitationError,
    acceptInvitationSuccess,
  };
}
const mapDispatchToProps = dispatch => bindActionCreators({
  register,
  registerReset,
  googleVerifyToken,
  getInvitationByNonce,
  completeRegistration
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(RegisterAccount)
