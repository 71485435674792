/**
 * Submit a reset password request
 */
import {
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_CLEAR,
} from '../types'
// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');
/**
 * reset password request
 */
export const resetPassword = (password, passwordConfirm, code) => {
  return(dispatch) => {
    dispatch({
      type: RESET_PASSWORD,
      payload: null
    });
    var body = { password, passwordConfirm, nonce: code };
    return axios({
      method: 'post',
      data: body,
      url: endpointForApi('www', 'account/reset'),
      headers: headersForApi('www')
    })
      .then(result => dispatch(resetPasswordSuccess(result)))
      .catch(result => dispatch(resetPasswordFailure(result)));
    };
  }; // end activate

const resetPasswordSuccess = (result) => {
  return (dispatch) => {
    return dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: result
    });
  }
}

const resetPasswordFailure = (result) => {
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_ERROR,
      payload: result
    });
  }
}

export const resetPasswordClear = () => dispatch =>
  dispatch({ type: RESET_PASSWORD_CLEAR });
