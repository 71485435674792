exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Footer__footer--28TRy {\n    /* position: absolute; */\n    bottom: 0;\n    left: 0;\n    display: flex;\n    flex: 1;\n    justify-content: center;\n    width: 100%;\n    height: 60px;\n    line-height: 60px;\n    background-color: #222222;\n}\n\n.Footer__copyright--2ENw4 {\n    font-size: 14px;\n    color: #CCCCCC;\n}\n\n.Footer__link--3PVAC {\n    color: #CCCCCC;\n    text-decoration: underline;\n}\n\n.Footer__link--3PVAC:hover {\n    color: #EEEEEE;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/common/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,UAAU;IACV,QAAQ;IACR,cAAc;IACd,QAAQ;IACR,wBAAwB;IACxB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,0BAA0B;CAC7B;;AAED;IACI,gBAAgB;IAChB,eAAe;CAClB;;AAED;IACI,eAAe;IACf,2BAA2B;CAC9B;;AAED;IACI,eAAe;CAClB","file":"Footer.css","sourcesContent":[".footer {\n    /* position: absolute; */\n    bottom: 0;\n    left: 0;\n    display: flex;\n    flex: 1;\n    justify-content: center;\n    width: 100%;\n    height: 60px;\n    line-height: 60px;\n    background-color: #222222;\n}\n\n.copyright {\n    font-size: 14px;\n    color: #CCCCCC;\n}\n\n.link {\n    color: #CCCCCC;\n    text-decoration: underline;\n}\n\n.link:hover {\n    color: #EEEEEE;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "Footer__footer--28TRy",
	"copyright": "Footer__copyright--2ENw4",
	"link": "Footer__link--3PVAC"
};