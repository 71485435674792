import React from 'react';
import SectionWithImage from '../../common/container/SectionWithImage';
import { colors } from '../../../styles';

import Button from 'react-bootstrap/lib/Button';
import Image from 'react-bootstrap/lib/Image';
import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import styles from './SectionFreeTrial.css';

const SectionFreeTrial = (props) => (
    <SectionWithImage
        name="trial"
        className={styles.containerFreeTrial}
        style={{
            backgroundColor: 'transparent',
            color: colors.white,
            textAlign: 'center',
            height: props.containerStyle.height
        }}
    >
        <Container>
            <Row>
                <Col>
                    <h1 className={styles.freeTrialHeader}>Become a Member</h1>
                    <p className={styles.freeTrialSubheader}>Unlimited Swing Analysis</p>
                    <p className={styles.freeTrialDescription}>Get your swing analyzed anytime, anywhere using your mobile device.</p>
                    <br />
                    <Button 
                        className={styles.freeTrialButton} 
                        variant="success" 
                        size="large"
                        onClick={() => props.props.navRegister()}
                    >
                        Click to get started
                    </Button>
                    <br />
                    <div className={styles.badgeContainer}>
                        <a href="https://itunes.apple.com/us/app/swingbot-golf-swing-video/id516200328?mt=8">
                            <Image
                                className={styles.appStoreBadge}
                                src={require('../../../assets/images/app-store-logo.png')}
                            />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.databake.swingbot">
                            <Image
                                className={styles.appStoreBadge}
                                src={require('../../../assets/images/google_web.png')}
                            />
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    </SectionWithImage>
);

SectionFreeTrial.defaultProps = {
    containerStyle: {
        height: undefined
    }
}

export default SectionFreeTrial;
