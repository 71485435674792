exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".MyAccount__subscriptionRow--6slN9 {\n    margin-bottom: 20px;\n}\n\n.MyAccount__subscriptionList--2bgiQ {\n    margin-bottom: 10px;\n}\n\n.MyAccount__subscriptionHeader--paQs2 {\n    font-weight: bold;\n}\n\n.MyAccount__subscriptionFooter--3o2ey {\n    text-align: right;\n}", "", {"version":3,"sources":["/Users/trops/Development/react_projects/swingbot-reactjs/website/swingbot-www/src/components/Account/MyAccount.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;CACvB;;AAED;IACI,oBAAoB;CACvB;;AAED;IACI,kBAAkB;CACrB;;AAED;IACI,kBAAkB;CACrB","file":"MyAccount.css","sourcesContent":[".subscriptionRow {\n    margin-bottom: 20px;\n}\n\n.subscriptionList {\n    margin-bottom: 10px;\n}\n\n.subscriptionHeader {\n    font-weight: bold;\n}\n\n.subscriptionFooter {\n    text-align: right;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"subscriptionRow": "MyAccount__subscriptionRow--6slN9",
	"subscriptionList": "MyAccount__subscriptionList--2bgiQ",
	"subscriptionHeader": "MyAccount__subscriptionHeader--paQs2",
	"subscriptionFooter": "MyAccount__subscriptionFooter--3o2ey"
};