import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import PageContainer from '../common/container/PageContainer'
import { getAnalysisResultsByVideoId } from '../../actions';
import AnalysisResultPanel from '../Analysis/AnalysisResultPanel';
import Loading from '../common/Loading'
import { isPassing } from '../../util/strings';

// import Breadcrumb from 'react-bootstrap/lib/Breadcrumb';
import Col from 'react-bootstrap/lib/Col';
import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';

import SwingbotHeader from '../common/navbar/SwingbotHeader';
/**
 * class Lab
 */
class Lab extends React.Component {
  /**
   * mount
   */
  componentDidMount = () => {
    // lets fetch the Analysis
    this.props.getAnalysisResultsByVideoId(this.props.match.params.videoId);
  };
  /**
   * render the Analysis as panels
   *
   * We should loop through each of the keys and
   * render a separate panel (AnalysisResultPanel)
   *
   * IF the keys do not equal frame_images, camera_angle, etc...
   */
  renderAnalysisPanelDetail = () => {
    const { analysisResults } = this.props;
    console.log(analysisResults.points);
    console.log(analysisResults.frame_images);
    const { analysis_data, credit_data } = analysisResults;
    return Object.keys(analysisResults).length > 0 ? Object.keys(analysisResults)
      .filter(key => {
        // check the keys...
        return (
          key !== 'camera_angle' &&
          key !== 'frame_images' &&
          key !== 'success' &&
          key !== 'credit_data' &&
          key !== 'analysis_data' &&
          key !== 'frames' &&
          key !== 'points' && 
          key !== 'tempo'
        );
      })
      .map(key => {
        const {
          code,
          def,
          msg,
          video_id,
          target_data,
          target_summary_id,
          image_lines_url_png,
          probability_pass,
          probability_low,
          equation_results
        } = analysisResults[key];
        const panelStyle = isPassing(code) ? 'success' : 'danger';
        return (
          <AnalysisResultPanel
            key={key}
            panelHeader={target_data.display_name}
            panelStyle={panelStyle}
            data={{
              code,
              def,
              msg,
              video_id,
              display_name: target_data.display_name,
              score: target_data.percent_score,
              image: image_lines_url_png,
              target_summary_id,
              // target_id: target_data.target_id,
              // licensee_credit_id: credit_data.licensee_credit_id,
              // analysis_id: analysis_data.analysis_id,
              probability_low,
              probability_pass 
              // calculation_1_pseudo: equationResultsForCode.equation_result.calculation_1_pseudo,
              // calculation_1_result: JSON.stringify(equationResultsForCode.calculation_1_result),
              // calculation_2_pseudo: equationResultsForCode.equation_result.calculation_2_pseudo,
              // calculation_2_result: JSON.stringify(equationResultsForCode.calculation_2_result)
              //equationResults: equationResultsForCode.equation_result
            }}
          />
        );
      }) : (
        <div className="noVideosContainer">
            <h2>No Results Found</h2>
            <p className="subheading">This may be due to an issue detecting a golfer in the uploaded video.</p>
        </div>
      );
  };
  /**
   * render breadcrumbs
   */
  renderBreadcrumbs = () => {
    return this.props.analysisResults !== null ? (
      null
      // <Breadcrumb>
      //   <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      //   <Breadcrumb.Item href="/video">Videos</Breadcrumb.Item>
      //   <Breadcrumb.Item active>Analysis Results</Breadcrumb.Item>
      // </Breadcrumb>
    ) : null;
  };
  /**
   * render
   */
  render() {
    return (
        <div className="mainStitchContainer">
        <PageContainer>
          <div className="pageContainer">
            <SwingbotHeader />
            {this.props.analysisResults !== null && (
            <Container style={{ marginTop: 20 }} fluid>
                <Row>
                    <Col sm={12}>
                        {this.renderBreadcrumbs()}
                        {this.renderAnalysisPanelDetail()}
                    </Col>
                </Row>
            </Container>
            )}
            {this.props.analysisResults === null && <Loading />}
        </div>
      </PageContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ analyzeVideoDetail }) => {
  const { analysisResults, loadingAnalyzeVideoDetail } = analyzeVideoDetail;
  return { analysisResults, loadingAnalyzeVideoDetail };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        getAnalysisResultsByVideoId,
        backToAnalysis: () => push('/analyses'),
        backToAnalysisWithId: analysisId => push('/analyses/' + analysisId)
    },dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Lab);
