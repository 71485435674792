import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'

import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import Container from 'react-bootstrap/lib/Container';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import styles from './SwingbotHeaderClear.css'
import { logout } from '../../../actions'

import Image from 'react-bootstrap/lib/Image';

// images
import logo from 'assets/images/swingbot_logo.png';

class SwingbotHeaderClear extends React.Component {

  handleLogout = () => this.props.logout();

  renderLoggedInNav = () => {
    const name = this.props.authUser.data.f_name;
    return (
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav.Link href="/account">Welcome, {name}</Nav.Link>
          <Nav.Link href="/video">Video Library</Nav.Link>
          {/* <Nav.Link onClick={() => this.props.logout()}>Logout</Nav.Link> */}
        </Nav>
      </Navbar.Collapse>
    )
  }

  renderTrialNav = () => (
    <Nav>
      <Nav.Link href="#howitworks">Features</Nav.Link>
      <Nav.Link href="#sample">Samples</Nav.Link>
      <Nav.Link href="#pricing">Pricing</Nav.Link>
      <Nav.Link href="#trial">Membership</Nav.Link>
      <Nav.Link href="/login">Login</Nav.Link>
    </Nav>
  )

  render() {
    return (
        <Container className={styles.container} fluid>
          <Row>
            <Col>
            <Navbar expand="lg" className={styles.clear} variant={this.props.variant}>
              <Navbar.Brand bg="dark" variant="dark">
                <Image
                  alt=""
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />
                {' Swingbot'}
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                  {this.props.authUser &&
                    <Nav>
                      <Nav.Link href="/video">Video Uploads</Nav.Link>
                      <Nav.Link href="/instruction">Instruction</Nav.Link>
                      <Nav.Link href="/connection">Connections</Nav.Link>
                      <Nav.Link href="/account">Account</Nav.Link>
                      <Nav.Link 
                        target="_blank" 
                        href="http://swingbot.uservoice.com">
                          Support
                      </Nav.Link>
                      <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
                    </Nav>
                  }
                  { !this.props.authUser && 
                    <Nav className={styles.loggedOut}>
                      <Nav.Link href="#howitworks">Features</Nav.Link>
                      <Nav.Link href="#sample">Samples</Nav.Link>
                      {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
                      <Nav.Link href="#trial">Membership</Nav.Link>
                      <Nav.Link 
                        target="_blank" 
                        href="http://swingbot.uservoice.com">
                          Support
                      </Nav.Link>
                      <Nav.Link href="/login">Login</Nav.Link>
                    </Nav>
                  }
                </Navbar.Collapse>
            </Navbar>
            </Col>
          </Row>
        </Container>
    )
  }
}

SwingbotHeaderClear.propTypes = {
  autohide: PropTypes.bool,
  fixed: PropTypes.bool,
  variant: PropTypes.string
};

SwingbotHeaderClear.defaultProps = {
  autohide: false,
  fixed: true,
  variant: 'dark'
};

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SwingbotHeaderClear);
