/**
 * Reset password
 */
import {
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_CLEAR
} from '../../actions/types'
// set the initial state of the reducer
 const INITIAL_STATE = {
   resetPasswordSuccess: null,
   loadingResetPassword: false,
   loadingResetPasswordError: null,
 };

 export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
     /**
      * activate user
      */
     case RESET_PASSWORD:
       return {
         ...state,
         resetPasswordSuccess: null,
         loadingResetPassword: true,
         loadingResetPasswordError: null
       }

     case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: true,
        loadingResetPassword: false,
        loadingResetPasswordError: null,
      }

     case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordSuccess: false,
        loadingResetPassword: false,
        loadingResetPasswordError: action.payload
      }

    case RESET_PASSWORD_CLEAR:
       return {
         ...state,
         resetPasswordSuccess: null,
         loadingResetPassword: false,
         loadingResetPasswordError: null
       }

     default:
       return state;
   }
 };
