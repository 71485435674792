import React from 'react';
import styles from './Footer.css';

const Footer = (props) => (
    <div className={styles.footer}>
        <p className={styles.copyright}>
            Copyright 2010-2019, Databake, LLC.&nbsp;
            <a className={styles.link} href="/terms">Terms of Use</a>&nbsp;
            <a className={styles.link} href="/privacy">Privacy Policy</a>
        </p>
    </div>
);

export default Footer;
