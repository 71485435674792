import {
    FEEDBACK_GENERAL,
    FEEDBACK_GENERAL_ERROR,
    FEEDBACK_GENERAL_SUCCESS,
    FEEDBACK_GENERAL_RESET
  } from '../types'
  // endpoint helpers and axios
  import { endpointForApi, headersForApi } from '../../api/swingbotApi';
  const axios = require('axios');
  /**
   * General Feedback
   */
  export const submitFeedbackGeneral = (userId, formData) => {
    return (dispatch) => {
      dispatch({
        type: FEEDBACK_GENERAL,
        payload: { userId, ...formData }
      });
      const body = { userId, ...formData };
      const endpoint = endpointForApi('feedback', `feedback/general`);
      return axios({
        url: endpoint,
        method: 'post',
        headers: headersForApi('feedback'),
        data: body
      })
        .then(result => {
          dispatch({
            type: FEEDBACK_GENERAL_SUCCESS,
            payload: result.data.data
          });
        })
        .catch(result => {
          dispatch({
            type: FEEDBACK_GENERAL_ERROR,
            payload: result
          });
        });
      };
    };
  
    export const submitFeedbackGeneralReset = () => {
        return (dispatch) => {
            dispatch({
              type: FEEDBACK_GENERAL_RESET,
              payload: null
            });
        }
    };