import React from 'react';
import Alert from 'react-bootstrap/lib/Alert';

import styles from './SBBootstrap.css';
/**
 * variants
 
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'dark',
 */
class SBAlert extends React.Component {
    render() {
        return (
            <Alert 
                className={styles.alert} 
                variant={this.props.variant}
            >
                {this.props.children}
            </Alert>
        );
    }
}

SBAlert.defaultProps = {
    variant: 'info'
}

export default SBAlert;
