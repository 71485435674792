import {
  GET_STITCH_AVAILABLE_LIST,
  GET_STITCH_AVAILABLE_LIST_ERROR,
  GET_STITCH_AVAILABLE_LIST_SUCCESS,
  SELECTED_STITCH_VIDEO,
} from '../types'
// endpoint helpers and axios
import { endpointForApi, headersForApi } from '../../api/swingbotApi';
const axios = require('axios');
/**
 * get video list
 */
export const getStitchVideosAvailableByUserId = (userId, limit=100, offset=0) => {
  return (dispatch) => {
    dispatch({
      type: GET_STITCH_AVAILABLE_LIST,
      payload: null
    });
    const endpoint = endpointForApi('stitch', `users/${userId}/stitch?limit=${limit}&offset=${offset}`);
    return axios({
      url: endpoint,
      method: 'get',
      headers: headersForApi('stitch')
    })
      .then(result => {
        dispatch({
          type: GET_STITCH_AVAILABLE_LIST_SUCCESS,
          payload: result.data.data
        });
      })
      .catch(result => {
        dispatch({
          type: GET_STITCH_AVAILABLE_LIST_ERROR,
          payload: result
        });
      });
    };
  };

export const selectStitchVideo = (video) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_STITCH_VIDEO,
      payload: video
    });
  };
};
